import React from "react";
// Customizable Area Start
import { createTheme ,styled} from "@material-ui/core/styles";
import {
  Button,
  Box,
  IconButton,
  Typography,
  Radio,
  TextField,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  FormGroup,
  FormControl,
  Checkbox,
  Grid
} from "@material-ui/core";
import { MessageBox } from "./assets";
 import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// Customizable Area End
import DashboardController, { Props } from "./DashboardController";

export const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",
    }
  },
  typography: {
    subtitle1: {
      margin: "20px 0px"
    },
    h6: {
      fontWeight: 500
    },
  }
});
export default class EditKpi extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start

    const { analyticsChecked, automaticChecked , isHovered ,selectedOption} = this.state;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
      <Box >
              <Grid container justifyContent="space-between" alignItems="center" style={{backgroundColor:"#F3F4F5"}}>
                    <Grid className="first" style={webStyle.leftContainer} item>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item style={webStyle.rowDisplay}>
                                <ArrowBackIosIcon style={{ fontSize: "26px" , color:"black" }} data-testid="multiBack" onClick={this.navigateToEditTemplate2}/>
                                <span
                                    style={webStyle.labelStyle1}
                                >
                                    Edit Template
                                </span>
                            </Grid>
                            <Grid item style={webStyle.rowDisplay}>
                                <ArrowBackIosIcon style={webStyle.labelStyle2} />
                                <span style={webStyle.labelStyle as React.CSSProperties}>
                                    Add KPI
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className="second" style={webStyle.emptyContainer} item />
                </Grid>
        <Box style={webStyle.MainContainer}>
          <Box>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label" style={webStyle.FormLabel1}>Type <span style={{ color: 'red' }}>*</span></FormLabel>
              <RadioGroup
                data-testid = "singleuserCheck"
                aria-labelledby="demo-row-radio-buttons-group-label"
                row
                defaultValue="Core" 
                name="row-radio-buttons-group"
                value={selectedOption}
                onChange={this.handleRadioChange}
                
              >
                <FormControlLabel
                  value="Core"
                  control={<Radio style={webStyle.Radio1} />}
                  style={{ fontFamily: 'Open Sans', fontSize: '14px', color: '#777', }}
                  label="Core"
                />
                <FormControlLabel
                  value="Attendance"
                  control={<Radio style={webStyle.Radio1}/>}
                  style={{ fontSize: '14px', color: '#777', fontFamily: 'Open Sans', }}
                  label="Attendance"
                />
                <FormControlLabel
                  value="Timing"
                  label="Timing"
                  control={<Radio style={webStyle.Radio1} />}
                  style={webStyle.FormControlLabel1}
                />
                <FormControlLabel
                  control={<Radio style={webStyle.Radio1}/>}
                  value="Report"
                  label="Report"
                  style={webStyle.FormControlLabel1}
                />
                <FormControlLabel
                   style={webStyle.FormControlLabel1}
                  control={<Radio style={webStyle.Radio1} />}
                  value="Productivity"
                  label="Productivity"
                />
                <FormControlLabel
                  value="Checklist"
                  label="Checklist"
                  control={<Radio style={webStyle.Radio1} />}
                  style={webStyle.FormControlLabel1}
                />
                <FormControlLabel
                  value="Occurrence"
                  control={<Radio style={webStyle.Radio1} />}
                  style={webStyle.FormControlLabel1}
                  label="Occurrence"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box style={webStyle.box1}>
            <Typography style={webStyle.Typography1}>Basic Details<span style={{ color: 'red' }}>*</span></Typography>
            <Box sx={webStyle.box2}>
              <Box style={webStyle.box3}>
                <Typography style={webStyle.Typography1}>Title</Typography>
                <TextField
                  InputProps={{
                    disableUnderline: true,
                  }}
                  style={ webStyle.TextField1} />
              </Box>
              <Box style={webStyle.box3}>
                <Typography style={webStyle.Typography1}>Description</Typography>
                <TextField
                  // value=""
                  rows={6}
                  multiline
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                  }}
                  style={webStyle.TextField2}
                />
                <Typography variant="caption" style={webStyle.Typography2}>0/1000</Typography>
              </Box>
              <Box style={{ marginRight: '20px' }}>
                <Typography style={webStyle.Typography1}>Points (Weightage) </Typography>
                <TextField
                  InputProps={{
                    disableUnderline: true,
                  }}
                  style={webStyle.TextField3} />
              </Box>
            </Box>
          </Box>
          <Box>
          </Box>
{['Productivity','Core'].includes(selectedOption) && (
          <Box sx={webStyle.box4}>
            {/* Your existing upper code block */}
            <Box sx={webStyle.box4}>
              <Box style={{ marginTop: "30px" }}>
                <Box sx={webStyle.box5}>
                  <Typography style={webStyle.Typography3}>
                    Calculation Type <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Box
                    style={webStyle.box6}
                    data-test-id="MouseHover"
                    onMouseOver={this.handleMouseOver}
                    onMouseOut={this.handleMouseOut}
                  >
                    <Typography style={webStyle.Typography4}>i</Typography>
                  </Box>
                </Box>

                {/* Conditional hover image */}
                <StyledModalContainer>
                  <Box>
                    {isHovered && (
                      <Box style={{ position: "relative" }}>
                        <img src={MessageBox} style={webStyle.box7} />
                      </Box>
                    )}
                  </Box>
                </StyledModalContainer>
              </Box>

              {/* Rest of the upper code block */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={automaticChecked}
                      style={{ color: '#FDD100',paddingRight:"0px"}}
                      data-testid = "singleuserCheck"
                      onChange={this.handleChanxgeAutomatic}
                    />
                  }
                  label="Automatic"
                />
              </FormGroup>
            </Box>

            {/* Additional conditional logic for automaticChecked */}
            {automaticChecked && (
               <Box sx={webStyle.box4}>
               <Box sx={webStyle.box5}>
                 {automaticChecked && selectedOption !== 'Productivity' && (
                   <Box>
                     <Typography style={webStyle.Typography6}>
                       Monthly Goal Details <span style={{ color: 'red' }}>*</span>
                     </Typography>
                     <Box sx={webStyle.box5}>
                       <Box>
                         <Typography style={webStyle.Typography7}>Goal Type</Typography>
                         <StyledNumberBox>
                           <StyledSelect  data-test-id = "goalType" 
                            >
                             <option value="number">Number</option>
                             <option value="percentage">Percentage</option>
                           </StyledSelect>
                         </StyledNumberBox>
                       </Box>
                          <Box style={{ marginLeft: '15px' }}>
                           <Typography style={webStyle.Typography7}>Calculation Formulas</Typography>
                           <SumBox >
                             <select style={{padding:"15px",border:"none",background:"none",width:"220px"}}>
                               <option value="sum">Sum</option>
                               <option value="average">Average</option>
                             </select>
                           </SumBox>
                         </Box>                       
                       <GoalBox >
                            <Box>
                            <Typography style={webStyle.Typography7}>Goal Value (In Numbers)</Typography>
                            <Box className="custom-select" style={{ marginTop: '12px' }}>
                              <TextField
                                InputProps={{
                                  disableUnderline: true,
                                }}
                                style={webStyle.TextField4}
                              />
                            </Box>
                          </Box>

                              
                           
                       </GoalBox>
                     </Box>
                   </Box>
                 )}
                  {automaticChecked && selectedOption === 'Productivity' && (
                            <Box>
                     <Typography style={webStyle.Typography6}>
                          Daily Goal Details <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Box style={{ display: 'flex', flexDirection: 'row' }}>
                               <Box>
                               <Typography style={webStyle.Typography7}>Goal Value (In Numbers)</Typography>
                               <Box className="custom-select" style={{ marginTop: '12px' }}>
                                 <TextField
                                  placeholder="360"
                                   InputProps={{
                                     disableUnderline: true,
                                   }}
                                   style={webStyle.TextField4}
                                 />
                               </Box>
                             </Box>
                                </Box>
                              
                          </Box> 
                    )}
                  </Box>
                  <Box style={{ marginTop: '30px' }}>
                    <Typography style={webStyle.Typography8}>
                      Analytics & Run Rate<span style={{ color: 'red' }}>*</span><span><IconButton /></span>
                    </Typography>
                    <Box sx={webStyle.box8}>
                      <FormGroup>
                        <FormControlLabel
                        style={{marginTop:"6px"}}
                          control={
                            <Checkbox
                              data-testid = "singleuserCheck"
                              style={{ color: '#FDD100', fontSize: 'large',paddingRight:"0px"}}
                            />
                          }
                          label="Run Rate"
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormControlLabel
                        style={{marginTop:"6px"}}
                          control={
                            <Checkbox
                              onChange={this.handleChangeChecked2}
                              checked={analyticsChecked}
                              data-testid = "singleuserCheck"
                              style={{ color: '#FDD100', fontSize: 'medium',paddingRight:"0px"}}
                            />
                          }
                          label="Part of Analytics"
                        />
                      </FormGroup>
                      {(analyticsChecked) && (
                        <NewStyledBox >
                          <select style={{padding:"15px",border:"none",background:"none",width:"280"}}>
                            <option value="price">Price</option>
                            <option value="sales">Sales</option>
                            <option value="sales conversion">Sales Conversion</option>
                            <option value="Daily Visit">Daily Visit Avg.</option>
                          </select>
                        </NewStyledBox>)}
                    </Box>
                  </Box>
                </Box>
              
            )}
          </Box>
        )}
    
    


             
                         
          </Box>
          <Box style={{ marginTop: '50px',marginLeft: '40px'}}>
            <Button style={{ fontFamily: 'Open Sans', backgroundColor: "FDD100", color: "black", marginRight: "20px", width: "170px", fontSize: "medium", fontWeight: 550, textTransform: "none" }}>
              Submit
            </Button>
            <Button variant="outlined" style={{ fontFamily: 'Open Sans', backgroundColor: "white", color: "black", width: "170px", fontSize: "medium", fontWeight: 550, textTransform: "none" }} >
              Cancel
            </Button>
          </Box>
        </Box>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
MainContainer: {
  paddingLeft: '45px',
  marginTop: "30px",
  height:"max-content"
},
FormLabel1:{
  fontSize: "18px",
   fontFamily: 'Open Sans', 
   fontWeight: 700, 
   color: 'black' 
},
Radio1:{
  color: 'FDD100',
  paddingBottom: '12px'
},
FormControlLabel1:{
  color: '#777', 
  fontFamily: 'Open Sans',
 fontSize: '14px' 
},
box1:{
   marginTop: '10px' 
},
Typography1:{
   fontWeight: 700, 
   color: 'black', 
   fontSize: "18px", 
   fontFamily: 'Open Sans', 
   marginTop: '10px' 
},
box2:{
  display: 'flex', 
  flexDirection: 'row'
},
box3:{
  marginRight: '20px'
},
TextField1:{
  backgroundColor: '#F3F4F5',
  border: 'none',
  borderRadius: '10px',
  width: '130px',
  marginTop: '10px',
  padding: '8px',
},
TextField2:{
  width: '330px',
  marginTop: '10px',
  borderRadius: '10px',
  padding: '8px',
  backgroundColor: 'white',
  border: '2px solid #F3F4F5',

},
Typography2:{
  fontFamily: 'Open Sans', 
  display: 'flex', 
  justifyContent: 'end', 
  color: 'black', 
  fontSize: "larger",
},
TextField3:{
  borderRadius: '10px',
  width: '130px',
  padding: '8px',
  marginTop: '10px',
  backgroundColor: 'white',
  border: '2px solid #F3F4F5',
},
box4:{
  display: 'flex', 
  flexDirection: 'column'
},
box44:{
  display: 'flex', 
  flexDirection: 'row'
},
box5:{
  display: 'flex', 
  flexDirection: 'row',
},
Typography3:{
  fontWeight: 700, 
  color: 'black', 
  fontSize: "18px", 
  fontFamily: 'Open Sans' 
},
box6:{
  height: "30px",
  width: "30px",
  border: "1px solid yellow",
  borderRadius: "50%",
  marginLeft: '10px',
  cursor: 'pointer' 
},
Typography4:{
  fontWeight: 100, 
  color: 'black', 
  fontSize: "larger", 
  fontFamily: 'Open Sans', 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center'
},
box7:{
  height: '110px', 
  width: '220px'
},
Typography5:{
  color: 'black', 
  fontFamily: 'Open Sans'
},
Typography6:{
  fontWeight: 700, 
  color: 'black', 
  fontSize: "18px", 
  fontFamily: 'Open Sans', 
  marginTop: '30px'
},
Typography7:{
  fontWeight: 700, 
  color: 'black', 
  fontSize: "18px", 
  fontFamily: 'Open Sans',
   marginTop: '20px' 
},
TextField4:{
  border: '1px solid #ddd',
  borderRadius: '10px',
  width: '220px',
  padding: '10px',
},
TextField5:{
  border: '1px solid #ddd',
  borderRadius: '10px',
  width: '220px',
  padding: '10px',
},
Typography8:{
  fontWeight: 700, 
  color: 'black', 
  fontSize: "18px", 
  fontFamily: 'Open Sans'
},
box8:{
  display: 'flex', 
  flexDirection: 'row', 
  marginTop: '10px'
},
template: {
  display: "flex",
  flexWrap: "wrap",
  padding: "10px 10px",
  backgroundColor: '#F3F4F5',
  position: "relative",
  height: "72px",
  alignItems: "center"
 },
 filterHeader: { 
  padding: "0px 0px 0px 22px", 
  display: "flex", 
  alignItems: "flex-start", 
  gap: "20px"
 },
 rowDisplay: { 
  display: "flex", 
  alignItems: "center" 
},
labelStyle1: {
  color: "black",
  fontSize: "18px",
  fontFamily: 'Open Sans',
},
labelStyle2: {
  color: "	#C0C0C0",
  fontSize: "18px",
},
labelStyle: {
  color: 'black', 
  fontSize: "18px", 
  fontWeight: 700 ,
  fontFamily: 'Open Sans',
},
emptyContainer: { 
  padding: "0px 44px 0px 0px" 
},
leftContainer: {
  padding: "19px 0px 21px 38px"
},

};


const StyledModalContainer = styled(Box)({
  position:"absolute" , left: "499px",top: "530px",
  
  '@media (max-width: 1370px)': {
  left: "450px"   
},
'@media (max-width: 1445px)': {
  left: "450px"   
},
})
const StyledNumberBox=styled(Box)({
  marginTop: '10px',
  width: "233px" ,
  backgroundColor: "F6F6F9" ,
   borderRadius: "12px" 
})
const SumBox=styled(Box)({
 marginTop: '10px',
 width: "233px" ,
 backgroundColor: "F6F6F9" ,
  borderRadius: "12px" 
})
const GoalBox=styled(Box)({
  marginLeft: '15px', 
  display: 'flex',
   flexDirection: 'row'
})
const NewStyledBox=styled(Box)({
 width: "290px" ,
 backgroundColor: "F6F6F9" ,
  borderRadius: "12px"
})
const StyledSelect=styled('select')({
  padding:"15px",
  border:"none",
  background:"none",
  width:"220px"
})
// Customizable Area End
