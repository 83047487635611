import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-hot-toast";
import React ,{createRef} from "react";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import {format} from  "date-fns";
import './dashboard.css'
import { user_profile_icon } from "./assets";
declare global {
  interface Console {
    disableYellowBox: boolean;
  }
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  open: boolean;
  onClose: () => void;
  message: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  isLoadingDepartments:any,
  isLoadingDesignations: any,
  isLoadingEmployees: any,
  messageVisible: any,
  expandedDepartment: any,
  hoveredIndex:any,
  newHoveredIndex:any,
  isDropdownOpen:boolean,
  selectedOption: string,
  goalType:string,
  isHovered:boolean,
  showMoreRow:boolean,
  selectedDepartment: string,
  departmentNavigateId:any,
  navigateToEditKpi: boolean,
  kpiName:string,
  automaticChecked: boolean,
  kpiDescription:string,
  weightage:string,
  goalCalculation:string,
  runRateChecked: boolean;
  analyticsChecked: boolean;
  startDate:any;
  endDate:any;
  dataSource: any;
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  options: any;
  series:any;
  dataSource2: any;
  viewAllUserData: any
  activateMessage: string;
  department: unknown;
  percentage: number;
  togglemodel: boolean;
  addFeature: boolean;
  imageData: any;
  switchStates: { id: number | string }[];
  viewAllTemplates: any
  templateAdd: any
  kpis: any;
  templateData: { name: string, description: string },
  selectedItemId: any,
  searchQuery: string,
  flagButton:boolean,
  events:any,
  isPopupVisible:boolean;
  open:boolean,
  isMultiple: boolean,
  isChecked:boolean,
  openNotification:boolean,
  editAdminFormData:any,
  profile_img:string,
  userDataLoading:boolean,
  totalTemplate: number,
  departmentListData: any,
  designationListData: any,
  employeeListData:any,
  roleListData: any,
  activeUsersCount:number,
  imageField:string|null,
  tempLateoptions:string[],
  createEventData:{  
    event_name: string,
    description: string,
    day_type: string,
    start_date: any ,
    end_date:  any ,
  },
  disableKpiModel:boolean
  copyTemplateData:any
  copiedTemplates: any,
  selectedCheckboxes : {
    all_templates: boolean;
    copied_templates: boolean;
    original_templates: boolean;
  };
  filterData:any;
  showTemplateData:any;
  selectedKPIName: string, 
  selectedKpiIds: number,
  disableSwitch:boolean,
  kpiFormData:any,
  anchorEl:any,
  anchorEl2:any,
  anchorEl3:any,
  anchorEl4:any,
  anchorEl5:any,
  anchorEl6:any,
  anchorEl7:any,
  showModal: boolean, 
  accountIdToDisable: any,
  accountsName:string,
  activateDeactivate:boolean,
  sideBarFlag:boolean,
  userType : string;
  cards:any,
  isOpen:boolean,
  isOpen2:boolean,
  isTemplateStatus:boolean;
  isduplicateOpen:boolean,
  department_id :any ,
  editOpen: boolean,
  dayType:any,
  userRole:any,
  expandedDesignation:any,
  showEventName :any,
  selectedDate:any,
  eventStartDate:any,
  eventEndDate:any,
  activeDate:any,
  selectedMonth:any,
  selectedYear:any,
  currentDateForEvent:any,
  showeEventDateFirst :any,
  showeEventDateLast:any,
  allEvent:any,
  datepickerClose:any,
  getTemplateName:any,
  selectedTemplateName:string,
  selectedEmployeeName:string,
  selectedTemplateCheckBox:number,
  selectedEmployeeCheckBox:number,
  tagTemplateModal:boolean,
  selectedTemplateData:any,
  succesModalisOpen:boolean,
  checkBoxRes:boolean,
  checkBoxMul:boolean,
  isOpneUserBulkUpload:boolean,
  bulkuploadFile:any,
  bulkUploadProgress:boolean,
  showUploadError:string,
  spacificDepartmentID:number,
  userTemplateData:any;
  notify:boolean;
  circularLoading:boolean;
  showKpiDiv:boolean;
  statusList:any;
  originalTemplates:any;
  templateStatusText:any;
  // filteredStatus:any;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
 apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getAllUserId: string = "";
  getActivatedAccountsId: string = "";
  getTemplateId: string = "";
  getTemplateAddedId: string = "";
  getEditTemplateId: string = "";
  getSearchTemplateID: string = "";
  getSpacificTemplateID: string="";
  bulkUploadInput: any;
  userBulkUploadId:string="";
  addMoreFeatureId:string="";
  totalTemplateId: string = "";
  getDepartmentsID: string = "";
  getdesignationListID: string = "";
  getemployeeListID: string = "";
  getRolesID: string = "";
  getActiveUsersId:string = "";
  createEventId:string="";
  getCopyTemplateID:string="";
  getFilterTemplateID:string="";
  getShowTempaltewithKpiId:string="";
  getDisableKpiId:string="";
  getUpdateTemplateKpiId:string=""
  getAdminProfileDetails: string = "";
  getEventDetailsId: string = "";
  datePickerRef :any;
  duplicateTemplateNewId :string=""
  departmentRef:any;
  statusRef:any;


  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];
    const currentDate = new Date();
    
    this.state = {
      isLoadingDepartments: true,
      isLoadingDesignations: true ,
      isLoadingEmployees: true,
      messageVisible: false,
      hoveredIndex:null,
      newHoveredIndex:null,
      isDropdownOpen:false,
      expandedDepartment:[],
      selectedOption: 'Core',
      goalType: 'number',
      isHovered:false,
      showMoreRow:false,
      selectedDepartment: 'All Departments',
      departmentNavigateId:'',
      navigateToEditKpi: false ,
      editOpen: false,
      automaticChecked: false,
      runRateChecked: false,  
      analyticsChecked: false,
      kpiName: '',
      kpiDescription: '',
      weightage: '',
      goalCalculation: '',
      sideBarFlag:false,
      department_id: null,
      accountsName:"",
      activateDeactivate:false,
      showModal: false,
      accountIdToDisable: null,
      anchorEl:null,
      anchorEl2:null,
      anchorEl3:null,
      anchorEl4:null,
      anchorEl5:null,
      anchorEl6:null,
      anchorEl7:null,
      kpiFormData:{},
      disableSwitch:true,
      selectedKpiIds:0,
      selectedKPIName: '', 
      showTemplateData:{},
      filterData:[],
      selectedCheckboxes: {
        all_templates: false,
        copied_templates: false,
        original_templates: false,
      },
      copiedTemplates: {},
      copyTemplateData:{},
      disableKpiModel:false,
      createEventData:{
        event_name: '',
        description: '',
        day_type: '',
        start_date: null,
        end_date: null,
      },
      isOpen:false,
      isOpen2:false,
      isduplicateOpen:false,
      imageField:null,
      activeUsersCount:0,
      totalTemplate: 0,
      roleListData: [],
      employeeListData:[],
      designationListData: [],
      departmentListData: [],
      expandedDesignation: [],
      openNotification:false,
      isChecked:false,
      isMultiple: false, 
      editAdminFormData: [],
      profile_img: '',
      userDataLoading: false,
      startDate:null,
      endDate:null,
      open:false,
      isPopupVisible:false,
      events: [
        {
          date: "2023-07-02",
          color: "red",
          extraData: "sdfsd"
        },
        {
          date: new Date("2023-07-23"),
          color: "yellow",
          extraData: "sdfsd"
        },
        {
          date: "2023-07-14",
          color: "#ffafcc",
          extraData: "sdfsd"
        }
      ],
      flagButton:false,
      switchStates: [],
       templateData: {
        name: "",
        description: "",  
      },
     kpis:[],
      selectedItemId: null,
      searchQuery: '',
      viewAllTemplates: [],
      templateAdd: [],
       imageData: '',
     dashboardData: [],
      addFeature: false,
      togglemodel: false,
      percentage: 60,
      department: "",
      viewAllUserData: [],
      userType : "",
      errorMsg: "",
      token: "",
      loading: false,
      activateMessage: "",
      series: [
        {
          name: "Inflation",
          data: [2.3, 3.1, 4.0, 10.1, 4.0],
          color: "#476484"
        }
      ],
      tempLateoptions: ['All Templates', 'Original Templates', 'Duplicate Templates', 'Added Templates'],
      options: {
        
        chart: {
          type: "bar",

          toolbar: {
            show: false
          }
        },
        title: {
          text: "Company Usage",
          align: "left",
          style: {
            color: "#a0a0a0",
            fontSize: '20px', fontWeight: 'bold',
            paddingLeft: 20,
            paddingTop:20
          }
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top"
            },
            borderRadius:"5"
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#9f9f9d"]
          },
          offsetY: -20,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: ["XYZ Ltd.", "Awes Ltd.", "Onm Ltd.", "123 Ltd.", "Swr Ltd."],
          title: {
            text: "Company usage based on the KPI Templates created",
            style: {
              color: "#C9C9C9" 
            }
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          },
          labels: {
            show: false,
          },
        },
        yaxis: {
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: true
          },
          labels: {
            show: true,
            offsetY: 10,
          },
          
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
          }
        }
      },
      dataSource:  [
        {
          name: "Aug 2022",
          uv: 4000,
          pv: 2400,
          amt: 2400
        },
        {
          name: "Sep 2022",
          uv: 3000,
          pv: 1398,
          amt: 2210
        },
        {
          name: "Oct 2022",
          uv: 2000,
          pv: 9800,
          amt: 2290
        },
        {
          name: "Dec 2022",
          uv: 2780,
          pv: 3908,
          amt: 2000
        },
        {
          name: "Jan 2023",
          uv: 1890,
          pv: 4800,
          amt: 2181
        },
      ],
      dataSource2:  [
        {
          name: "Aug 2020",
          uv: 4000,
          pv: 2400,
          amt: 2400
        },
        {
          name: "Sep 2020",
          uv: 3000,
          pv: 1398,
          amt: 2210
        },
        {
          name: "Oct 2020",
          uv: 2000,
          pv: 9800,
          amt: 2290
        },
        {
          name: "Nov 2020",
          uv: 2780,
          pv: 3908,
          amt: 2000
        },
        {
          name: "Dec 2020",
          uv: 1890,
          pv: 4800,
          amt: 2181
        },
        {
          name: "Jan 2021",
          uv: 2390,
          pv: 3800,
          amt: 2500
        },
        
      ],
      cards:[
        { title: 'Developer Gamecard', content: 'Over 92% of computers are infected with Adware and spyware. Such software is rarely accompanied by uninstall utility and even when it is it almost always leaves broken Windows Registry keys behind it.' , status: 1},
       ],
       dayType:'Choose Day Type',
       userRole:'',
       showEventName:[],
       selectedDate:'',
       eventStartDate :'',
       eventEndDate:'',
       activeDate:'',
       selectedMonth: currentDate.getMonth(),
       selectedYear: currentDate.getFullYear(),
       currentDateForEvent:"",
       showeEventDateFirst :'',
       showeEventDateLast:'',
       allEvent:[],
       datepickerClose:false,
       getTemplateName:[],
       selectedTemplateName:"Choose Template",
       selectedEmployeeName:"Choose Employee",
       selectedTemplateCheckBox:2,
       selectedEmployeeCheckBox:2,
       tagTemplateModal:false,
       selectedTemplateData:[],
       succesModalisOpen:false,
       checkBoxRes:true,
       checkBoxMul:false,
       isOpneUserBulkUpload:false,
       bulkuploadFile:"",
       bulkUploadProgress:false,
       showUploadError:'',
       spacificDepartmentID:0,
       userTemplateData:{},
       notify:false,
       circularLoading:true,
       showKpiDiv:true,
       isTemplateStatus:false,
       statusList:["All Templates","Original Templates","Duplicate Templates","Added Templates"],
       originalTemplates:[],
       templateStatusText:"All Templates"
    };
    this.datePickerRef = createRef()
    this.departmentRef = createRef()
    this.statusRef=createRef()
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
  
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  
    const savedDepartment = localStorage.getItem("selectedDepartment");
    const savedDepartmentId = localStorage.getItem("departmentNavigateId");
    const savedTemplateStatusText = localStorage.getItem("templateStatusText");
  
    if (savedDepartment && savedDepartmentId) {
      this.setState(
        {
          selectedDepartment: savedDepartment,
          departmentNavigateId: Number(savedDepartmentId),
          circularLoading: true,
        },
        () => {
          if (savedDepartment === "All Departments") {
            this.getAllTemplates();
          } else {
            this.getTemplateAdded(Number(savedDepartmentId));
          }
        }
      );
    } else {
      this.setState({ circularLoading: true }, () => {
        this.getAllTemplates();
      });
    }
  
 
    if (savedTemplateStatusText) {
      this.handleSelectionStatus(savedTemplateStatusText, null);
    }
  
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("mousedown", this.handleClickOutside2);
    this.currentDate();
    this.getEventDetails();
    const user = await getStorageData("loginMessage");
    this.setState({ userRole: user });
  
    this.getAdminProfileData();
    this.getDepartmentList();
  
    setTimeout(() => {
      const medianValue = this.calculateMedianValue();
      const options = {
        ...this.state.options,
        annotations: {
          yaxis: [
            {
              y: medianValue,
              borderColor: "#E4E4E4",
              strokeDashArray: 10,
            },
          ],
        },
      };
  
      this.setState({ options });
    }, 3000);
  
    this.getDashboardData();
    this.getTotalTemplates();
    this.getActiveUsers();
  
    if (this.props.navigation && this.props.navigation.match.path === "/department") {
      this.getDepartmentList();
      this.getRolesList();
    }
  
    if (this.props.navigation && this.props.navigation.getParam("id")) {
      this.showTemplateswithKpis(this.props.navigation.getParam("id"));
    }
  
    this.dataFromStorage();
  
    const result = await getStorageData("loginMessage");
    this.setState({ userType: result });
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    let userToken = window.localStorage.getItem("authToken");

    const fetchallUSersHeader = {
      "Content-Type": configJSON.dashboarContentType,
      token:userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllUserId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/organization_employee_details"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchallUSersHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.getAllUserId:
          
          if (responseJson && responseJson.employee_details) {
            const sortedData = responseJson.employee_details.sort((a :any, b:any) => a.id - b.id);
            this.setState({ viewAllUserData: sortedData,userTemplateData:responseJson });

          }
          break;
        case this.getActivatedAccountsId:
          if (responseJson && responseJson.message) {
            toast.success(responseJson.message)
            this.handleOpenKpiModal()
            this.getDashboardData();
            this.getActiveUsers();
          }
          else{
            toast.error(responseJson.error)
            this.setState({disableKpiModel:false})
           }
          
          break;
      
        case this.getTemplateId:
          this.getTemplate(responseJson)
          break;
        case this.getTemplateAddedId:
          this.getTemplateAddedDetail(responseJson)
          break;
        case this.userBulkUploadId:
           this.uploadBulkData(responseJson)
            break;
        case this.getDepartmentsID:
              this.getDepartmentDataList(responseJson)
              break;
        case this.getdesignationListID:
              this.getDesignationDataList(responseJson)
              
              break;
        case this.getemployeeListID:
              this.getEmployeeDataList(responseJson)              
              break;
        case this.getRolesID:
              this.getRolesDataList(responseJson)
              break;
        case this.totalTemplateId:
              this.getTotalTemplate(responseJson)
              this.getAllTemplateshere(responseJson)
              break;
        case this.duplicateTemplateNewId:
          this.getDuplicateTemplate(responseJson)
            break;
        case this.getActiveUsersId:
            this.getActiveUsersResponse(responseJson)
                break;
        case this.createEventId:
            this.createEventResponse(responseJson)
              break;
       case this.getCopyTemplateID:
            this.getCopyresponse(responseJson)
            break;
        case this.getShowTempaltewithKpiId:
          this.getShowTemplateDataKpisResponse(responseJson)
          break;
        case this.getDisableKpiId:
          this.handleDisaleSwitchResponse(responseJson)
          break;
        case this.getUpdateTemplateKpiId:
            this.getUpdateKpiResponse(responseJson)
            break;
        case this.getAdminProfileDetails:
            this.getEditEmployeesFormData(responseJson)
            break;
        case this.getEventDetailsId:
              this.getEventDetailsResposne(responseJson)
              break;  
        case this.getSpacificTemplateID:
              this.getSpacificTemplateRes(responseJson)
              break;         
          default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  dataFromStorage = async () => {
    let result = await getStorageData("loginMessage")
    
    if (result === "org_admin") {
      this.setState({ sideBarFlag: false })
    }
    else {
    
      this.setState({ sideBarFlag: true })
    }
  }
  
  toggleDropdown = () => {
    this.setState({ isOpen: !this.state.isOpen});
  };
  toggleDropdownStatus=()=>{
    this.setState({ isTemplateStatus: !this.state.isTemplateStatus});
  }

  toggleDropdown2 = () => {
    this.setState({ isOpen2: !this.state.isOpen2 });
  };
  handleSelectionStatus = async (name: any, index: any) => {
    localStorage.setItem("templateStatusText", name); 
  
    if (name === "Original Templates") {
      const filteredStatus = this.state.originalTemplates.filter(
        (template: any) => template.attributes.status.toLowerCase() === "approved"
      );
      await this.setStateAsync({
        isTemplateStatus: false,
        viewAllTemplates: filteredStatus,
        templateStatusText: name,
      });
    } else if (name === "Added Templates") {
      const filteredStatus = this.state.originalTemplates.filter(
        (template: any) => template.attributes.status.toLowerCase() === "added"
      );
  
      await this.setStateAsync({
        isTemplateStatus: false,
        viewAllTemplates: filteredStatus,
        templateStatusText: name,
      });
    } else if (name === "Duplicate Templates") {
      const filteredStatus = this.state.originalTemplates.filter(
        (template: any) =>
          template.attributes.status.toLowerCase() !== "added" &&
          template.attributes.status.toLowerCase() !== "approved"
      );
  
      await this.setStateAsync({
        isTemplateStatus: false,
        viewAllTemplates: filteredStatus,
        templateStatusText: name,
      });
    } else if (name === "All Templates") {
      this.getAllTemplates();
      await this.setStateAsync({
        isTemplateStatus: false,
        templateStatusText: name,
      });
    }
  
    console.log(this.state.viewAllTemplates, "Filtered Templates");
  };
  
  
  setStateAsync(state: any) {
    return new Promise<void>((resolve) => {
      this.setState(state, resolve);
    });
  }
  
  handleSelection = (department: any | null, departmentId: number | null) => {
    this.setState(
      {
        circularLoading: true,
        selectedDepartment: department,
        isOpen: false,
        departmentNavigateId: departmentId,
      },
      () => {
       
        if (department) {
          localStorage.setItem("selectedDepartment", department);
          localStorage.setItem("departmentNavigateId", departmentId?.toString() || "");
        }
  
     
        if (department === "All Departments") {
          this.setState({ circularLoading: false });
          this.getAllTemplates();
        } else if (departmentId && departmentId !== null) {
          this.getTemplateAdded(Number(departmentId));
        }
      }
    );
  };
  
  togglePopup = () => {
    this.setState({openNotification:!this.state.openNotification})
   };

  uploadBulkData=(responseJson:any)=>{

  if(responseJson && responseJson.message){
    this.getDashboardData()
    toast.success(responseJson.message)
  }
  else{
    toast.error("file is already uploaded")
  }
}

  handleFileChange=(event:any)=>{
   
    const file=event.target.files[0]
  
    if(file){
      if(file.type !== 'text/csv'){
        toast.error("select a csv file");
        return;
      }
      this.uploadBulkUsers(file)
    } 
  }
  navigateToSignUp=()=>{
    this.props.navigation.navigate("EditKpi")
  }
 
  navigateToEditTemplate2 = async ()=>{
    const navigateToUserProfile = new Message(getName(MessageEnum.NavigationMessage));
    navigateToUserProfile.addData(getName(MessageEnum.NavigationTargetMessage), "EditTemplate2");
    navigateToUserProfile.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateToUserProfile);
  }
  navigateToAddDKR=()=>{
    this.props.navigation.navigate("AddDkr")
  }
  navigateToVeiwKpi=()=>{
    this.props.navigation.navigate("ViewKpi")
  }
  navigateToProductDKR = () => {
    this.props.navigation.navigate("ProductDkr")
  }
  navigateToProductView = () => {
    this.props.navigation.navigate("ProductView")
  }
  navigateToAttendenceDKR = () => {
    this.props.navigation.navigate("AttendenceDkr")
  }
  navigateToAttendanceView = () => {
    this.props.navigation.navigate("AttendenceView")
  }
  navigateToTimingDKR = () => {
    this.props.navigation.navigate("TimingDkr")
  }
  navigateToTimingView = () => {
    this.props.navigation.navigate("TimingView")
  }
  navigateToOccurrenceDKR = () => {
    this.props.navigation.navigate("OccurrenceDkr")
  }
  navigateToReportDKR = () => {
    this.props.navigation.navigate("ReportDkr")
  }
  navigateToReportView = () => {
    this.props.navigation.navigate("ReportView")
  }
  navigateToChecklistDKR = () => {
    this.props.navigation.navigate("ChecklistDkr")
  }
  navigateToChecklistView = () => {
    this.props.navigation.navigate("ChecklistView")
  }
  navigateToOccurrenceView = () => {
    this.props.navigation.navigate("OccurrenceView")
  }
  handleCheckboxChange = (event:any) => {
    const { name, checked } = event.target;
    if (name === "isMultiple") {
      this.setState({
        isMultiple: checked,
        checkBoxRes:false,
        checkBoxMul:true
      });
      this.setState(prevState => ({
        createEventData: {
          ...prevState.createEventData,
          start_date: null,
          end_date: null,
        },
      }));
    
    } 
    else{
      this.setState({
        isChecked: checked,
      });
    }
   
  };

  handleCheckboxChangeRes=()=>{
      this.setState(prevState => ({
        ...prevState,
        checkBoxRes: true, 
        checkBoxMul: false,
        isMultiple: false,
        createEventData: {
          ...prevState.createEventData,
          start_date: null,
          end_date: null,
        },
      }));
  }
  handleCheckboxChangeNotify=()=>{
    this.setState({notify:!this.state.notify})
   
  
}
   handleOpenModal = () => {
    this.setState({open:!this.state.open,isPopupVisible: false });
    setTimeout(() => {
    this.setState({open:false,isPopupVisible: false,succesModalisOpen:false });
    }, 5000);
  };
  handleOpenKpiModal = () => {
    this.setState({disableKpiModel:!this.state.disableKpiModel });
  };

  handleEditClick = (item:any) => {
    localStorage.setItem("editEmployeeName", item.name);
    localStorage.setItem("editEmployeeID", item.employee_id);
    window.history.pushState({},`/users/${item.id}`);
  };
  handleTemplatCheckboxChange = (name:any) => {
    
    this.setState(
      (prevState:any) => {
        const updatedCheckboxes = {
          all_templates: false,
          copied_templates: false,
          original_templates: false,
        };
  
        updatedCheckboxes[name as keyof typeof updatedCheckboxes] = !prevState.selectedCheckboxes[name];
  
        return {
          selectedCheckboxes: updatedCheckboxes,
        };
      }
    );
  };

  handleKPISelection = (index:number) => {
    const selectedKPIName = this.state.showTemplateData.attributes.kpis.data[index].attributes.name;
    const selectedKpiIds = this.state.showTemplateData.attributes.kpis.data[index].id;
    this.setState({ selectedKPIName,selectedKpiIds });
  };
  handleClick = (event:any) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClickClose = () => {
    this.setState({ anchorEl: null });
  };
  handleClick2 = (event:any) => {
    this.setState({ anchorEl2: event.currentTarget });
  };
  handleClickClose2 = () => {
    this.setState({ anchorEl2: null });
  };
  handleClick3 = (event:any) => {
    this.setState({ anchorEl3: event.currentTarget });
  };
  handleClickClose3 = () => {
    this.setState({ anchorEl3: null });
  };
  handleClick4 = (event:any) => {
    this.setState({ anchorEl4: event.currentTarget });
  };
  handleClickClose4 = () => {
    this.setState({ anchorEl4: null });
  };
  handleClick5 = (event:any) => {
    this.setState({ anchorEl5: event.currentTarget });
  };
  handleClickClose5 = () => {
    this.setState({ anchorEl5: null });
  };
  handleClick6 = (event:any) => {
    this.setState({ anchorEl6: event.currentTarget });
  };
  handleClickClose6 = () => {
    this.setState({ anchorEl6: null });
  };
  handleClick7 = (event:any) => {
    this.setState({ anchorEl7: event.currentTarget });
  };
  handleClickClose7 = () => {
    this.setState({ anchorEl7: null });
  };
  handleMouseOver = () => {
    this.setState({ isHovered: true });
  };

  handleMouseOut = () => {
    this.setState({ isHovered: false });
  };
  handleMouseEnter = (index:any) => {
    this.setState({ hoveredIndex: index });
  };
  handleMouseEnter2 = () => {
    this.setState({ hoveredIndex: true });
  };
  handleMouseEnter3 = (index:any) => {
    this.setState({ newHoveredIndex: index });
  };

  handleMouseLeave = () => {
    this.setState({ hoveredIndex: null });
  };
  handleMouseLeave3 = () => {
    this.setState({ newHoveredIndex: null });
  };
  handleSelectChange = (id: number) => {
  };
  
  openPopup = () => {
    this.setState({
      isChecked:false,
      isPopupVisible: !this.state.isPopupVisible, createEventData: {
        event_name: '',
        description: '',
        day_type: '',
        start_date: null,
        end_date: null,      
      },
    });
  };


  getDepartmentDataList = async (responseJson: any) => {
    if (responseJson.departments && responseJson.departments.length > 0) {
      this.setState({ departmentListData: responseJson.departments });
  
      const selectedDepartmentId = responseJson.departments[0].id;
      
      await this.getDesignationDataList(selectedDepartmentId);
    }
  }
  getDesignationDataList = async (responseJson: any) => {
    if (responseJson.data && responseJson.data.length > 0) {
      const designations = responseJson.data.map((item:any) => ({
        id: item.attributes.id,
        name: item.attributes.name,
      }));
      this.setState({ designationListData: designations });
      const selectedDesignationId = responseJson.desination[0].id;
     await this.getEmployeeDataList(selectedDesignationId);
    } 

  }
  getEmployeeDataList = async(responseJson: any) => {
    if (responseJson.data && responseJson.data.length > 0) {
      const employees = responseJson.data.map((item:any) => ({
        id: item.attributes.id,
        name: item.attributes.name,
        first_name: item.attributes.first_name,
        last_name: item.attributes.last_name,
      }));
      this.setState({ employeeListData: employees });
    } 
     

  }
  getRolesDataList = (responseJson: any) => {
    if (responseJson.role) {
      this.setState({ roleListData: responseJson.role });
    }
  }

  getShowTemplateDataKpisResponse = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ showTemplateData: responseJson.data });
    }
  }


   formatMonthLabel = (locale:any, date:any) => {
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const day = startOfMonth.getDate().toString().padStart(2, '0'); // Get the day and pad with leading zero if necessary
    const month = startOfMonth.toLocaleString('en-US', { month: 'long' }); // Get the month name
    const year = startOfMonth.getFullYear(); // Get the year

    const Eday = endOfMonth.getDate().toString().padStart(2, '0'); // Get the day and pad with leading zero if necessary
    const Emonth = endOfMonth.toLocaleString('en-US', { month: 'long' }); // Get the month name
    const Eyear = endOfMonth.getFullYear(); // Get the year

    const formattedStartDate = `${day} ${month} ${year}`;
    const formattedEndDate = `${Eday} ${Emonth} ${Eyear}`;
    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  
   handleUploadClick = () => {
   this.setState({flagButton:!this.state.flagButton})
  
  };
  handlePopOverClick = (event:any) => {
   };
   handlePopOvercloseClick = () => { 
   };

  getStatusStyle = (hasData:any, activated:any) => {
        if (!hasData) {
      return { color: '#ff4f4f' };
    } else if (activated) {
      return { color: '#9298a1' };
    } else {
      return { color: '#00bea0' };
    }
  };

logout = async() => {
    localStorage.removeItem("authToken");
    await removeStorageData("loginMessage")
    await removeStorageData("cmpName")
    await removeStorageData("adminName")
    await removeStorageData("rememberedEmail")
    await removeStorageData("rememberedPassword")
    await removeStorageData("selectedDepartment");
    await removeStorageData("departmentNavigateId");
    document.dispatchEvent(new CustomEvent("isLoggedIn", { detail: false }));
    window.history.pushState({}, "", "/EmailAccountLoginBlock");
  }
  calculateMedianValue = () => {
    const { data } = this.state.series[0];
    const sortedData = [...data].sort((a, b) => a - b);
    const medianIndex = Math.floor(sortedData.length / 2);
    return sortedData.length % 2 === 0
      ? (sortedData[medianIndex] + sortedData[medianIndex - 1]) / 2
      : sortedData[medianIndex];
  };

  formatDate = (date: any) => {
    return date.toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric"
    });
};

  calculateMedian = (data:any) => {
    const sortedData = [...data].sort((a, b) => a.pv - b.pv);
    const dataLength = sortedData.length;

    if (dataLength % 2 === 0) {
      const middleIndex = dataLength / 2;
      const middleValues = [
        sortedData[middleIndex - 1].pv,
        sortedData[middleIndex].pv
      ];
      const medianValue = (middleValues[0] + middleValues[1]) / 2;
      return medianValue;
    } else {
      const middleIndex = Math.floor(dataLength / 2);
      const medianValue = sortedData[middleIndex].pv;
      return medianValue 
    }
  };
 
  getAllTemplateshere = (responseJson: any) => {
    if (responseJson) {
      this.setState({ viewAllTemplates: responseJson.data,circularLoading:false ,originalTemplates: responseJson.data});
    } 
  }
  getDuplicateTemplate=(responseJson:any)=>{
    if (responseJson && responseJson.message) {
      toast.success(responseJson.message)
      setTimeout(()=>{
        window.location.reload()
      },1500)
    }
    else {
      toast.error(responseJson.errors[0].message)
     }
  }
  
  getTotalTemplate = (responseJson: any) => {
    if(responseJson&&!responseJson.errors){
      this.setState({ totalTemplate: responseJson });
    }else if (responseJson && responseJson.errors && responseJson.errors[0].token){
      localStorage.removeItem("authToken");
      document.dispatchEvent(new CustomEvent("isLoggedIn", { detail: false }));
      window.history.pushState({}, "", "/EmailAccountLoginBlock");
    }
  }
  getTemplate = (responseJson: any) => { 
    if (responseJson) {
      this.setState({ viewAllTemplates: responseJson.data, originalTemplates:responseJson.data});
    } 
  }
  getTemplateAddedDetail = (responseJson: any) => { 
    if (responseJson) {
      this.setState({ templateAdd: responseJson.data ,circularLoading:false});
    } 
  }
  getActiveUsersResponse= (responseJson: any) => {
    if (responseJson) {
      
      this.setState({ activeUsersCount: responseJson.active_users });
    }
  }
  createEventResponse= (responseJson: any) => {
    if (responseJson.data) {
      this.getEventDetails();
      this.handleOpenModal()
      this.setState({ isPopupVisible: false})    
    } 
   else{
      toast.error(responseJson.error)
      this.setState({ createEventData: {
        event_name: '',
        description: '',
        day_type: '',
        start_date: null,
        end_date: null,
      },   checkBoxMul:false,
           checkBoxRes:true,
           isMultiple: false,
           isChecked:false,
           isPopupVisible: false
    }
    )
    };
  
  }
  getCopyresponse= (responseJson: any) => {
    if (responseJson.data) {
    this.setState({copyTemplateData:responseJson.data})
     this.getAllTemplates()
    } 
    else{
      toast.error(responseJson.errors)
    }
  }
  getEditEmployeesFormData = (responseJson: any) => {
   
    if (responseJson.data) {
      if(responseJson.data.attributes.image=="https://jamesperformanceapp-261643-ruby.b261643.dev.eastus.az.svc.builder.cafe"){
        this.setState({
          profile_img: user_profile_icon,
          editAdminFormData: responseJson.data,
          userDataLoading: false
        })
      }
      else{
        this.setState({
          profile_img: responseJson.data.attributes.image,
          editAdminFormData: responseJson.data,
          userDataLoading: false
        });
      }
     
    }

  }
  navigateToPost = async(screenName: string, payload?: object) => {
    const navigateMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigateMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      screenName
    );
    navigateMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    if(payload) {
      const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage))
      raiseMessage.addData(getName(MessageEnum.PostDetailDataMessage), payload);
      navigateMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage)
    }
    this.send(navigateMessage)
  }
  getAdminProfileData = () => {
    let userToken = window.localStorage.getItem("authToken");

    const httpHeaderData = {
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAdminProfileDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAdminProfileEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(httpHeaderData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAdminProfileAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getUpdateKpiResponse= (responseJson: any) => {
    if (responseJson.data) {
      this.showTemplateswithKpis(this.props.navigation.getParam("id"))
     this.props.navigation.navigate("Template")
    } 
  }

  handleUpdateKpiForm = (values: { [key: string]: any }) => {
    
     const searchParams = window.location.pathname.split("/");
     const params = searchParams[searchParams.length - 1]
     const paramsAsNumber = Number(params);
    
    this.setState({ kpiFormData: values });
     this.getUpdateTEmplateKpiDetail(paramsAsNumber)
     
   };

    createFormData = (values:any) => {
    const formData = new FormData();
    
    formData.append('template[name]', values.templateName);
    formData.append('template[description]', values.templateDescription);
    
    for (const key in values) {
      if (key.startsWith('kpiname')) {
        const index = key.replace('kpiname', ''); 
        formData.append(`template[kpis_attributes][${index}][name]`, values[key]);
      } else if (key.startsWith('kpidescription')) {
        const index = key.replace('kpidescription', '');
        formData.append(`template[kpis_attributes][${index}][description]`, values[key]);
      } else if (key.startsWith('points')) {
        const index = key.replace('points', ''); 
        formData.append(`template[kpis_attributes][${index}][point]`, values[key]);
      }
      else if (key.startsWith('id')) {
        const index = key.replace('id', ''); 
        formData.append(`template[kpis_attributes][${index}][id]`, values[key]);
      }
    }
    return formData;
};
  handleAddFeature = () => {
    this.setState((prevState) => ({
      kpis: [...prevState.kpis, { name: '', description: '' }],
    }));
  };




  handleAccordionToggle = (departmentId: number) => {
    if (this.state.expandedDepartment === departmentId) {
      this.setState({ expandedDepartment: null }); 
    } else {
      this.setState({  isLoadingDesignations: true, expandedDepartment: departmentId });
      this.getDesignationList(departmentId);

      setTimeout(() => {
        this.setState({ isLoadingDesignations: false });
      }, 500);
    }
  };


  handleDesignationToggle = (designationId: number) => {
    this.setState(
      (prevState) => ({
        expandedDesignation: prevState.expandedDesignation === designationId ? null : designationId,
        employeeListData: [],
        isLoadingEmployees: true, 
      }),
      () => {
        if (this.state.expandedDesignation === designationId) {
          this.getEmployeeDetails(designationId);
  
          setTimeout(() => {
            this.setState({ isLoadingEmployees: false });
          }, 500);
        } else {
          this.setState({ isLoadingEmployees: false });
        }
      }
    );
  };
  
  handleChange = (event:any) => {
    this.setState({ selectedDepartment: event.target?.value });
  };
  handleDescriptionChange = (index: any, value: any) => {
    this.setState((prevState) => {
      const updatedKPIs = [...prevState.kpis];
      updatedKPIs[index].description = value;
      return { kpis: updatedKPIs };
    });
  };

  handleOpenAddKpiModel = () => {
    
    
    this.setState({
     
      togglemodel: true,
      addFeature: false,});
  };

   addKpi = () => {
    this.setState(prevState => ({
      kpis: [...prevState.kpis, prevState.kpis.length + 1]
    }));
  };

  toggleModal = (templateId:any) => {
    setStorageData('templateId',templateId)
    this.setState((prevState) => ({ isduplicateOpen: !prevState.isduplicateOpen }));
  };
  toggleModal2 = () => {
  this.setState({tagTemplateModal:!this.state.tagTemplateModal })
};
  handleClose = () => {
    this.setState({ togglemodel: false, addFeature: false});
  };
  handleClose2 = () => {
    this.setState({isduplicateOpen:false,tagTemplateModal:false,isOpen2:false});
  };
  
  handleSwitchChange = (id: number | string,name:string,actDea:boolean) => {
    this.setState({ accountIdToDisable: id,accountsName:name,activateDeactivate:actDea });
  };
  confirmDisableAccount = () => {
   this.getActivateAccount(this.state.accountIdToDisable)
    const updatedSwitchStates = [...this.state.switchStates];
  const index = updatedSwitchStates.findIndex(state => state.id === this.state.accountIdToDisable);
  if (index === -1) {
    updatedSwitchStates.push({ id: this.state.accountIdToDisable });
  }

  this.setState({
    switchStates: updatedSwitchStates,
    accountIdToDisable: null,
  });
  };
  
  getClassName=(attributes:any) =>{
    return attributes ? "table-data-row" : "disable";
  }
  
  handleEventFormSubmit = (event:any ) => {
    event.preventDefault();
    this.createEvent()
  }
  handleDisaleSwitchResponse=(responseJson:any)=>{
    if(responseJson.message){
      this.showTemplateswithKpis(this.props.navigation.getParam("id"))
      this.handleOpenKpiModal()
    }
  }

  handleChanxgeAutomatic=(event:any)=>{
    this.setState({automaticChecked: event.target?.checked})
  }

  handleChangeChecked2=(event:any)=>{
    this.setState({analyticsChecked: event.target?.checked}) 
  }

  handleRadioChange = (event:any) => {
    this.setState({ selectedOption: event.target.value });
  };
  getActivateAccount = async (id: number | string) => {
    let userToken = window.localStorage.getItem("authToken");
    const reqbody = { id };
    const fetchactvateAccountsHeader = {
      "Content-Type": configJSON.dashboarContentType,
      token: userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getActivatedAccountsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.activateAccountEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchactvateAccountsHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reqbody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.activateAccountApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  
  getAllTemplates = () => {

    const fetchTemplateHeader = {
      "Content-Type": configJSON.dashboarContentType,
      token:localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTemplateId = requestMessage.messageId;
    const endpoint = `bx_block_gamification/template_filter?department_id=`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
      
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchTemplateHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTemplateAdded = (departmentID:any) => {

    const fetchTemplateHeader = {
      "Content-Type": configJSON.dashboarContentType,
      token:localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTemplateAddedId = requestMessage.messageId;
    const endpoint = `bx_block_gamification/template_filter?department_id=${departmentID}`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
      
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchTemplateHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  uploadBulkUsers = async (file:any) => {

    const formData = new FormData()
    formData.append("file",file)

    const header = {
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userBulkUploadId = requestMessage.messageId; 
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.uploadBulkUserEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
     formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getDepartmentList = async () => {
    let userToken =localStorage.getItem('authToken')
    const fetchdepartmentsHeader = {
      "Content-Type": configJSON.dashboarContentType,
      token: userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDepartmentsID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.departmentlistEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchdepartmentsHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getDesignationList = async (departmentId: number) => {
    this.setState({designationListData:[]})
    let userToken = localStorage.getItem('authToken');
    const fetchdepartmentsHeader = {
      "Content-Type": configJSON.dashboarContentType,
      token: userToken,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getdesignationListID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_gamification/designations/designation_list?department_id=${departmentId}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchdepartmentsHeader)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  

  

  getEmployeeDetails = async (designation_id:any) => {
    this.setState({employeeListData:[]})
    let userToken =localStorage.getItem('authToken')
    const fetchemployeesHeader = {
      "Content-Type": configJSON.dashboarContentType,
      token: userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getemployeeListID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/designation_wise_employees?designation_id=${designation_id}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchemployeesHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getRolesList = async () => {
    

    const fetchdepartmentsHeader = {
      "Content-Type": configJSON.dashboarContentType,
      
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRolesID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.rolesEndPoint}/?department_id=${window.localStorage.getItem(
        "departmentId"
      )}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchdepartmentsHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  getTotalTemplates = async () => {
    let userToken = window.localStorage.getItem("authToken");

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: userToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.totalTemplateId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.filterTemplateEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getActiveUsers = () => {
    const fetchTemplateHeader = {
      "Content-Type": configJSON.dashboarContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getActiveUsersId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.activeUsersEndPoint}/?company_name=${window.localStorage.getItem(
        "cmpName"
      )}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchTemplateHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createEvent = async () => {
    const token =await getStorageData('authToken')
    const fetchactvateAccountsHeader = {
      "Content-Type": configJSON.dashboarContentType,
       token:token
    };
    
   const formattedStartDate = format(new Date(this.state.createEventData.start_date), "dd/MM/yyyy");

   const formattedEndDate = this.state.createEventData.end_date
  ? format(new Date(this.state.createEventData.end_date), "dd/MM/yyyy")
  : null;


    const reqbody = {
      event: {
        event_name: this.state.createEventData.event_name,
        start_date:formattedStartDate,
        end_date: formattedEndDate || formattedStartDate,
        day_type: this.state.createEventData.day_type,
        description:this.state.createEventData.description,
        notify_via_infoboard:this.state.notify,
    }
    }
  
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createEventId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createEventEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchactvateAccountsHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reqbody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage); 
    this.setState({ createEventData: {
      event_name: '',
      description: '',
      day_type: '',
      start_date: null,
      end_date: null,
    },   checkBoxMul:false,
         checkBoxRes:true,
         isMultiple: false,
         isChecked:false
  }
  )
  };

  showTemplateswithKpis = async (id:number) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token:localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getShowTempaltewithKpiId= requestMessage.messageId;
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     
     `${configJSON.showTemplatesEndPoint}/${id}`
    );
   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  disableKpisSwitch = async () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
     
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDisableKpiId= requestMessage.messageId;
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     
     `${configJSON.disableKpiEndPoint}/?id=${this.state.selectedKpiIds}`
    );
   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.activateAccountApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getUpdateTEmplateKpiDetail = async(id: number) => {
    
    const formData=this.createFormData(this.state.kpiFormData)
    let userToken = window.localStorage.getItem("authToken");
    const fetchactvateAccountsHeader = {
      token: userToken
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUpdateTemplateKpiId= requestMessage.messageId;
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.showTemplatesEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchactvateAccountsHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
     formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.activateAccountApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  KpiGamecard = (path: any) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };
  Statistics = (path: any) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };
  KpiReport = (path: any) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };
  isSunday = (date: any): boolean => {
    return date instanceof Date && date.getDay() === 0;
  };

  formatDateRange = (startDate: Date | null, endDate: Date | null): JSX.Element | any => {
    const formatOptions: Intl.DateTimeFormatOptions = { 
      day: 'numeric', 
      month: 'short', 
      year: 'numeric' 
    };
  
    const formattedStartDate = startDate instanceof Date
      ? startDate.toLocaleDateString('en-GB', formatOptions)  
      : '';
  
    const formattedEndDate = endDate instanceof Date
      ? endDate.toLocaleDateString('en-GB', formatOptions)  
      : '';
  
    if (formattedStartDate || formattedEndDate) {
      return `${formattedStartDate} - ${formattedEndDate}`;
    }
    
    return <span style={{ fontFamily: "Open Sans", fontSize: "16px", fontWeight: 400, lineHeight: '22px', textAlign: 'left', color:'#959595'   }}>Pick a date</span>;
  };
  
  formatSingleDate = (date: Date | null): JSX.Element | any => {
    const formatOptions: Intl.DateTimeFormatOptions = { 
      day: 'numeric', 
      month: 'short', 
      year: 'numeric' 
    };
  
    if (date instanceof Date) {
      return date.toLocaleDateString('en-GB', formatOptions);
    } 
  
    return <span style={{ fontFamily: "Open Sans", fontSize: "16px", fontWeight: 400, lineHeight: '22px', textAlign: 'left', color:'#959595'
       }}>Pick a date</span>;
  };

  getEventDetails = async() => {
    let userToken = window.localStorage.getItem("authToken");
    const fetchactvateAccountsHeader = {
      token: userToken
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEventDetailsId= requestMessage.messageId;
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `bx_block_gamification/events/company_events`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchactvateAccountsHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

   formatMonth =(date :any) => {
    const [year, month, day] = date.split("-");
    const formattedMonth = parseInt(month, 10) < 11 ? `0${month}` : month;
    return `${day?.padStart(2, '0')}-${formattedMonth}-${year}`;
  };

  getEventDetailsResposne =(responseJson:any)=>{
    if(responseJson.data){
      this.setState({showEventName:responseJson.data})
      this.setState({allEvent:responseJson.data})
      const startDates = this.state.showEventName.map((event :any) =>  this.formatMonth(event.attributes.start_date));
      const endDates = this.state.showEventName.map((event: any) => 
        this.formatMonth(event.attributes.end_date)
    );
   
      this .setState({eventStartDate:startDates , eventEndDate :endDates})
    } 
  }
  resetScroll=()=>{
    const eventBlocks = document.getElementById('event-blocks');
    if (eventBlocks) {
      eventBlocks.scrollTop = 0; 
    }
  }
  handleClickOutside = (event :any) => {    
    if (this.datePickerRef.current && !this.datePickerRef.current.contains(event.target)) {
       this.setState({datepickerClose :false , isOpen2: false, flagButton:false})
    }   
    else if ((this.departmentRef.current && !this.departmentRef.current.contains(event.target))) 
      {
        this.setState({ isOpen: false})
     } 
     else if ((this.statusRef.current && !this.statusRef.current.contains(event.target))) 
      {
        this.setState({ isTemplateStatus: false})
     } 
     
  };
  handleClickOutside2 = (event :any) => {    
   if ((this.statusRef.current && !this.statusRef.current.contains(event.target))) 
      {
        this.setState({ isTemplateStatus: false})
     } 
     
  };
 
 
  async componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("mousedown", this.handleClickOutside2);
  }
  handleDateChange = (date :any) => {
    this.resetScroll()
    this.setState({ selectedDate: date });
  }
handleMonth=(value :any)=>{
  this.resetScroll()
    const date = new Date(value);

    const month = date.getMonth(); 
    const year = date.getFullYear();
    this.setState({selectedYear:year,selectedMonth:month})
    this.setState({selectedDate:null})

   const firstDate = new Date(year, month, 1);

    const lastDate = new Date(year, month + 1, 0);

    const formattedFirstDate = this.formatDateEn(firstDate);
    const formattedLastDate = this.formatDateEn(lastDate);
    this.setState({showeEventDateFirst :formattedFirstDate,showeEventDateLast:formattedLastDate})

 }

formatDateEnvent = (dateString: any) => {
  console.log(dateString, "DateString");

  const dateStr = String(dateString);
  
  const [, month, day] = dateStr.split("-");
  const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
  ];
  const monthName = monthNames[parseInt(month, 10) - 1];

  const dayNum = parseInt(day, 10); 

  const daySuffix = this.endDaySuffix(dayNum);
  return `${dayNum}${daySuffix} ${monthName} Events`;
};


currentDate=()=>{
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth(); 

  const firstDate = new Date(year, month, 1);

  const lastDate = new Date(year, month + 1, 0);

  const formattedFirstDate = this.formatDateEn(firstDate);
  const formattedLastDate = this.formatDateEn(lastDate);
  this.setState({showeEventDateFirst :formattedFirstDate,showeEventDateLast:formattedLastDate})


}
formatDateEn(date :any) {
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return date.toLocaleDateString('en-GB', options).replace(',', '');
}

getTileClassName = (realDate:any) => {
  return this.state.showEventName.some((event :any)=> {
    const { start_date, end_date } = event.attributes;
    if (start_date && end_date && new Date(start_date) <= new Date(end_date)) {
        return realDate >= start_date && realDate <= end_date;
    } else {
        return realDate === start_date;
    }
}) ? 'highlight' : null;
};

handleDecreaseMonthClick = (decreaseMonth: any, e: any) => {
  decreaseMonth(); 
  e.stopPropagation();
}
handleIncreaseMonthClick = (increaseMonth: any, e:any) => {
  increaseMonth(); 
  e.stopPropagation();
}


renderSortedEvents(groupedEvents: any) {
  const sortedDates = Object.keys(groupedEvents).sort(
    (a, b) => new Date(a.split(' - ')[0]).getTime() - new Date(b.split(' - ')[0]).getTime()
  );

  return sortedDates.map((dateRange) => {
    const events = groupedEvents[dateRange];
    const firstEvent = events[0];
    const startDate = firstEvent?.attributes.start_date;
    const endDate = firstEvent?.attributes.end_date;

    
    const formattedStartDate = this.formatDateEnvent(startDate);
    const eventDateText = startDate !== endDate
  ? this.formatEventDateRange(startDate, endDate)
  : `${formattedStartDate}`;

    return (
      <div className="event-block" key={dateRange}>
      <h5 className="event-dates">{eventDateText}</h5>
      <ul>
      {events.map((event: any) => (
        <div key={event.id}>
          <li>
          <h5 className="event-title-name">{event.attributes.event_name}</h5>
          <span className="event-remind">1h Early Reminder</span>
          </li>
        </div>
      ))}
      </ul>
    </div>
    );
  });
}

formatEventDateRange = (startDate: string, endDate: string) => {
  const [startYear, startMonth, startDay] = startDate.split("-");
  const [endYear, endMonth, endDay] = endDate.split("-");

  if ( startYear === endYear && startMonth === endMonth) {
      const startDayNum = parseInt(startDay, 10); const endDayNum = parseInt(endDay, 10);
       const monthName = this.formatDateEnvent(startDate).split(" ")[1];  const endDaySuffix = this.endDaySuffix(endDayNum);

      return `${startDayNum} to ${endDayNum}${endDaySuffix} ${monthName} Events`;
  } else {
      return `${this.formatDateEnvent(startDate)} to ${this.formatDateEnvent(endDate)}`;
  }
};
endDaySuffix = (day: number) => {
    if (day === 1 || day === 21 || day === 31) return "st";
    if (day === 2 || day === 22) return "nd";
    if (day === 3 || day === 23) return "rd";
    return "th";
};
handleSelectionTemplate=(val :any)=>{
this.setState({selectedTemplateName:val.attributes.name,selectedTemplateCheckBox:val.id})
}
handleSelectionTemplate2=(val :any)=>{
  this.setState({selectedEmployeeName:val.attributes.name,selectedEmployeeCheckBox:val.id})
  
  }
handleTagTemplateModal=(item :any)=>{
this.setState({tagTemplateModal:!this.state.tagTemplateModal ,selectedTemplateData:item , isOpen: false, isOpen2: false, spacificDepartmentID:item.department_id},()=>{
  this.getSpacificTemplates()
})

}

getCircularProgressStyle = (item :any) => {
  if (!item.activate_deactivate) {
    return { color: 'gray' };
  }
  return { color: Number(item.kpi_progress) > 40 ? 'green' : 'red' };
};

handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
  if (event.target.files) {
    this.setState({bulkuploadFile:event.target.files[0].name ,showUploadError:""})
  }
};

handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
  event.preventDefault();
  event.stopPropagation();
  if (event.dataTransfer.files) {
        this.setState({bulkuploadFile:event.dataTransfer.files[0].name ,showUploadError:""})
  }
};

handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
  event.preventDefault();
};

 handleDownloadCsvFile = () => {
  const csvContent = "data:text/csv;charset=utf-8,First name,Last name,Email,Organization Name,Department name,Designation name,Employee role,Reporting to,Employee type,Country,State,City,Shift Timings,Time Zone,Work full phone number,Higher education,Preferred pronounce,Home town,Address,Blood group,Date of birth,Brief about employee,Ask employee about expertise,Seating location,Date of joining\n";
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "bulkupload.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

handleUploadBulk=()=>{
  this.setState({isOpneUserBulkUpload:true})
}
handleUploadCsv=()=>{
if(!this.state.bulkuploadFile){
  this.setState({showUploadError:"Please select file"})
}
else{
  this.setState({bulkUploadProgress:false ,isOpneUserBulkUpload:false})
}
}
handleCancelCsv=()=>{
  this.setState({bulkuploadFile:"",isOpneUserBulkUpload:false})
}
getSpacificTemplates = () => {
    const fetchTemplateHeader = {
      "Content-Type": configJSON.dashboarContentType,
      token:localStorage.getItem("authToken")
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSpacificTemplateID = requestMessage.messageId;
    const endpoint = `bx_block_gamification/template_filter?department_id=${this.state.spacificDepartmentID}`;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
      
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchTemplateHeader)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getSpacificTemplateRes=(responseJson :any)=>{
   if(responseJson.data){
    this.setState({getTemplateName:responseJson.data})
   }
  }
  handleTagTemplateCancel=()=>{
    this.setState({selectedTemplateName:"Choose Template",tagTemplateModal:false})
  }
  navigateToTemplateAdded = async () => {
    try {
      await this.duplicateTemplate();
      this.setState(
        { isduplicateOpen: false },
        () => {
          this.getTotalTemplates(); 
        }
      );
      localStorage.setItem(
        "DepartmentNavigationId",
        this.state.departmentNavigateId
      );
    } catch (error) {
      console.error("Something went wrong:", error);
    }
  };
  

  duplicateTemplate = async () => {
    const token = await getStorageData("authToken");
    const templateId = await getStorageData("templateId");
    const header = {
      token:token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.duplicateTemplateNewId = requestMessage.messageId; 
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_gamification/organization_templates/${templateId}/duplicate_template`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  navigateToEdit= async (path: any,templateNewId:any)=>{
    await setStorageData('templateId',templateNewId.attributes.id)
    await setStorageData('currentStatus',templateNewId.attributes.status)
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  }
  getButtonText = (template:any) => {
    return template.attributes?.status?.toLowerCase() === "added" ? "View Details" : "Edit";
  }
// Customizable Area End

}
