import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import toast from "react-hot-toast";
import { createRef } from "react";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  templateAdd: any
  tagTemplateModal: boolean,
  isduplicateOpen: boolean,
  isOpen: boolean,
  selectedTemplateData: any,
  selectedTemplateName: string,
  selectedEmployeeCheckBox: any,
  getTemplateName: any,
  circularLoading: boolean,
  tagTemplateName: any;
  tagTemplateDepartment: any;
  isSelectOpen: boolean;
  alreadyAssigned: any;
  accountAvailable: any;
  EmployeeName: any;
  errorMessage: string;

  // Customizable Area End
}


interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TemplateAddedController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTemplateAddedResponseID: string = "";
  getTagTemplateID: string = " ";
  submitTagTemplateID: string = " ";
  dropdown: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      templateAdd: [],
      tagTemplateModal: false,
      isduplicateOpen: false,
      isOpen: false,
      selectedTemplateData: [],
      selectedTemplateName: '',
      // selectedEmployeeCheckBox:0,
      getTemplateName: [],
      circularLoading: true,
      tagTemplateName: '',
      tagTemplateDepartment: '',
      isSelectOpen: false,
      alreadyAssigned: [],
      accountAvailable: [],
      EmployeeName: [],
      selectedEmployeeCheckBox: [],
      errorMessage: '',
      // Customizable Area End
    };
    this.dropdown = createRef()
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getTemplateResponses()
    document.addEventListener("mousedown", this.handleClickOutside);
    // Customizable Area End
  }

  async componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event: any) => {
    if (this.dropdown.current && !this.dropdown.current.contains(event.target)) {
      this.setState({ isSelectOpen: false })
    }
  };
  handleStopPropagation = (e: any) => {
    e.stopPropagation()
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getTemplateAddedResponseID) {
        this.getTemplateAddedDetail(responseJson)
      }
      else if (apiRequestCallId === this.getTagTemplateID) {
        this.getTagTemplateDetails(responseJson)
      }
      else if (apiRequestCallId === this.submitTagTemplateID) {
        this.getSubmitTemplateDetails(responseJson)
      }
    }
    // Customizable Area End
  }
  // Customizable Area End
  navigateToEditTemplate2 = () => {
    this.props.navigation.navigate("EditTemplate2")
  };
  getTemplateAddedDetail = async (responseJson: any) => {
    if (responseJson) {
      const templateId = await getStorageData('templateId');
      const templatedFilteredData = responseJson.data.filter((data: any) => {
        return data.id === templateId;
      });      
      this.setState({ templateAdd: templatedFilteredData ,circularLoading:false});
    }
  };
  getTagTemplateDetails = async (responseJson: any) => {
    if (responseJson) {
      this.setState({
        tagTemplateName: responseJson?.organization_template,
        tagTemplateDepartment: responseJson?.department,
        alreadyAssigned: responseJson?.already_assigned,
        accountAvailable: responseJson?.account_available
      })
    }
  }
  getSubmitTemplateDetails = (responseJson: any) => {
    if (responseJson && responseJson.message) {
      toast.success(responseJson.message)
      this.setState({ tagTemplateModal: false, EmployeeName: [] })
    }
    else {
      toast.error(responseJson.error)
    }
  }
  tagTemplateFunction = () => {
    this.setState({ tagTemplateModal: true })
    this.getTagTemplates()
  }
  handleTagTemplateClose = () => {
    this.setState({ tagTemplateModal: false, EmployeeName: [] })
  }
  toggleDropdown = () => {
    this.setState({ isSelectOpen: !this.state.isSelectOpen });
  };

  getTemplateResponses = async () => {
    const fetchTemplateAddedReponseHeader = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTemplateAddedResponseID = requestMessage.messageId;
    const endpoint = `bx_block_gamification/template_filter?department_id=`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchTemplateAddedReponseHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleSelectionTemplate = (val: any) => {
    const { EmployeeName, selectedEmployeeCheckBox } = this.state;
    const isAlreadySelected = selectedEmployeeCheckBox.includes(val.id);
    const updatedNames = isAlreadySelected
      ? EmployeeName.filter((name: any) => name !== val.name)
      : [...EmployeeName, val.name];

    const updatedCheckBox = isAlreadySelected
      ? selectedEmployeeCheckBox.filter((id: any) => id !== val.id)
      : [...selectedEmployeeCheckBox, val.id];
    this.setState(
      {
        EmployeeName: updatedNames,
        selectedEmployeeCheckBox: updatedCheckBox,
        errorMessage: ""
      },
      () => {
        console.log(this.state.selectedEmployeeCheckBox, 'Hii');
      }
    );
  };
  saveTemplateModal = () => {
    if (this.state.EmployeeName.length == 0) {
      this.setState({ errorMessage: "Please select employee" })
    }
    else {
      this.submitTagTemplate()
    }
  }

  getTagTemplates = async () => {
    const token = await getStorageData('authToken')
    const templateId = await getStorageData("templateId");
    const fetchTemplateAddedReponseHeader = {
      "Content-Type": configJSON.dashboarContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTagTemplateID = requestMessage.messageId;
    const endpoint = `bx_block_gamification/organization_templates/${templateId}/teg_template`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchTemplateAddedReponseHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  submitTagTemplate = async () => {
    const token = await getStorageData('authToken')
    const templateId = await getStorageData("templateId");
    const fetchTemplateAddedReponseHeader = {
      "Content-Type": configJSON.dashboarContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.submitTagTemplateID = requestMessage.messageId;
    const endpoint = `bx_block_gamification/organization_templates/${templateId}/assign_to_accounts`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    const reqbody = {
      account_ids: this.state.selectedEmployeeCheckBox
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchTemplateAddedReponseHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reqbody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area Start
  getButtonText = (template:any) => {
    const data = ["added", "approved"];
    return data.includes(template.attributes.status) ? "View Template" : "Edit Template";
  }
  // Customizable Area End
}
