import React from "react";

import {
    Box,
    Grid,
    Card, CardContent,
    Typography,
    Tooltip,
    styled,
    Button,
    Modal,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import "../../dashboard/src/dashboard.css"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExpandLess from '@material-ui/icons/ExpandLess';

import { Leaderboard, Scoreboard, Infoboard, leader_icon, Polygon, Medallion, pngegg, close_Icon } from './assets';


// Customizable Area End

import AddKpiController, { Props } from "./AddKpiController";
import { user_profile } from "../../CustomisableUserProfiles/src/assets";
import { oval } from "../../dashboard/src/assets";

export default class Statistics extends AddKpiController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    formatPrivacyPolicyText(text: string) {
        return text
            .replace(/\r\n|\n/g, "<br />")
            .replace(/ {2,}/g, (spaces) => "&nbsp;".repeat(spaces.length))
            .replace(/\\"/g, '"');
    }

    descriptionModal = (description: string) => {
        const formattedText = this.formatPrivacyPolicyText(description);
        return (
            <div
                dangerouslySetInnerHTML={{ __html: formattedText }}
            />
        );
    };
    descriptionModalSecond = (description: string) => {
        const formattedText = this.formatPrivacyPolicyText(description);
        return (
            <div
                dangerouslySetInnerHTML={{ __html: formattedText }}
            />
        );
    };
    renderCards() {
        const { infoBoardData } = this.state;

        return ( 
            <>
            {infoBoardData && infoBoardData.length === 0 && (
                <div style={{marginLeft:'40vw',color:'black'}}>
                  No records found!
                </div>
              )}
              
            <InfoGridContainer container spacing={4} >
                {infoBoardData.map((item: any) => (
                    <StyledGridItemBox item key={item.id}  >
                        <StyledCard>
                            <Title style={webStyles.titleStyles}>{item.title}</Title>
                            {item.description?.length > 257 || item.event_id || item.title.length > 66 ? (<> <DescriptionBox style={webStyles.descriptionStyle} >
                                <DescriptionTypography>
                                    {this.descriptionModalSecond(item.description)}
                                </DescriptionTypography>
                            </DescriptionBox>
                                <ButtonContainer data-test-id='clickOne' onClick={() => { this.handleOpenSecond(item.title, item.event_id, this.descriptionModalSecond(item.description)) }} >
                                    <StyledButton data-test-id="infoboard-submit">
                                        <ViewMoreTypography className="view-more-text"> View More</ViewMoreTypography>
                                    </StyledButton>
                                </ButtonContainer></>) : (<SecondDescription style={webStyles.descriptionStyle} >
                                    <DescriptionTypography >
                                        {this.descriptionModalSecond(item.description)}
                                    </DescriptionTypography>
                                </SecondDescription>)
                            }
                        </StyledCard>
                    </StyledGridItemBox>
                ))}
            </InfoGridContainer>
        </>
        );
    }

    // Customizable Area End

    render() {
        const { openNew, dayType, startDate, endDate, descriptionModalSecond, title, open, infoTitle, infoDescription } = this.state
        return (
            // Customizable Area Start
            <Box style={webStyles.maincontainer}>
                <Box style={webStyles.headerSection}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid style={webStyles.leftContainer} item>
                            <ArrowBackIosIcon data-test-id="goback" onClick={() => this.Goback("EmployeeDashboard")} style={{ fontSize: '25px', marginRight: '20px' }} />
                            <span data-test-id="gobackhome" style={webStyles.labelStyle1} onClick={() => this.Goback("EmployeeDashboard")}>Home</span>
                            <ArrowForwardIosIcon style={{ fontSize: '18px', marginRight: '10px', marginLeft: '10px' }} />
                            <span data-test-id="StatisticsBackId" style={webStyles.labelTwoStyle1} onClick={() => this.handlePageTabBackNavigate()} >Statistics</span>
                            {this.state.statisticsTab && <><ArrowForwardIosIcon style={{ fontSize: '18px', marginRight: '10px', marginLeft: '10px' }} />
                                <span style={webStyles.labelTwoStyle1}>{this.state.statisticsTab}</span></>}
                        </Grid>
                    </Grid>
                </Box>

                <Grid container className='stepperContainer' spacing={5}>
                    <Grid
                        item
                        alignItems="center"
                        lg={12}
                    >
                        {this.state.statisticsTab == "" && <StaticContainer > <Grid alignItems="center" item style={{ boxShadow: '0px 4px 18px 0px #00000017' }}  >
                            <Card data-test-id="AddKpiReportId" onClick={() => this.handlePageTabNavigate("Leaderboard")} style={webStyles.root as React.CSSProperties} variant="outlined">
                                <CardContent style={{ cursor: "pointer" }}>
                                    <img src={Leaderboard} alt="logo" width={124} height={98} />
                                    <LeaderboardTypography  >
                                        Leaderboard
                                    </LeaderboardTypography>
                                </CardContent>
                            </Card>
                        </Grid>
                            <Grid alignItems="center" item style={{ boxShadow: '0px 4px 18px 0px #00000017' }}>
                                <Card data-test-id="KpiGameCardId" onClick={() => this.handlePageTabNavigate("Scoreboard")} style={webStyles.root as React.CSSProperties} variant="outlined">
                                    <CardContent style={{ cursor: "pointer", }}>
                                        <img src={Scoreboard} alt="logo" width={113} height={113} />
                                        <ScoreboardTypography
                                        >
                                            Scoreboard
                                        </ScoreboardTypography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid alignItems="center" item style={{ boxShadow: '0px 4px 18px 0px #00000017' }}>
                                <Card data-test-id="StatisticsId" onClick={() => this.handlePageTabNavigate("Infoboard")} style={webStyles.root as React.CSSProperties} variant="outlined">
                                    <CardContent style={{ cursor: "pointer", }}>
                                        <img src={Infoboard} alt="logo" width={103} height={101} />
                                        <InfoboardTypography>
                                            Infoboard
                                        </InfoboardTypography>
                                    </CardContent>
                                </Card>
                            </Grid></StaticContainer>}
                        {this.state.statisticsTab == "Leaderboard" && <>
                            <LeaderboardBox>
                                <LeaderboardSubBox>
                                    <img style={{ width: '100%', height: '250px' }} src={leader_icon} />
                                    <div style={webStyles.oneTwoThree} className="static_container">
                                        <div className="rectangleBox box_image_one" style={{ height: '163px' }}>
                                            <ReactangeBoxDetailsFirst className="rectangle_box_details" >
                                                <img style={{ width: '65px' }} src={user_profile} />
                                                <RankNewTypography>2nd</RankNewTypography>
                                                <NameNewTypography >Daniel</NameNewTypography>
                                                <PositionNewTypography >Hiring Manager</PositionNewTypography>
                                            </ReactangeBoxDetailsFirst>
                                        </div>
                                        <div className="rectangleBox box_image_two" style={{ height: '211px' }}>
                                            <ReactangeBoxDetailsTwo className="rectangle_box_details" >
                                                <img style={{ width: '80px' }} src={pngegg} />
                                                <img style={{ width: '80px' }} src={user_profile} />
                                                <RankNewTypography >1nd</RankNewTypography>
                                                <NameNewTypography >Emma</NameNewTypography>
                                                <PositionNewTypography >Executive</PositionNewTypography>
                                            </ReactangeBoxDetailsTwo >
                                        </div>
                                        <div className="rectangleBox box_image_three" style={{ height: '151px' }}>
                                            <ReactangeBoxDetailsThree className="rectangle_box_details" >
                                                <img style={{ width: '60px' }} src={user_profile} />
                                                <RankNewTypography >3nd</RankNewTypography>
                                                <NameNewTypography >Samantha</NameNewTypography>
                                                <PositionNewTypography >Executive</PositionNewTypography>
                                            </ReactangeBoxDetailsThree>
                                        </div>
                                    </div>
                                </LeaderboardSubBox>
                                <div style={{ marginTop: '100px' }}>
                                    <div className="static-profile-tab">
                                        <h5 className={`${this.state.tabNumber == 1 ? "static-tab-border" : "static-tab"}`} onClick={() => { this.setState({ tabNumber: 1 }) }}>Monthly</h5>
                                        <h5 className={`${this.state.tabNumber == 2 ? "static-tab-border" : "static-tab"}`} onClick={() => { this.setState({ tabNumber: 2 }) }}>Yearly</h5>
                                        <h5 className={`${this.state.tabNumber == 3 ? "static-tab-border" : "static-tab"}`} onClick={() => { this.setState({ tabNumber: 3 }) }}>All-time</h5>
                                    </div>
                                    <ParentContainer style={{ width: "100%", display: 'flex' }}>
                                        <div style={{ width: this.state.tabNumber == 3 ? '80%' : "100%", margin: "0px 30px" }}>
                                            {[1, 2, 3, 4, 5].map((item) => {
                                                return (
                                                    <Box display={'flex'} justifyContent={'space-between'}>
                                                        <Box style={webStyles.meetingDetail} display={'flex'}>
                                                            <NameContainer><img style={{ width: '48px', marginRight: '33px' }} src={oval} />Ralph Gomez</NameContainer>
                                                            <Box style={{ width: '33.33%', display: 'flex', justifyContent: 'end' }}><DesignerBox>UI Designer</DesignerBox></Box>
                                                            <Box style={{ width: '33.33%', textAlign: "end" }}><StyledImage  src={Polygon} /><span style={{ position: 'relative', bottom: '6px', right: '9px', fontSize: '12px' }}>4th</span></Box>
                                                        </Box>
                                                    </Box>
                                                )
                                            })}
                                        </div>
                                        {this.state.tabNumber == 3 &&
                                            <DepartmentContainer >
                                                <div style={{ fontWeight: 700 }}>Department</div>
                                                {["Design", "Delivery", "Procurement", "Sales", "Executive"].map((item) => {
                                                    return (
                                                        <ItemContainer >
                                                            <div><input style={{ width: "19px", height: '19px' }} type="checkbox" /></div>
                                                            <div>{item}</div>
                                                        </ItemContainer>
                                                    )
                                                })}
                                            </DepartmentContainer>}
                                    </ParentContainer>
                                </div>
                            </LeaderboardBox>
                        </>}
                        {this.state.statisticsTab == "Scoreboard" && <>
                            <div style={{ display: 'flex' }}>
                                <Box style={{ width: this.state.scoreTabNumber == 0 ? "100%" : "50%" }}>
                                    <Box data-test-id="Monthly" style={webStyles.Scoreboard} display={'flex'} onClick={() => { this.setState({ scoreTabNumber: 1 }) }}>
                                        <Box style={{ fontWeight: this.state.scoreTabNumber == 1 ? 700 : 400 }}>Monthly</Box>
                                        <ArrowForwardIosIcon style={{ fontSize: '18px', marginRight: '10px', marginLeft: '10px' }} />
                                    </Box>
                                    <Box data-test-id="Yearly" style={webStyles.Scoreboard} display={'flex'} onClick={() => { this.setState({ scoreTabNumber: 2 }) }}>
                                        <Box style={{ fontWeight: this.state.scoreTabNumber == 2 ? 700 : 400 }}>Yearly</Box>
                                        <ArrowForwardIosIcon style={{ fontSize: '18px', marginRight: '10px', marginLeft: '10px' }} />
                                    </Box>
                                    <Box data-test-id="All-time" style={webStyles.Scoreboard} display={'flex'} onClick={() => { this.setState({ scoreTabNumber: 3 }) }}>
                                        <Box style={{ fontWeight: this.state.scoreTabNumber == 3 ? 700 : 400 }}>All time</Box>
                                        <ArrowForwardIosIcon style={{ fontSize: '18px', marginRight: '10px', marginLeft: '10px' }} />
                                    </Box>
                                </Box>
                                {this.state.scoreTabNumber > 0 && <Box style={{ margin: "0px 40px 0px 0px", width: "50%" }}>
                                    <Box style={webStyles.ScoreboardDetailTital} marginLeft={"0px"} display={'flex'}>
                                        <Box>KPI</Box>
                                        <Box>Rank</Box>
                                        <Box>Points</Box>
                                        <Box>Badges</Box>
                                    </Box>
                                    {[1, 2, 3, 4, 5, 6].map((item) => {
                                        return (
                                            <Box style={webStyles.ScoreboardDetail} marginLeft={"0px"} display={'flex'}>
                                                <ScoreBoardDeatilsBox >Sales</ScoreBoardDeatilsBox>
                                                <ScoreBoardDeatilsBox>1nd</ScoreBoardDeatilsBox>
                                                <ScoreBoardDeatilsBox color={"#0742BF"}>38/45</ScoreBoardDeatilsBox>
                                                <ScoreBoardDeatilsBox display={"flex"}>2
                                                    <StyledTooltip title={
                                                        <PointsBox >
                                                            <div><img src={Medallion} /> Ranker</div>
                                                            <div><img src={Medallion} /> 20Pts Achieved</div>
                                                        </PointsBox>
                                                    } placement="right-start"
                                                    >
                                                        <ExpandLess />
                                                    </StyledTooltip>
                                                </ScoreBoardDeatilsBox>
                                            </Box>
                                        )
                                    })}
                                </Box>}
                            </div>
                        </>}
                        {this.state.statisticsTab === "Infoboard" && this.renderCards()}
                        <Modal open={openNew}
                            onClose={this.handleCloseNew}>
                            <Box style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 600,
                                backgroundColor: 'white',
                                padding: '38px',
                                borderRadius: '8px',
                            }}>
                                <ModalHeaderBox style={webStyles.modalTextStyle} >
                                    <Typography style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '20px',
                                        fontWeight: 700,
                                        color: '#000000'
                                    }}>{title}</Typography>
                                    <CrossButton
                                        data-test-id="handleClose"
                                        onClick={this.handleCloseNew}
                                    ><img src={close_Icon} alt='X' /></CrossButton>
                                </ModalHeaderBox>
                                <TextContainer >
                                    <EventTypeTypography ><span style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        color: '#000000',
                                    }} >Event Type</span><span style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        color: '#000000'
                                    }}>{dayType}</span></EventTypeTypography>
                                    <StartDateTypography ><span style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        color: '#000000',
                                    }}>Start Date</span> <span style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        color: '#000000',
                                    }}
                                    >{startDate}</span></StartDateTypography>
                                    <EndDateTypography ><span style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        color: '#000000',
                                    }}>End Date</span> <span style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        color: '#000000'
                                    }}
                                    >{endDate}</span></EndDateTypography>
                                </TextContainer>
                                <Box>
                                    <ManagerRemarkTypography>Detailed Description</ManagerRemarkTypography>
                                    <DummyModalContentBox style={webStyles.modalTextStyle}><DescriptionNewTypography >{descriptionModalSecond}</DescriptionNewTypography></DummyModalContentBox>
                                </Box>
                            </Box>
                        </Modal>
                        <Modal open={open}
                            onClose={this.handleCloseNew}>
                            <ModalBox>
                                <ModalHeaderBox style={webStyles.modalTextStyle} >
                                    <InfoTypography>{infoTitle}</InfoTypography>
                                    <CrossButton
                                        data-test-id="handleClose"
                                        onClick={this.handleCloseNew}
                                    ><img src={close_Icon} alt='X' /></CrossButton>
                                </ModalHeaderBox>
                                <Box>
                                    <ManagerRemarkTypography>Detailed Description</ManagerRemarkTypography>
                                    <DummyModalContentBox style={webStyles.modalTextStyle}><DecriptionSecondTypography>{infoDescription}</DecriptionSecondTypography></DummyModalContentBox>
                                </Box>
                            </ModalBox>
                        </Modal>
                    </Grid>
                </Grid>
            </Box>
            // Customizable Area End
        );
    }
}
const StyledTooltip = styled(Tooltip)({
    display: 'block'
})
// Customizable Area Start
const webStyles = {
    calendarWrapper: {
        display: "flex",
        justifyContent: "space-between",
        width: "298px",
        height: "62px",
        background: "#fff",
        alignItems: "center",
        padding: "15px 20px",
        borderRadius: "8px"
    },
    infoGrid: {
        border: "2px solid red",
        display: 'flex',
        flexWrap: 'wrap',
        padding: "32px 32px 0px 37px",
        gap: '32px',
    },
    cotinueBtn: {
        margin: "20px 0 0",
        fontWeight: 600,
        height: "45px",
        maxWidth: '142px',
        width: "100%",

    },
    meetingDetail: {
        boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.1)",
        padding: "5px 44px",
        width: "100%",
        fontSize: "16px",
        fontWeight: 400,
        color: "#000000",
        margin: "0px 0px 24px",
        borderRadius: "20px",
        justifyContent: 'space-between',
        alignItems: "center",
    },
    Scoreboard: {
        padding: "19px 23px",
        boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.1)",
        fontSize: "16px",
        margin: "0px 30px 24px",
        borderRadius: "20px",
        fontWeight: 400,
        justifyContent: 'space-between',
        alignItems: "center",
        color: "#000000",
    },
    ScoreboardDetail: {
        padding: "19px 23px",
        background: "linear-gradient(180deg, #FFF26D 0%, #FFF9E2 130.37%)",
        width: "100%",
        fontSize: "16px",
        margin: "0px 30px 24px",
        fontWeight: 400,
        justifyContent: 'space-between',
        alignItems: "center",
        color: "#000000",
    },
    ScoreboardDetailTital: {
        width: "100%",
        padding: "19px 23px",
        background: "rgba(243, 244, 245, 1)",
        justifyContent: 'space-between',
        fontSize: "16px",
        margin: "0px 30px 24px",
        color: "#000000",
        alignItems: "center",
        fontWeight: 400,
    },
    oneTwoThree: {
        position: "absolute",
        top: "31%",
        display: 'flex',
        alignItems: "baseline"
    },
    maincontainer: {
        background: "#F3F4F5",
    },
    headerSection: {
        backgroundColor: '#F3F4F5',
        padding: '16px',
    },
    leftContainer: {
        padding: "19px 0px 21px 20px",
        alignItems: 'center',
        display: 'flex',
    },
    labelStyle1: {
        fontSize: "18px",
        lineHeight: '24px',
        color: '#000000',
        cursor: 'pointer'
    },
    labelTwoStyle1: {
        fontSize: "18px",
        lineHeight: '24px',
        color: '#000000',
        fontWeight: 600,
        cursor: 'pointer'
    },
    descriptionStyle: {
        wordWrap: 'anywhere',
        marginBottom: '57px',
    },
    titleStyles: {
        wordWrap: 'anywhere',
        marginBottom: '25px',
    },
    modalTextStyle: {
        wordWrap: 'anywhere',
    },
    root: {
        minWidth: 245,
    },
} as any;
const InfoGridContainer = styled(Grid)({
    padding: '34px 38px',
     display: 'flex',
      justifyContent: 'flex-start', 
      flexWrap: 'wrap' 
});

const StyledCard = styled("div")({
    height: '358px',
    padding: "37px 27px",
    borderRadius: "8px",
    textAlign: "start",
    background: "#F9F9F9",
    boxShadow: '0px 4px 18px 0px #00000017',
});

const Title = styled("div")({
    fontSize: "18px",
    height: '48px',
    fontWeight: 600,
    marginBottom: "23px",
    color: "#000000",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    WebkitLineClamp: 2,
    textOverflow: "ellipsis",
});



const DescriptionBox = styled("div")({
    minHeight: "120px",
    maxHeight: "120px",
    overflow: "hidden",
});
const SecondDescription = styled("div")({
    minHeight: "120px",
    maxHeight: "120px",
    marginBottom: '119px',
    overflow: "hidden",
});
const ButtonContainer = styled("div")({
    display: 'flex',
    justifyContent: 'flex-end',
    // marginTop: "51px",
});

const StyledButton = styled(Button)({
    background: "#FFCC00",
    cursor: "pointer",
    display: 'flex',
    justifyContent: 'center',
    padding: '12px',
    alignItems: 'flex-end',
    width: '144px',
    borderRadius: '8px',
    '&:hover': {
        backgroundColor: '#6191c7',
        cursor: 'pointer',
        '& .view-more-text': {
            color: '#ffffff',
        },
    },
})
const ViewMoreTypography = styled(Typography)({
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
    color: "#000000",
    textTransform: 'none',
})
const DescriptionTypography = styled(Typography)({
    fontSize: "16px",
    fontWeight: 400,
    color: "#000000",

})

const ModalHeaderBox = styled(Box)({
    display: "flex",
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '10px',
    gap: '10px',
    cursor: 'pointer',
})
const DummyModalContentBox = styled(Box)({
    background: '#F7F7F7',
    padding: '14px',
    borderRadius: '8px',
    maxHeight: '340px',
    overflow: 'auto',
})
const ManagerRemarkTypography = styled(Typography)({
    fontFamily: 'Open Sans',
    fontSize: '15px',
    fontWeight: 700,
    color: '#000000',
    marginBottom: '8px',
})
const CrossButton = styled(Button)({
    background: '#EBEBEB',
    padding: '11px',
    borderRadius: '6px',
    minWidth: '40px'
})
const PointsBox = styled(Box)({
    padding: '13px',
    width: '100%',
})
const TextContainer = styled(Box)({
    margin: '24px 0 20px 0'
})
const RankNewTypography = styled(Typography)({
    color: 'rgba(28, 160, 255, 1)',
    fontSize: '14px',
    fontWeight: 700
})
const NameNewTypography = styled(Typography)({
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '16px',
    fontWeight: 700
})
const PositionNewTypography = styled(Typography)({
    color: 'rgba(120, 120, 120, 1)',
    fontSize: '14px'
})
const ScoreBoardDeatilsBox = styled(Box)({
    width: '25%',
    display: "flex",
    justifyContent: 'center'
})
const ReactangeBoxDetailsFirst = styled(Box)({
    position: 'absolute',
    top: '-45px'
})
const ReactangeBoxDetailsTwo = styled(Box)({
    position: 'absolute',
    top: '-110px'
})
const ReactangeBoxDetailsThree = styled(Box)({
    position: 'absolute',
    top: '-43px'
})
const DepartmentContainer = styled(Box)({
    paddingTop: '25px',
    paddingLeft: '37px',
    width: '20%',
    fontSize: '16px',
    color: "rgba(0, 0, 0, 1)"
})
const DecriptionSecondTypography = styled(Typography)({
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 400,
    color: '#000000',
    paddingRight: '5px'
})
const StyledGridItemBox = styled(Grid)({
    maxWidth: '395px',
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
})
const InfoTypography = styled(Typography)({
    fontFamily: 'Open Sans',
    fontSize: '20px',
    fontWeight: 700,
    color: '#000000'
})
const ModalBox = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    backgroundColor: 'white',
    padding: '38px',
    borderRadius: '8px',
})
const EventTypeTypography = styled(Typography)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '6px'
})
const StartDateTypography = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '6px'
})
const DescriptionNewTypography = styled(Typography)({
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 400,
    color: '#000000',
    paddingRight: '5px'
})
const LeaderboardTypography = styled(Typography)({
    color: '#000000',
    fontWeight: 400,
    marginTop: '29px',
    fontSize: '21px',
})
const ScoreboardTypography = styled(Typography)({
    color: '#000000',
    fontWeight: 400,
    marginTop: '18px',
    fontSize: '21px',
})
const InfoboardTypography = styled(Typography)({
    color: '#000000',
    fontWeight: 400,
    marginTop: '28px',
    fontSize: '21px',
})
const EndDateTypography = styled(Typography)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '6px'
})
const ItemContainer = styled(Box)({
    display: 'flex',
    marginTop: '25px',
    columnGap: '10px'
})
const NameContainer = styled(Box)({
    width: '33.33%',
    display: 'flex',
    alignItems: 'center'
})
const ParentContainer = styled(Box)({
    width: "100%", display: 'flex'
})
const StaticContainer = styled(Box)({
    display: "flex",
    marginTop: '40px',
    marginLeft: '33px',
    gap: '45px'
})
const LeaderboardBox = styled(Box)({
    height: '100vh'
})
const LeaderboardSubBox = styled(Box)({
    display: 'flex',
    justifyContent: "center"
})
const DesignerBox = styled(Box)({
    width: "300px",
    display: 'flex',
    justifyContent: 'flex-start'
})
const StyledImage=styled('img')({
     position: 'relative',
      left: '19px',
       top: '5px' 
})
// Customizable Area End
