Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings";
exports.labelBodyText = "Settings Body";
exports.resetNewPasswordEndPoint = "bx_block_forgot_password/passwords";
exports.privacyPolicyEndPoint = "bx_block_content_management/privacy_policies";
exports.termsOfUseEndPoint = "bx_block_content_management/terms_and_conditions";
exports.changePasswordEndPoint = "account_block/accounts/change_password";
exports.emailToggleEndPoint = "account_block/accounts/update_email_notifications";
exports.getAdminProfileEndPoint="bx_block_customisableuserprofiles/admin_profile/admin_profile_detail";
exports.deleteAccountEndPoint="account_block/delete_account";
exports.btnExampleTitle = "CLICK ME";
exports.httpPostMethod =  "POST";
exports.httpGetMethod =  "GET";
exports.httpPutMethod =  "PUT";
exports.httpDeleteMethod =  "DELETE";
exports.httpPatchMethod= "PATCH";
// Customizable Area End