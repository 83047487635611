import React from "react";
// Customizable Area Start
import {
  Button,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Divider,
  Box,
  CircularProgress
} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// Customizable Area End
import AddDkrController, { Props } from "./AddDkrController";
export const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",
    }
  },
  typography: {
    subtitle1: {
      margin: "20px 0px"
    },
    h6: {
      fontWeight: 500
    },
  }
});
export default class TimingView extends AddDkrController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {currentStatus} = this.state
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      {this.state.circularLoading ? (
              <CircularProgressBox>
                <StyledCircularProgress />
              </CircularProgressBox>
            ):(
              <Box>
        <Box style={webStyle.Timeviewmaincontainer}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid className="first" style={webStyle.TimeviewleftSide} item>
              <Grid container spacing={2} alignItems="center">
                <Grid item style={webStyle.TimeviewrowarrowDisplay}>
                  <ArrowBackIosIcon style={{ fontSize: "24px", color: "black" }} data-test-id="nextButton1" onClick={this.navigateToEditTemplate2} />
                  <span
                    style={{ ...webStyle.TimeviewHeadStyleTop1, fontWeight: 400 }}>
                    Edit Template
                  </span>
                </Grid>
                <Grid item style={webStyle.TimeviewrowarrowDisplay}>
                  <ArrowBackIosIcon style={{ ...webStyle.TimeviewHeadStyleTop1, color: "#B2B2B2", fontSize: "16px" }} />
                  <span style={{ ...webStyle.TimeviewlabelStyleoptionhead as React.CSSProperties, fontWeight: 700, fontSize: "18px" }}>
                    View KPI Details
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="second" style={webStyle.TimeviewnullContainer} item />
          </Grid>
          <Grid
            container
            alignItems="center"
            style={webStyle.TimeviewinformContainer as React.CSSProperties}>
            <Grid
              className="first"
              item
              style={webStyle.TimeviewinformSecondContainerHead}
              xs={12}>
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.TimeviewheaderMain10}>
                    <span style={webStyle.TimeviewHeadtopStyle22 as React.CSSProperties}>KPI Details</span>
                  </FormLabel>

                </FormControl>
              </div>
              <div className="form-field text-center" id="signupBlock4">
                <div className="control" id="signupfieldcontrol2" style={{display: currentStatus === "Ready"? "none" : "block"}}>
                  <Button style={webStyle.Editbutton as React.CSSProperties} data-test-id="EditButton" onClick={this.navigateToEditKpi}>
                    Edit KPI Details
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.TimeviewinformationSubject}
              xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.TimeviewheaderMain10}>
                  <span style={{ ...webStyle.TimeviewlabelStyleoptionhead as React.CSSProperties, fontSize: "22px", fontWeight: 500 }}>Type</span>
                </FormLabel>
                <span style={{ ...webStyle.TimeviewblurvalueStyle as React.CSSProperties, fontWeight: 400 }}>Timing</span>
              </FormControl>
            </Grid>
            <Grid
              className="second"
              item
              style={webStyle.TimeviewinformationSubject}
              xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={{ ...webStyle.TimeviewheaderMain10, marginTop: "35px" }}>
                  <span style={{ ...webStyle.TimeviewlabelStyleoptionhead as React.CSSProperties, fontWeight: 500, fontSize: "22px" }}>Basic Details</span>
                </FormLabel>
                {Array.isArray(this.state.showTemplateData) && (
          this.state.showTemplateData.map((template:any, index:any) => (
                <Grid container style={{ display: "flex" }} xs={12}>
                  <Grid item xs={3}>
                    <div className="form-block" id="signupBlock3">
                      <form>
                        <div
                          className="form-field"
                          id="signupform"
                          style={webStyle.TimeviewtopdescThirty}>
                          <span style={webStyle.TimeviewTopstyleHeader as React.CSSProperties}>Title</span>
                          <div
                            className="control"
                            id="signupfieldcontrol"
                            style={webStyle.TimeviewmarginLeft0}>
                            <span style={webStyle.TimeviewblurvalueStyle as React.CSSProperties}>{template.attributes.name}</span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Grid>
                  <>
                    <Grid item xs={6}>
                      <div className="form-block" id="signupBlock3">
                        <form>
                          <div
                            className="form-field"
                            id="signupform"
                            style={webStyle.TimeviewtopdescThirty}
                          >
                            <span style={webStyle.TimeviewTopstyleHeader as React.CSSProperties}>Description</span>
                            <div
                              className="control"
                              id="signupfieldcontrol"
                          //istanbul ignore next
       style={webStyle.TimeviewmarginLeft0}
                            >
                              <span style={{ ...webStyle.TimeviewblurvalueStyle as React.CSSProperties, fontWeight: 400 }}>{template.attributes.description}</span>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="form-block" id="signupBlock3">
                        <form>
                          <div
                            className="form-field"
                            id="signupform"
                            style={{ ...webStyle.TimeviewtopdescThirty, width: "max-content" }}
                          >
                            <span style={webStyle.TimeviewTopstyleHeader as React.CSSProperties}>Points (Weightage)</span>
                            <div
                              className="control"
                              id="signupfieldcontrol"
                              style={webStyle.TimeviewmarginLeft0}
                            >
                              <span style={{ ...webStyle.TimeviewblurvalueStyle as React.CSSProperties, fontWeight: 400 }}>{template.attributes.weightage} points</span>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Grid>
                  </>
                </Grid>
          )))}
              </FormControl>
            </Grid >



          </Grid >
          <Divider light style={{ marginTop: "20px", marginBottom: "20px" }} />
          <Grid
            container
            alignItems="center"
            style={webStyle.TimeviewinformContainer as React.CSSProperties}
          >
            <Grid
              className="first"
              item
              style={webStyle.TimeviewinformSecondContainerHead}
              xs={12}
            >
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.TimeviewheaderMain10}>
                    <span style={webStyle.TimeviewHeadtopStyle22 as React.CSSProperties}>Daily KPI Report</span>
                  </FormLabel>

                </FormControl>
              </div>
              <div className="form-field text-center" id="signupBlock4">
                <div className="control" id="signupfieldcontrol2" style={{display: currentStatus === "Ready"? "none" : "block"}}>
                <Button style={webStyle.Editbutton as React.CSSProperties} data-test-id="nextButton">
                Edit DKR Details
            </Button>
                </div>
              </div>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.TimeviewinformationSubContainerTable}
              xs={12}  >
              <>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.Timeviewheadermandatory}>
                    <span style={{ ...webStyle.labelStyle10 as React.CSSProperties, fontWeight: 500 }}>Applicable Occurrence</span>
                  </FormLabel>
                </FormControl>
                <TableContainer style={webStyle.TimeviewtablemainContainer}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow style={webStyle.TimeviewmaintableHead}>
                        <TableCell style={webStyle.TimingTableCell}>Occurrence Title</TableCell>
                        <TableCell style={webStyle.TimingTableCell}>
                          Deductible Value
                        </TableCell>
                        <TableCell style={{ width: "18%" }}>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                
                    {Array.isArray(this.state.showTemplateData) &&
  this.state.showTemplateData.map((template: any, index: any) => (
    <TableBody key={index}>
      {template.attributes.dkr_information.data.attributes.applicable_occurrences.data.map(
        (occurrence: any) => (
          
          <TableRow>
            <TableCell
              component="th"
              scope="row"
              style={webStyle.TimeTableCell2} >
              {occurrence.attributes.title}
            </TableCell>
            <TableCell>
              <Box className="form-block" id="signupBlock3">
                <form>
                  <Box
                    className="form-field"
                    id="signupform" >
                    <Box
                      className="control"
                      id="signupfieldcontrol"
                      style={webStyle.TimeviewmarginLeft0} >
                      <span style={ webStyle.mainvalueStyle as React.CSSProperties}>
                        {occurrence.attributes.deductible_value} Points
                      </span>
                    </Box> </Box>
                </form> </Box> </TableCell>
            <TableCell></TableCell>
          </TableRow>
        )
      )}
    </TableBody>
  ))}
                  </Table>
                </TableContainer>

              </>
            </Grid>
          </Grid >
        </Box >
          </Box>)}
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  TimeTableCell2:{
    fontFamily: "Open Sans", fontSize: "14px", color: "black", paddingLeft: "65px",
  },
  TimingTableCell:{ fontFamily: "Open Sans", fontSize: "14px",
    color: "black", fontWeight: 600, 
    paddingLeft: "70px", width: "33%" 
    },
    mainvalueStyle: {
        color: "black",
        marginTop: "10px",
        fontSize: "16px",
        fontFamily:"Open Sans",
        fontWeight: 400,
        },
  inputmainTable: {
    background: 'transparent',
    border: '0.5px solid #979797',
    width: "70%",
  },
  Editbutton: {
    backgroundColor: "FDD100",
    color: "black",
    fontFamily: 'Open Sans',
    padding: "15px 20px 15px 20px",
    width: "200px",
    fontSize: "16px",
    borderRadius: "10px",
    fontWeight: 600,
    textTransform: "none"
  },


  TimeviewHeadStyleTop1: {
    color: "black",
    fontFamily: "Open Sans",
    fontSize: "18px",
  },
  TimeviewHeadtopStyle22: {
    color: "black",
    fontWeight: 700,
    fontSize: "24px",
    fontFamily: "Open Sans"
  },

  TimeviewmarginLeft0: {
    marginLeft: 0,
    marginTop: '5px'
  },

  TimeviewbuttonmainContainer: { display: "flex", gap: "20px", margin: "0 40px" },
  TimeviewCancelBtn: {
    fontWeight: 600,
    fontFamily: 'Open Sans',
    textTransform: "none",
    backgroundColor: "white",
    fontSize: "16px",
    width: "170px",
    color: "black",
  },

  TimeviewlabelStyleoptionhead: {
    color: "black",
    fontWeight: 500,
    fontSize: "22px",
    fontFamily: "Open Sans"
  },
  TimeviewTopstyleHeader: {
    fontSize: "18px",
    color: "#c8ccc9",
    fontWeight: 700,
    fontFamily: "Open Sans"
  },
  TimeviewblurvalueStyle: {
    color: "black",
    marginTop: "20px",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: 400
  },
  TimeviewrowarrowDisplay: {
    alignItems: "center",
    display: "flex",
  },
  TimeviewheaderMain10: {
    display: "flex",
    alignItems: "baseline",
    gap: "4px",
    MarginTop: "30px"
  },
  TimeviewSubmitBtn: {
    fontFamily: 'Open Sans',
    marginRight: "20px",
    color: "black",
    fontSize: "16px",
    width: "170px",
    backgroundColor: "FDD100",
    fontWeight: 600,
    textTransform: "none",

  },


  informatSubContainerTable: { marginTop: "24px", width: "98%" },
  TimeviewinformSecondContainerHead: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "24px",
    alignItems: "initial",
    width: "98%"
  },
  TimeviewtopdescThirty: {
    marginTop: "20px",
  },
  container: {
    height: "100%",
    width: "100%",
  },


  TableC: {
    fontSize: "14px",
    color: "black",
    fontWeight: 600,
    fontFamily: "Open Sans",
  },

  Timeviewmaincontainer: {
    background: "#F3F4F5",
    height: "64px",
    marginRight: "-20px"
  },
  TimeviewinformContainer: {
    padding: "0 32px",
    flexDirection: "column",
    alignItems: "baseline",
  },
  TimeviewinformationSubject: {
    marginTop: "24px",
    width: "800px",
    marginBottom: "30px"
  },
  TimeviewleftSide: {
    padding: "19px 0px 21px 38px"
  },
  TimeviewnullContainer: {
    padding: "0px 44px 0px 0px"
  },
  TimeviewmaintableHead: {
    backgroundColor: "#F5F5F5",
  },
  labelStyle10: {
    fontSize: "22px",
    fontFamily: "Open Sans",
    fontWeight: 500,
    color: "black",
  },
  Timeviewheadermandatory: {
    display: "flex",
    MarginTop: "30px",
    alignItems: "baseline",
    gap: "4px",
  },
  TimeviewtablemainContainer: {
    marginTop: "15px",
    borderRadius: "10px",
    border: "1px solid #979797",
    marginBottom: "10%"
  },
  TimeviewinformationSubContainerTable: {
    marginTop: "24px",
    width: "98%",
  },
};
const CircularProgressBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '400px'
})
const StyledCircularProgress = styled(CircularProgress)({
  color: '#FDD001'
})
// Customizable Area End
