import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { number } from "yup";
import { getStorageData } from "../../../framework/src/Utilities";
import toast from "react-hot-toast";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  kpiName:string,
  kpiDescription:string,
  weightage:string,
  goalCalculation:string,
  isduplicateOpen:boolean,
  anchorEl7:any,
  templateAdd:any,
  SelectedtemplateId:any,
  newtemplateAdd:any,
  templateKpiDetails:any,
  groupTemplates:any,
  templateState: string
  circularLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
 
  // Customizable Area End
}

export default class EditTemplate2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getIdForTemplateAdded: string = "";
  saveEditTemplateId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      kpiName:'',
      kpiDescription:'',
      weightage:'',
      goalCalculation:'',
      isduplicateOpen:false,
      anchorEl7:null,
      templateAdd:[],
      newtemplateAdd:[],
      templateKpiDetails: {
        name: "",
        description: "",
        totalWheightage:"",
      },
      groupTemplates:[],
      templateState: "",
      circularLoading: false,
      SelectedtemplateId:number

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
  
    this.getTemplateAddedResponse()

    // Customizable Area End
  }



    // Customizable Area Start
    async receive(from: string, message: Message) {
      // Customizable Area Start
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
         if(apiRequestCallId === this.getIdForTemplateAdded) {
            this.getTemplateAddedDetail(responseJson)
        }
        else if (apiRequestCallId===this.saveEditTemplateId){
          this.getEditTemplateResponse(responseJson)
        }
      }
      // Customizable Area End
    }
    // Customizable Area End
  
    handleSelecttemplate = (templateId: number | null) => {
    
      // Use setState's callback to ensure state is updated
      this.setState(
        { SelectedtemplateId: templateId },
        () => {
          // Callback function ensures you access the updated state
        }
      );
    };
  
      navigateToTimingDKR = () => {
        this.props.navigation.navigate("TimingDkr")
      }
      navigateToTimingView =(id: string) => {        
        this.props.navigation.navigate("TimingView")
      }
     
      navigateToSignUp=()=>{
        this.props.navigation.navigate("EditKpi")
      }
      navigateToTemplateAdded = async ()=>{
        const navigateToUserProfile = new Message(getName(MessageEnum.NavigationMessage));
        navigateToUserProfile.addData(getName(MessageEnum.NavigationTargetMessage), "TemplateAdded");
        navigateToUserProfile.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateToUserProfile);
      }
     
      toggleModal = () => {
        this.setState((prevState) => ({ isduplicateOpen: !prevState.isduplicateOpen }));
      };
      handleClose2 = () => {
        this.setState({isduplicateOpen:false,});
      };
      handleClick7 = (event:any, id:any) => {
        localStorage.setItem("TemplateNavigationId", id)
        this.setState({ anchorEl7: event.currentTarget });
      };
      handleClickClose7 = () => {
        this.setState({ anchorEl7: null });
      };
      getTemplateAddedDetail =async (responseJson: any) => {         
        if (responseJson) {
          this.setState({circularLoading: false})
          this.setState({
            templateKpiDetails: {
              ...this.state.templateKpiDetails,
              name: responseJson.template.name,
              description: responseJson.template.description,
              totalWheightage: responseJson.template.total_weightage,
             
            },
          });
          this.setState({groupTemplates:responseJson.grouped_templates, templateState:responseJson.template.status})
          console.log(this.state.groupTemplates,'Hiiii')
        } 
      }
      getEditTemplateResponse=(responseJson: any)=>{
        if (responseJson.data) {
          this.setState({circularLoading: false})
          this.props.navigation.navigate("Template")
        }
        else{
          this.setState({circularLoading: false})
          toast.error(responseJson.error)
         }
      }
      
      getTemplateAddedResponse =async () => {
        this.setState({circularLoading: true})
         const templateId= await getStorageData("templateId")
         const status = await getStorageData("currentStatus")
        const fetchTemplateAddedHeader = {
          "Content-Type": configJSON.dashboarContentType,
          token:localStorage.getItem("authToken")
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getIdForTemplateAdded = requestMessage.messageId;
        const endpoint = status === "approved"? `bx_block_gamification/kpi_listing?template_type=templates&template_id=${templateId}` : `bx_block_gamification/kpi_listing?template_type=organization_templates&template_id=${templateId}`;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endpoint 
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(fetchTemplateAddedHeader)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
      handleNameChange = (event:any) => {
        const value = event.target.value;
        this.setState((prevState) => ({
          templateKpiDetails: {
            ...prevState.templateKpiDetails,
            name: value,
          },
        }));
      };
    
      handleDescriptionChange = (event:any) => {
         const value = event.target.value;
        this.setState((prevState) => ({
          templateKpiDetails: {
            ...prevState.templateKpiDetails,
            description: value,
          },
        }));
      };
      submitEditData=()=>{
        this.saveEditTemplateApi()
      }
      handleBackTagTemplate=(path:any)=>{
        const toNavigate: Message = new Message(
          getName(MessageEnum.NavigationMessage)
        );
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toNavigate);
      }
      saveEditTemplateApi = async () => {
        this.setState({circularLoading: true})
        const token = await getStorageData('authToken'); 
        const templateId = await getStorageData('templateId'); 
        
        const fetchTemplateAddedReponseHeader = {
          token: token, 
        };
      
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.saveEditTemplateId = requestMessage.messageId;
      
        const endpoint = `bx_block_gamification/organization_templates/${templateId}`;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endpoint
        );
      
        const formData = new FormData();
        formData.append("organization_template[name]", this.state.templateKpiDetails.name);
        formData.append("organization_template[description]", this.state.templateKpiDetails.description);
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(fetchTemplateAddedReponseHeader)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData 
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "PUT"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
      
  // Customizable Area Start
  getTemplateLinkProps = (template:any) => {
    const isAdded = template.status === "added";
    
    return {
      buttonText: isAdded ? "View Template" : "Edit Template",
      linkStyle: { display: isAdded ? "none" : "block" }
    };
  }
  addNewkpi = async() => {
    window.location.href = "/Addkpi";
  }
  // Customizable Area End
}
