import React from "react";
// Customizable Area Start
import "./dashboard.css";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import "react-circular-progressbar/dist/styles.css";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import { Button, Modal, styled } from '@material-ui/core';
import { LineChart, Line, XAxis, YAxis, Tooltip, LabelList, ReferenceLine } from "recharts";
import { AntSwitch } from "./Users.web";
import { webStyle } from "./EditTemplate.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BulkUpload, singleupload, bulkupload } from "./assets";
// Customizable Area End
import DashboardController, { Props } from "./DashboardController";
import AddIcon from '@material-ui/icons/Add';


export default class Dashboard extends DashboardController {

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  userBulkUploadModal() {
    return (
      <div>

        <Modal
          style={{ position: "relative" }}
          open={this.state.isOpneUserBulkUpload}
        >
          <>
            <ModalBoxSucces>
              {this.state.bulkUploadProgress &&
                <StyledCircularProgress
                  
                />}
              <ModalContentBox >
                <HeadingTypographySucces>User Bulk Upload</HeadingTypographySucces>
                <UploadBox >
                  <DownloadFile>Download and fill <BulkUploadBox  data-test-id="handleDownloadCsvFile" onClick={this.handleDownloadCsvFile} >User bulk upload.csv</BulkUploadBox></DownloadFile>
                  <DropZone
                    data-test-id="dropZone"
                    onDrop={this.handleDrop}
                    onDragOver={this.handleDragOver}

                  >
                    <DropZoneChid>
                      <BrowseButton htmlFor="fileInput">
                        <NewStyledImg src={BulkUpload} alt="addIcon"  />
                        <input
                          type="file"
                          accept=".csv"
                          multiple
                          style={{ display: "none" }}
                          data-test-id="handleFileSelect1"
                          onChange={this.handleFileSelect}
                          id="fileInput"
                        />
                      </BrowseButton>
                    </DropZoneChid>
                  </DropZone>
                  <DragAndDrop>{this.state.bulkuploadFile ? this.state.bulkuploadFile : "Drag & Drop .csv file here to upload bulk user entries"}</DragAndDrop>
                </UploadBox>
                <ShowError>{this.state.showUploadError}</ShowError>
                <SubmitBoxContainer >
                  <SubmitBox
                    disabled={this.state.bulkUploadProgress}
                    data-test-id="handleCloseSubmitId1"
                    onClick={this.handleUploadCsv}
                    style={{ cursor: 'pointer', fontSize: '16px', fontWeight: 600, color: "#000000", textTransform: 'capitalize', background: this.state.bulkUploadProgress ? '#e3d7a6' : ' #FFCC00' }}>
                    Save
                  </SubmitBox>
                  <SubmitBox
                    data-test-id="handleCloseSubmitId"
                    onClick={this.handleCancelCsv}
                    style={{ cursor: 'pointer !important', fontSize: '16px', textTransform: 'capitalize', fontWeight: 600, color: "#000000", background: 'white', border: '1px solid #979797' }}>
                    Cancel
                  </SubmitBox>
                </SubmitBoxContainer>
              </ModalContentBox>
            </ModalBoxSucces>
          </>
        </Modal>
      </div>
    )
  }
  circularProgress(item: any) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress
          data-test-id="progress"
          variant="determinate"
          value={Number(item.kpi_progress)}
          style={this.getCircularProgressStyle(item)}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            data-test-id="typography"
            variant="caption"
            component="div"
            color="textSecondary"
          >
            {item.kpi_progress + "%"}
          </Typography>
        </Box>
      </Box>
    )
  }
  renderTableRows(data: any) {
    if (data.length > 0) {
      return data.map((item: any, key: number) => (
        <tr data-test-id="rows" key={key} className="tabel-rows">
          <td data-id="table-id" className={this.getClassName(item.activate_deactivate)}>
            {item.employee_id ? item.employee_id : "-"}
          </td>
          <td data-test-id="attr-name-id" className={this.getClassName(item.activate_deactivate)}>
            <span className="attr-name">
              {item.name ? item.name : "-"}
            </span>
          </td>
          <td className={this.getClassName(item.activate_deactivate)}>
            {item.department ? item.department : "-"}
          </td>
          <td className={this.getClassName(item.activate_deactivate)}>
            {item.kpi_progress === "NA" ? "N/A" : this.circularProgress(item)}
          </td>
          <td id="fake" className={this.getClassName(item.activate_deactivate)}>
            <AntSwitch
              data-testid="handleSelectionSwitch"
              checked={item.activate_deactivate}
              onClick={() => {
                this.handleSwitchChange(item.id, item.name, item.activate_deactivate);
                this.handleOpenKpiModal();
              }}
              name="checked"
              id={`switch-${item.id}`}
              style={{ cursor: 'pointer' }}
            />
          </td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan={5} style={{ textAlign: "center" }}>
            No records
          </td>
        </tr>
      );
    }
  }
  // Customizable Area End

  render() {
    const medianValue1 = this.calculateMedian(this.state.dataSource);
    const medianValue2 = this.calculateMedian(this.state.dataSource2);
    const { userTemplateData } = this.state
    return (
      // Customizable Area Start
      <div className="content-wrapper">
        <div className="dashboard-info">
          <div className="welcome-user">
            <h2>Hi, {this.state.editAdminFormData.attributes?.first_name} {this.state.editAdminFormData.attributes?.last_name}</h2>
            <div className="row">
              <div className="col">
                <div className="inner-col" style={webStyle2.Div1}>
                  <h2 style={webStyle2.Heading2}>{userTemplateData.active_templates}</h2>
                  <p style={webStyle2.Paragraph}>Active Templates</p>
                </div>
              </div>
              <div className="col">
                <div className="inner-col" style={webStyle2.Div2}>
                  <h2 style={webStyle2.Heading2}>{userTemplateData.active_users}</h2>
                  <p style={webStyle2.Paragraph}>Active Users</p>
                </div>
              </div>
              <div className="col">
                <div className="inner-col" style={webStyle2.Div3}>
                  <h2 style={webStyle2.Heading2}>{userTemplateData.templates_assigned}</h2>
                  <p style={webStyle2.Paragraph}>Templates Assigned</p>
                </div>
              </div>
            </div>
          </div>
          <div className="upload-user-data" ref={this.datePickerRef}>
            <button style={webStyle2.Button} className="button" onClick={this.handleUploadClick}>
              <AddIcon style={webStyle2.Icon} /> Upload User Data

            </button>
            {this.state.flagButton && (
              <ParentBox >
                <Card
                  variant="outlined"
                  style={webStyle2.Card1 as React.CSSProperties}
                >
                  <Button
                    data-test-id="singleuserupload"
                    onClick={() => this.props.navigation.navigate("SingleUserUpload")}
                    style={webStyle2.Button4 as React.CSSProperties}
                  >
                    <StyledImage src={bulkupload} alt=""  />
                    <Typography style={webStyle2.label1 as React.CSSProperties}>Single User Upload</Typography>
                  </Button>
                  <Button
                    data-test-id="handleUploadBulk"
                    onClick={this.handleUploadBulk}
                    style={webStyle2.Button3 as React.CSSProperties}
                  >
                    <StyledNewImage  src={singleupload} alt=""  />
                    <Typography style={webStyle2.label2 as React.CSSProperties}>Bulk Upload</Typography>
                  </Button>
                </Card>
              </ParentBox>
            )
            }
          </div>
        </div>
        <div className="dashboard-chart-block">
          <div className="graph-row">
            <div className="col">
              <div className="dashboard-chart">
                <ReactApexChart
                  options={this.state.options}
                  series={this.state.series}
                  type="bar"
                  height={350}
                  width={500}

                />
              </div>
            </div>
            <div className="col">
              <div className="dashboard-chart">
                <LineChart
                  layout="horizontal"
                  width={500}
                  height={360}
                  data={this.state.dataSource}
                  margin={{
                    top: 60,
                    right: 30,
                    left: 20,
                    bottom: 10
                  }}
                >
                  <XAxis dataKey="name" type="category" />
                  <YAxis type="number" />
                  <Tooltip />
                  <text
                    x={30}
                    y={30}
                    textAnchor="right"
                    fontWeight="bold"
                    style={webStyle2.text as React.CSSProperties}
                    fill="#a0a0a0"
                  >
                    Performance (Last Quarter)
                  </text>
                  <Line dataKey="pv" stroke="#FCD61D" dot={{ fill: "#486484" }}>
                    <LabelList dataKey="pv" position="top" />
                  </Line>

                  <ReferenceLine
                    y={medianValue1}
                    stroke="#E4E4E4"
                    strokeDasharray="7 6"
                    width={249.9}
                    style={webStyle2.Ref as React.CSSProperties}
                  />

                </LineChart>
              </div>
            </div>
            <div className="col">
              <div className="dashboard-chart">
                <LineChart
                  layout="horizontal"
                  width={500}
                  height={360}
                  data={this.state.dataSource2}
                  margin={{
                    top: 60,
                    right: 30,
                    left: 20,
                    bottom: 10
                  }}
                >
                  <XAxis dataKey="name" type="category" />
                  <YAxis type="number" />
                  <Tooltip />
                  <text
                    x={30}
                    y={30}
                    textAnchor="right"
                    fontWeight="bold"
                    fill="#a0a0a0"
                    style={webStyle2.text as React.CSSProperties}
                  >
                    User Growth (Last Quarter)
                  </text>

                  <Line dataKey="pv" stroke="#486484" dot={{ fill: "#9BBE6A" }}>
                    <LabelList dataKey="pv" position="top" />
                  </Line>


                  <ReferenceLine
                    y={medianValue2}
                    stroke="#E4E4E4"
                    strokeDasharray="7 6"
                    width={249.9}
                    style={webStyle2.Ref as React.CSSProperties}
                  />

                </LineChart>

              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-table">
          <div className="table-header">
            <h4>Employee Details</h4>
            {this.state.viewAllUserData?.length > 0 && <Link to="/Users">View All</Link>}
          </div>
          <div className="responsive-table">
            <table className="table" cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                  <th>Employee ID</th>
                  <th>Employee Name</th>
                  <th>Department</th>
                  <th>KPI Progress</th>
                  <th>Enable/Disable</th>


                </tr>
              </thead>
              <tbody className="tbody">
                {this.renderTableRows(this.state.viewAllUserData)}
              </tbody>

            </table>
          </div>
        </div>
        <Modal
          style={webStyle2.modal}
          open={this.state.disableKpiModel}
          onClose={this.handleOpenKpiModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <>
            <div style={webStyle2.kpiModel2 as React.CSSProperties}>

              <Typography style={webStyle2.Typography}>Are you sure you want to {this.state.activateDeactivate ? "disable " : "enable "}the account of {this.state.accountsName} ? </Typography>
              <div style={webStyle.KpiModelfooterStyle}>
                <Button data-test-id="saveokay" onClick={this.confirmDisableAccount} style={webStyle2.buttonsubmit2 as React.CSSProperties}
                >Yes</Button>
                <div style={webStyle.buttoncancel}>
                  <Button

                    onClick={this.handleOpenKpiModal}
                    variant="outlined"
                    style={webStyle2.Button2 as React.CSSProperties}
                  >
                    Cancel
                  </Button>
                </div>
              </div>

            </div>
          </>
        </Modal>
        {this.userBulkUploadModal()}
      </div>
      // Customizable Area End  
    );
  }
}
// Customizable Area Start
const webStyle2 = {
  Heading2: {
    fontSize: "38px", color: "white"
  },
  Paragraph: {
    fontSize: "16px", color: "white"
  },
  Button: {
    cursor: "pointer", fontSize: "16px", borderRadius: "15px", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 700
  },
  Div1: {
    background: " #6191C7"
  },
  Div2: {
    background: "#486484"
  },
  Div3: {
    background: "#4E53A6"
  },
  Icon: {
    fontSize: "30px", fontWeight: 700
  },
  modal: {

    display: "flex",
    justifyContent: "center",
    alignItems: "center"

  },
  Typography: {

    color: "#000",
    fontSize: "18px",
    fontStyle: "normal", fontWeight: 700, lineHeight: "normal", padding: "5px 0px 30px 25px"

  },
  Button2: {

    backgroundColor: 'white',
    color: 'black',
    width: '80px',
    fontSize: '12px',
    fontWeight: 600,
    textTransform: 'none',
    fontFamily: "Open Sans"

  },
  Button3: {
    textAlign: "left", textTransform: "none",
    display: 'flex', alignItems: "center",
    cursor: "pointer"
  },
  Card1: {

    width: 202,
    marginTop: "10px",
    border: "none", // Remove the black border
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Add a shadow
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "12px 0px 13px 18px",
    height: '75px',
    borderRadius: '8px',

  },
  Button4: {
    textAlign: "left", textTransform: "none",
    display: 'flex', alignItems: "center",
    cursor: "pointer"
  },
  Person: {
    fontSize: 14, marginRight: 1, width: 16, height: 16
  },
  text: {
    fontSize: '20px', fontWeight: 'bold'
  }
  ,
  label1: {
    fontSize: 12,
    color: "#406587",
    fontWeight: 600,
    marginLeft: "13px",
    cursor: "pointer"
  },
  label2: {
    fontSize: 12,
    color: "#406587",
    fontWeight: 600,
    marginLeft: "8px",
    cursor: "pointer"
  },
  input: {
    display: 'none'
  },
  Ref: {
    strokeWidth: "3px"
  },
  kpiModel2: {
    borderRadius: '8px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '420px',
    backgroundColor: 'white',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
    padding: '20px 5px 20px 5px'
  },
  buttonsubmit2: {
    backgroundColor: '#FDD100',
    color: 'black',
    width: '80px',
    fontSize: '12px',
    fontWeight: 600,
    textTransform: 'none',
    fontFamily: "Open Sans"
  }

}
const ModalBoxSucces = styled(Box)({
  position: 'fixed',
  padding: '60px 48px 40px 48px',
  maxWidth: '723px',
  width: '100%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: '8px',

});
const ModalContentBox = styled(Box)({

})
const HeadingTypographySucces = styled(Typography)({
  fontSize: "24px",
  fontWeight: 700,
  lineHeight: '24px',
  color: '#000000',
  marginBottom: '50px',
})
const UploadBox = styled(Box)({
  width: '100%',
  maxWidth: "627px",
  borderRadius: "20px",
  border: "1px solid #DADADA",
  opacity: '0px',
  padding: "30px 0px",
  cursor: 'pointer'

})
const DropZone = styled("div")({
  width: "100%",
  margin: "20px 0px",
  borderRadius: 16,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const DropZoneChid = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 16,
});
const BrowseButton = styled("label")({
  display: "flex",
  gap: 8,
  alignItems: "center",
  cursor: "pointer",
});
const SubmitBoxContainer = styled(Box)({
  display: 'flex',
  width: '100%', paddingRight: '51px',
  gap: 14
})
const SubmitBox = styled(Button)({
  maxWidth: '100px',
  width: '100%',
  padding: '10px',
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
  borderRadius: '8px',
  marginTop: '40px',
  '&:hover': {
    backgroundColor: '#e3d7a6',
    color: '#000000',
    cursor: 'pointer',
  },
})
const DownloadFile = styled(Typography)({
  fontSize: "16px",
  fontWeight: 500,
  color: "#3A3A3A",
  display: 'flex',
  justifyContent: "center"
})
const DragAndDrop = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  color: "#3A3A3A",
  display: 'flex',
  justifyContent: "center"
})

const ShowError = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  color: "red",
  marginTop: '4px'
})
const ParentBox = styled(Box)({
  minWidth: '40px'
})
const StyledImage=styled('img')({
 height: "14px",
  width: '11px',
   cursor: "pointer" 
})
const StyledCircularProgress=styled(CircularProgress)({
 position: "absolute",
  top: '200px',
   left: "338px",
    color: 'green' 
})
const BulkUploadBox=styled(Box)({
 color: "#6475D0",
  marginLeft: '5px',
   cursor: 'pointer',
    textDecoration: "underline"
})
const StyledNewImage=styled('img')({
height: "12px",
 width: '15px',
  cursor: "pointer" 
})
const NewStyledImg=styled('img')({
  height: '56px',
   width: '56px'
})
// Customizable Area End

