import React from "react";
// Customizable Area Start

import { createTheme, styled } from "@material-ui/core/styles";
import { Empty, active_filter, filtered_active } from "./assets";
import {
  Grid,
  Box,
  Button,
  Typography,
  Modal,
  MenuItem,
  CircularProgress
} from "@material-ui/core";
export const configJSON = require("./config");
// Customizable Area End
import DashboardController, { Props } from "./DashboardController";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


export const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  },
});


export default class Template extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  statusColor = (template: any) => {
    const status = template.attributes?.status?.toLowerCase();
    if (status === "added") return "#34B53A";
    if (status === "draft") return "#6D6D6D";
    return "red";
  };

  renderNoRecords = () => (
    <div className="no-records-alert">
      <img src={Empty} alt="logo" width={175} height={125} />
      <h3>No Records Found!</h3>
      <h5>There are no records to display right now.</h5>
    </div>
  );


  renderTemplates = (templates: any) => {
    return templates.map((template: any, index: number) => {
      const isCopyNull = template.attributes.is_copy === null;
      const isStatusExcluded =
        template.attributes?.status?.toLowerCase() === "added" ||
        template.attributes?.status?.toLowerCase() === "draft";

      const boxStyle = {
        ...webStyle.Grid1,
        marginBottom: "20px",
        ...(isCopyNull && !isStatusExcluded && { border: "1px solid #FF0000" }),
      };

      return (
        <StyledBox style={boxStyle} key={index}>
          <StyledDescriptionDiv>
            <Typography style={webStyle.Typo1} data-test-id="templateId">
              {template.attributes.name}
            </Typography>
            <Typography style={webStyle.Typo2}>
              {template.attributes.description}
            </Typography>
          </StyledDescriptionDiv>
          <Box style={{ maxWidth: 120 }}>
            <div
              className="result-action"
              style={webStyle.grid3 as React.CSSProperties}
            >
              <div style={{ textAlign: "center" }}>
                {template.attributes.is_copy === null && (
                  <>
                    <StatusTypography
                      style={{
                        color: this.statusColor(template)
                      }}
                    >
                      {template.attributes?.status?.charAt(0).toUpperCase() +
                        template?.attributes?.status?.slice(1)}
                    </StatusTypography>

                  </>
                )}
                {template.attributes.is_copy === null ? (
                  <StyledButton
                    style={{ ...webStyle.duplicateButton, marginBottom: '10px' }}
                    className="button"
                    onClick={() => this.navigateToEdit('TemplateAdded', template)}
                    data-testid="duplicateButton"
                  >
                    {this.getButtonText(template)}
                  </StyledButton>
                ) : (
                  <StyledButton
                    style={{ ...webStyle.duplicateButton, marginBottom: '10px' }}
                    className="button"
                    onClick={() => this.toggleModal(template.attributes.id)}
                    data-testid="duplicateButton"
                  >
                    Duplicate
                  </StyledButton>
                )}
                <StyledLink href="#" className="view-link"
                  onClick={() => this.navigateToEdit('TemplateAdded', template)}
                  style={{ display: template.attributes?.status?.toLowerCase() === "added" ? "none" : "block" }}>
                  View Details
                </StyledLink>
              </div>
            </div>
          </Box>
        </StyledBox>
      );
    });
  };




  renderTemplateContent() {
    
    const { viewAllTemplates, selectedDepartment, templateAdd } = this.state;
    switch (true) {
      case viewAllTemplates.length ===  0 && selectedDepartment === "All Departments":
        return this.renderNoRecords();
      
      case selectedDepartment === "All Departments":
        return Array.isArray(viewAllTemplates) ? this.renderTemplates(viewAllTemplates) : null;

      case Array.isArray(templateAdd) && templateAdd.length === 0:
        return this.renderNoRecords();

      default:
        return Array.isArray(templateAdd) ? this.renderTemplates(templateAdd) : null;
    }
  }

  render() {
    // Customizable Area Start


    const DuplicatCard = () => {
      return (
        <div>
          <Modal
            open={this.state.isduplicateOpen}
            aria-labelledby="email-verified-title"
            aria-describedby="email-verified-description">
            <Box
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '420px',
                backgroundColor: 'white',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                padding: '20px 5px 20px 5px'
              }}>
              <StyledNewModlBox>
                <NewContainer >
                  <Typography style={{ color: 'black', fontFamily: "Open Sans" }}>
                    Do you really want to duplicate <span style={{ fontWeight: 'bold', color: 'black' }}>'Developer</span>
                    <GameCardTypography >
                      Gamecard,
                    </GameCardTypography>
                  </Typography>
                </NewContainer>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    paddingLeft: '40px',
                    paddingRight: '40px',
                    marginTop: '25px'
                  }} >
                  <Button
                    style={{
                      backgroundColor: '#FDD100',
                      color: 'black',
                      marginRight: '10px',
                      width: '80px',
                      fontSize: '12px',
                      fontWeight: 600,
                      textTransform: 'none',
                      fontFamily: "Open Sans"
                    }}
                    data-testid="singleuser"
                    onClick={this.navigateToTemplateAdded}>
                    Yes
                  </Button>
                  <Button
                    variant="outlined"
                    style={{
                      backgroundColor: 'white',
                      color: 'black',
                      width: '80px',
                      fontSize: '12px',
                      fontWeight: 600,
                      textTransform: 'none',
                      fontFamily: "Open Sans"
                    }}
                    data-test-id="closeBtn"
                    onClick={this.handleClose2}>
                    No
                  </Button>
                </Box>
              </StyledNewModlBox>
            </Box>
          </Modal>
        </div>
      );
    };

    // istanbul ignore next
    return (
      <>
        <div className="content-wrapper" >
          <form>
            <StyledSubBox className="form-group" >
              <StyledLabel >Choose Department <span style={webStyle.mandatory}>*</span></StyledLabel>
              <div className="control"  ref={this.departmentRef}>
                <StyledModalContainer>
                  <Box
                    data-test-id="dropdown-trigger"
                    style={{
                      width: "250px",
                      backgroundColor: "#F6F6F9",
                      borderRadius: "12px",
                      padding: "15px 8px 15px 15px",
                      cursor: "pointer",
                      position: "relative",
                    }}
                    onClick={this.toggleDropdown}
                  >
                    <StyledSpan >
                      {this.state.selectedDepartment.length > 20
                        ? `${this.state.selectedDepartment.slice(0, 20)}...`
                        : this.state.selectedDepartment || "All Departments"}
                    </StyledSpan>
                    <StyledExpandMoreIcon />

                    {this.state.isOpen && (
                      <Box
                        data-test-id="dropdown-menu"
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: "0",
                          width: "210px",
                          maxHeight: "400px",
                          overflowY: "auto",
                          backgroundColor: "white",
                          borderRadius: "12px",
                          zIndex: 999,
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                          paddingBottom: "10px",
                          marginTop: "5px"
                        }}
                      >
                        <MenuItem
                          value="choose department"
                          data-test-id="menu-item-all-departments"
                          onClick={() => this.handleSelection("All Departments", null)}
                          onMouseEnter={() => this.handleMouseEnter2()}
                          onMouseLeave={this.handleMouseLeave}
                          style={{
                            fontSize: "16px", borderRadius: "8px", fontFamily: "Open Sans", fontWeight: 400, color: "black", marginLeft: "7px", marginRight: "7px", marginTop: "15px",
                            backgroundColor: this.state.hoveredIndex === true ? "#FDD100" : "white"
                          }}
                        >
                          All Departments
                        </MenuItem>
                        {Array.isArray(this.state?.departmentListData) &&
                          this.state.departmentListData.map((val, index) => (
                            <MenuItem
                              key={index}
                              value={val.id}
                              data-test-id="hello-single-user"
                              onClick={() => this.handleSelection(val.name, val.id)}
                              onMouseEnter={() => this.handleMouseEnter(index)}
                              onMouseLeave={this.handleMouseLeave}
                              style={{
                                fontSize: "16px", fontFamily: "Open Sans", fontWeight: 400, color: "black",marginLeft: "7px", marginRight: "7px",
                                maxWidth: "290px",
                                borderRadius: "8px",
                                whiteSpace: "normal",
                                overflowWrap: "break-word",
                                backgroundColor: this.state.hoveredIndex === index ? "#FDD100" : "white"
                              }}
                            >
                              {val.name}
                            </MenuItem>
                          ))}
                      </Box>
                    )}
                  </Box>
                </StyledModalContainer>
              </div>
            </StyledSubBox>
          </form>
          <div className="partner-heading">
            <form>
              <div style={webStyle.filterHeader}>
                <label style={{ fontSize: 18 }}>{this.state.templateStatusText}</label>
                <div className="control" style={{position:'relative'}}  ref={this.statusRef} >
                  <StyledFilterImg
                    onClick={this.toggleDropdownStatus}
                    style={{
                      backgroundImage: `url(${this.state.isTemplateStatus ? filtered_active : active_filter})`,
                    }}
                  />

                  <StyledModalContainer>
                    {this.state.isTemplateStatus && (
                      <Box
                        data-test-id="dropdown-menu"
                        style={{
                          position: "absolute",
                          top: "44px",
                          left: "0",
                          width: "210px",
                          maxHeight: "400px",
                          overflowY: "auto",
                          backgroundColor: "white",
                          borderRadius: "12px",
                          zIndex: 999,
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                          paddingBottom: "10px",
                          paddingTop: "10px",
                          marginTop: "5px"
                        }}
                      >
                      {
                          this.state.statusList.map((val:any, index:any) => (
                            <MenuItem
                              key={index}
                              value={val}
                              data-test-id="hello-single-user-id"
                              onClick={() => this.handleSelectionStatus(val, index)}
                              onMouseEnter={() => this.handleMouseEnter3(index)}
                              onMouseLeave={this.handleMouseLeave3}
                              style={{
                                fontSize: "16px", fontFamily: "Open Sans", fontWeight: 400, color: "black", marginLeft: "7px", marginRight: "7px",
                                maxWidth: "290px",
                                borderRadius: "8px",
                                whiteSpace: "normal",
                                overflowWrap: "break-word",
                                backgroundColor: this.state.newHoveredIndex === index ? "#FDD100" : "white"
                              }}
                            >
                              {val}
                            </MenuItem>
                          ))}
                      </Box>
                    )}
                  </StyledModalContainer>
                </div>
              </div>
            </form>
          </div>
          <div className="partner-left">
            {this.state.circularLoading ? (
              <CircularProgressBox>
                <StyledCircularProgress />
              </CircularProgressBox>
            ) : (
              <NewBox className="partner-filter-data" >
                <div className="template-search-result">
                  <ul>
                    <div
                      style={webStyle.main}
                      data-test-id="templateList"
                    >
                      {this.renderTemplateContent()}
                      {DuplicatCard()}
                    </div>
                  </ul>
                </div>
              </NewBox>
            )}
          </div>
        </div>
      </>
    );
  }
}

// Customizable Area Start
const webStyle = {
  duplicateButton: {
    cursor: "pointer"
  },
  Grid1: {
    backgroundColor: "#F6F6F9",
    padding: "16px",
    borderRadius: "11px",
    marginLeft: "13px",
    width: "100%",
    display: 'flex',
    gap: 15,
  },
  addFeatureButton: {
    backgroundColor: "#f7da08",
    height: "50px",
    width: "26%",
    fontWeight: "bold",
    borderRadius: "13px"
  },
  main: {
    borderRadius: "11px",
    margin: "21px",
  },
  footerStyle: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "1rem",
    paddingTop: "50px"
  },
  buttonsubmit: {
    backgroundColor: "#f7da08",
    borderRadius: "7px",
    width: "19%"
  },
  mandatory: {
    color: "red"
  },

  filterHeader: {
    padding: "11px 0px 0px 22px",
    display: "flex",
    alignItems: "flex-start",
    gap: "20px",
    fontFamily: 'Open Sans',
  },

  buttoncancel: {
    paddingLeft: "18px"
  },
  root: {
    maxWidth: 500,
    maxHeight: 90,
    marginRight: "48px",
    marginLeft: "38px"
  },
  Typo1: {
    color: 'black',
    marginBottom: '10px',
    fontWeight: 700,
    fontSize: 20,
    fontFamily: 'Open Sans',
  },
  Typo2: {
    color: 'black',
    lineHeight: '23px',
    fontSize: 16,
    fontFamily: 'Open Sans',
  },
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  dropdown: {
    minWidth: 120
  },
  search: {
    position: "relative",
    backgroundColor: "white",
    marginRight: "48px",
    marginLeft: "38px",
    borderRadius: "10px",
    border: "2px solid #e6e8ea",
    width: "450px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    right: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#95969f"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 0, 1, 1),
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch"
    }
  },
  grid3: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "flex-end"
  }
};

const StyledModalContainer = styled(Box)({
  '@media (max-width: 1370px)': {
    width: "210px",

  },
})

const ModalBox = styled(Box)({
  position: 'fixed',
  padding: '55px 48px 55px 48px',
  maxWidth: '725px',
  width: '100%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: '8px',
  overflowY: 'auto',

});

const ModalTitleBox = styled(Box)({
  display: "flex",
  justifyContent:
    'space-between',
  position: 'relative'
})

const ContentBox = styled(Box)({
})
const ContentTypography = styled(Typography)({
  color: '#000000',
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '24px',
  textAlign: 'left',
  marginTop: "35px",
  marginBottom: "16px",

})
const SubmitBoxContainer = styled(Box)({
  display: 'flex',
  width: '100%', paddingRight: '51px',
  gap: 14
})
const HeadingTypography = styled(Typography)({
  fontSize: "24px",
  fontWeight: 700,
  lineHeight: '24px',
  textAlign: 'left',
  color: '#000000'


})

const SubmitBox = styled(Button)({
  maxWidth: '100px',
  width: '100%',
  background: '#FFCC00',
  padding: '10px',
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
  borderRadius: '8px',
  marginTop: '40px',
  '&:hover': {
    backgroundColor: '#FFCC00',
    color: '#000000',
    cursor: 'pointer',
  },
})
const CircularProgressBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '400px'
})
const StyledCircularProgress = styled(CircularProgress)({
  color: '#FDD001'
})
const GameCardTypography = styled(Typography)({
  fontWeight: 'bold',
  color: 'black',
  display: 'flex',
  justifyContent: 'center'
})
const StyledButton = styled('button')({
  cursor: 'pointer',
  maxWidth: "100%",
  '@media (max-width: 620px)': {
    width: '95px',
  },
});
const StyledDescriptionDiv = styled(Box)({
  flex: 1
});
const StyledBox = styled(Box)({
  '@media (max-width: 620px)': {
    flexDirection: 'column',
    alignItems: 'center',
  },
})
const StyledSubBox = styled(Box)({
  gap: "20px",
  margin: "20px",
  paddingLeft: "10px",
  '@media (max-width: 620px)': {
    width: '75%',
    gap: '5px'
  },
})
const StyledLink = styled('a')({
  display: 'block',
  width: 'unset',
  cursor: 'pointer',
  fontWeight: 400,
  fontSize: '12px',
})
const StyledLabel = styled('label')({
  fontSize: "18px",
  marginTop: "12px",
  fontFamily: 'Open Sans'
})
const StyledSpan = styled('span')({
  fontSize: "16px",
  fontFamily: "Open Sans",
  fontWeight: 400,
  color: "black"
})
const StyledExpandMoreIcon = styled(ExpandMoreIcon)({
  float: "right",
  color: "black",
  fontSize: "25px"
})
const StatusTypography = styled(Typography)({
  color: "#FF5C5C",
  fontSize: "12px",
  fontWeight: 600,
  marginBottom: '10px'
})
const StyledNewModlBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
})
const NewBox = styled(Box)({
  paddingTop: '20px'
})
const NewContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center'
})
const StyledFilterImg = styled('div')({
  cursor: 'pointer',
  height: "23px",
  width: "23px",
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  transition: 'background-image 0.3s ease',
  '&:hover': {
    backgroundImage: `url(${filtered_active}) !important`,
  },
});


// Customizable Area End