import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Row {
  id: number;
  title: string;
  description: string;
}
export interface CalculationRow {
  id: string;
  min: number;
  max: number;
  weightage:number
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: string
  // Customizable Area End
}

export interface KPIDetails {
  id: number;
  name: string;
  description: string;
  kpi_type: string;
  weightage: number;
  automatic_calculation: any;
  status: string;
  goal_type: string | null; 
  calculation_method: string | null;
  goal_value: number | null;
  run_rate: number | null;
  part_of_analytics: boolean | null;
  analytics_parameter_id: number | null;
  maximum_points: number;
  minimum_points: number;
  point: number | null;
  progress: number | null;
  activated: boolean;
}
export interface RowSlabdata {
  id: number;
  min: string;
  max: string;
  weightage: string;
}
export interface KPIResponse {
    id: string;
    type: string;
    attributes: KPIDetails;
}
export interface DataItem {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}
interface S {
  // Customizable Area Start
  typing: string;
  Dropdown: string;
  KpiChange: string;
  rows:any;
  rows2:any
  selectedValues:any;
  options: any,
  addedRows: any;
  nextId:number,
  showTemplateData:any
  currentStatus: string;
  circularLoading: boolean;
  KPIData: KPIResponse;
  selectedCalType: string;
  rowDrkprorata: any;
  rowSlab: RowSlabdata[];
  rowDrkprodata: any;
  analyticsData: DataItem[];
  qualifiedPercent: string;
  checklistDescriptions: string;
  maxDescriptionLength: number;
  rowOccDkr: any[];
  rowAppOcc: any[];
  rowReport: any[];
  dialogMessage: string;
  isOpenDialog: boolean;
  rowProductivity: any[];
  occrTitle: string;
  timingError: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddDkrController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getShowTempaltewithKpiId:string="";
  getAnalyticsDetailsId: string = "";
  postNewDkrId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      rows: [
        { id: 1, title: '', responseType: 'manual', options: [''] },
      ],
      rows2: [
        { id: 1, title: '', responseType: 'manual', options: ['manual', 'dropdown'] },
      ],
      rowDrkprorata: [{ id: 1, DKRtype: 'captured_dkr', DkrTitle: ''},
        { id: 2, DKRtype: 'gamified_dkr', DkrTitle: ''}
      ],
      rowDrkprodata: [{ id: 1, DKRtype: 'captured_dkr_numerator', DkrTitle: ''},
        { id: 2, DKRtype: 'captured_dkr_denominator', DkrTitle: ''},
        { id: 3, DKRtype: 'gamified_dkr', DkrTitle: ''}
      ],
      rowSlab: [
        { id: 1, min: "", max: "", weightage: "" },
      ],
      rowOccDkr: [
        { id: 1, resOption: '', resNature: 'positive'},
      ],
      rowAppOcc: [
        { id: 1, occTitle: '', deductVal: ''},
      ],
      rowReport: [
        { id: 1, title: '', deductableVal: ''},
      ],
      rowProductivity: [
        { id: 1, title: '', value: ''},
      ],
      typing: "Slab",
      Dropdown:"Number",
      KpiChange:"KPI1",
      selectedValues:{},
      options: [],
      addedRows: {},
      nextId:1,
      showTemplateData:[],
      currentStatus: "",
      circularLoading: false,
      KPIData: {
          id: "", 
          type: "", 
          attributes: {
            id: 0, 
            name: "", 
            description: "", 
            kpi_type: "", 
            weightage: 0,
            automatic_calculation: null,
            status: "",
            goal_type: null,
            calculation_method: null,
            goal_value: null,
            run_rate: null,
            part_of_analytics: null,
            analytics_parameter_id: null,
            maximum_points: 0,
            minimum_points: 0,
            point: null,
            progress: null,
            activated: false,
          },
      },
      selectedCalType:'slab',
      analyticsData: [],
      qualifiedPercent: '',
      checklistDescriptions:'',
      maxDescriptionLength: 1000,
      dialogMessage: '',
      isOpenDialog: false,
      occrTitle: '',
      timingError: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const TempID = localStorage.getItem("TemplateNavigationId");
    console.log("kkjjjjjjjjjjj",TempID)
    this.showTemplateswithKpis(TempID)
    this.getAnalyticaldetails()
    // Customizable Area End
  }



    // Customizable Area Start
    async receive(from: string, message: Message) {
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId === this.getShowTempaltewithKpiId){
          this.getKpisdetails(responseJson.data)
        }
        if (apiRequestCallId === this.getAnalyticsDetailsId) {
          this.setState({ analyticsData: responseJson.data })
        }
        if (apiRequestCallId === this.postNewDkrId){
          this.handleCreateDkrRes(responseJson)
        }
      }
    }
    // Customizable Area End
  navigateToEditTemplate2=()=>{
    this.props.navigation.navigate("EditTemplate2")
  };
  navigateToEditKpi=()=>{
  }
  navigateToAddDKR5=()=>{
    this.props.navigation.navigate("AddDkr5")
  }
  navigateToAddDKR=()=>{
    this.props.navigation.navigate("AddDkr")
  }
  handleCalculationTypeChange = (event:any) => {
    this.setState({ typing: event.target.value });
  };
  navigateToVeiwKpi5=()=>{
    this.props.navigation.navigate("ViewKpi5")
  };

handleAddRow = () => {
  const { nextId } = this.state;

  const newRow = {
    id: nextId,
    title: '',
    responseType: 'manual',
    options: [''], 
  };

  this.setState((prevState) => ({
    rows: [...prevState.rows, newRow],
    selectedValues: { ...prevState.selectedValues, [prevState.rows.length]: newRow.responseType },
    nextId: prevState.nextId + 1,
  }));
};
                             
handleAddOption = (index:any) => {
  this.setState((prevState) => {
    const newAddedRows = { ...prevState.addedRows };
    if (!newAddedRows[index]) {
      newAddedRows[index] = [];
    }
    newAddedRows[index].push({ option: "" });
    return { addedRows: newAddedRows };
  });
};
  getNameById = (id: number | null) => {
    const data = this.state.analyticsData    
    const item = data.find((entry) => entry.id === id);
    return item ? item.name : 'Not Found';
  }

  handleTitleChange = (index: number, value: string) => {
    const updatedRows = [...this.state.rows];
    updatedRows[index].title = value;
    this.setState({ rows: updatedRows });
  };
  handleAddProdRow = () => {
    const { nextId } = this.state;
  
    const newRow = {
      id: nextId,
      title: '',
      value: '',
    };
  
    this.setState((prevState) => ({
      rowProductivity: [...prevState.rowProductivity, newRow],
      selectedValues: { ...prevState.selectedValues, [prevState.rowProductivity.length]: 'manual' },  // Default responseType as 'manual'
      nextId: prevState.nextId + 1,
    }));
  };

  handleProdChange = (index: number, field: string, value: string) => {
    const updatedRows = [...this.state.rowProductivity];
    updatedRows[index][field] = value;
    this.setState({ rowProductivity: updatedRows, timingError:'' });
  };

  handleRemoveProRow = (index: number) => {
    const { rowProductivity } = this.state;
    
    const updatedRows = rowProductivity.filter((row: any, i: number) => i !== index);
    
    this.setState({
      rowProductivity: updatedRows,
    });
  };
  handleOccChange = (index: number, field: string, value: string) => {
    const updatedRows = [...this.state.rowOccDkr];
    updatedRows[index][field] = value; 
    this.setState({ rowOccDkr: updatedRows });
  };
  handleResponseTypeChange = (index: number, value: any) => {
    const updatedRows = [...this.state.rows];
    updatedRows[index].responseType = value;
    this.setState({ rows: updatedRows });
  };
  handleAddOptions = (index: number) => {
    const updatedRows = [...this.state.rows];
    updatedRows[index].options.push('');
    this.setState({ rows: updatedRows });
  };
  handleRemoveOptions = (rowIndex: number, optionIndex: number) => {
    const updatedRows = [...this.state.rows];
    updatedRows[rowIndex].options.splice(optionIndex, 1);
    this.setState({ rows: updatedRows });
  };
  handleOptionChange = (rowIndex: number, optionIndex: number, value: string) => {
    const updatedRows = [...this.state.rows];
    updatedRows[rowIndex].options[optionIndex] = value;
    this.setState({ rows: updatedRows });
  };
  handleRadioChange = (event: any) => {
    this.setState({ selectedCalType: event.target.value });
  };
  handleOccTitle = (event: any) => {
    this.setState({occrTitle: event.target.value})
  }

  handleProChange = (rowIndex: number, value: string) => {
    const KpiDetail = this.state.KPIData.attributes

    const applicableData = KpiDetail.goal_type === 'number'
      ? this.state.rowDrkprorata
      : this.state.rowDrkprodata;
  
    const updatedData = [...applicableData];
  
    updatedData[rowIndex].DkrTitle = value;
  
    if (KpiDetail.goal_type === 'number') {
      this.setState({ rowDrkprorata: updatedData });
    } else {
      this.setState({ rowDrkprodata: updatedData });
    }
  };
  
  handlecloseDialog = () => {
    this.setState({ isOpenDialog: false });
  };

  handleRemoveCalRow = (id: number) => {
    this.setState((prevState) => {
      const updatedRows = prevState.rowSlab.filter((row) => row.id !== id);
      return {
        rowSlab: updatedRows.map((row, index) => ({ ...row, id: index + 1 })),
      };
    });
  };

  handleAddCalRow = () => {
    const newRow = { id: this.state.rowSlab.length + 1, min: "", max: "", weightage: "" };
    this.setState((prevState) => ({ rowSlab: [...prevState.rowSlab, newRow] }));
  };

  handleQualifiedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({qualifiedPercent: event.target.value})
  }
  handleRowSlabChange = (
    id: number,
    field: keyof RowSlabdata,
    value: string
  ) => {
    this.setState((prevState) => ({
      rowSlab: prevState.rowSlab.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      ),
    }));
  };
  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    } as Pick<any, keyof any>);
  };

  handleAddRowOccDkr = () => {
    const { nextId } = this.state;
  
    const newRowOccDkr = {
      id: nextId,
      resOption: '',
      resNature: '',
    };
  
    this.setState((prevState) => ({
      rowOccDkr: [...prevState.rowOccDkr, newRowOccDkr],
      nextId: prevState.nextId + 1,
    }));
  };
  
  handleAddRowAppOcc = () => {
    const { nextId } = this.state;
  
    const newRowAppOcc = {
      id: nextId,
      occTitle: '',
      deductVal: '',
    };
  
    this.setState((prevState) => ({
      rowAppOcc: [...prevState.rowAppOcc, newRowAppOcc],
      nextId: prevState.nextId + 1,
    }));
  };

  handleRemoveRowAppOcc = (index: number) => {
    const { rowAppOcc } = this.state;
    const updatedRowAppOcc = rowAppOcc.filter((_, i) => i !== index);
    this.setState({
      rowAppOcc: updatedRowAppOcc,
    });
  };
  handleDeductValChange = (index: number, value: string) => {
    const updatedRows = [...this.state.rowAppOcc];
    updatedRows[index].deductVal = value;
    this.setState({ rowAppOcc: updatedRows });
  };

  handleCreateDkrRes = async (responseJson: any) => {
    if (responseJson.error) {
      this.setState({dialogMessage: responseJson.error, isOpenDialog: true})
    } else {
      this.props.navigation.navigate("EditTemplate2")
    }
  }

  handleSubmitDkr = () => {
    const { rowOccDkr, rowAppOcc, occrTitle, rows, KPIData, selectedCalType, rowProductivity,checklistDescriptions, qualifiedPercent, rowSlab, rowDrkprodata,rowDrkprorata } = this.state;
    const KpiDetail = KPIData.attributes;
    let apiPostData;
    const occName:  { [key: string]: string } = {
      timing: 'Late Occurrence',
      attendance: 'Absence Occurrence',
      report: 'Missed submission'
    }

    switch (KpiDetail.kpi_type) {
      case 'timing':
      case 'attendance':
      case 'report':
        if (!rowProductivity || rowProductivity[0].value.length === 0) {
          this.setState({timingError: 'Please fill required field'});
          return;
        }
        apiPostData = {
          'kpi_id': KPIData.id,
          'template_dkr': {
            "applicable_occurrences_attributes": rowProductivity.map((dkr: any, index: number) => (
              { "title": `${index + 1}. ${occName[KpiDetail.kpi_type]}`, "deductible_value": Number(dkr.value)}
          ))},
        };
        break;
  
      case 'core':
        if (KpiDetail.automatic_calculation) {
          if (KPIData.attributes.goal_type === 'number') {

            if (selectedCalType === 'pro_rata') {
              apiPostData = {
                'kpi_id': KPIData.id,
                'template_dkr': {
                  "calculation_type": selectedCalType,
                  "minimum_qualify_value": qualifiedPercent, 
                  'applicable_dkrs_attributes': rowDrkprorata.map((dkr: any) => ({
                    "title": dkr.DkrTitle,
                    "dkr_type": dkr.DKRtype, 
                  })),
                },
              };
            } else {
              apiPostData = {
                'kpi_id': KPIData.id,
                'template_dkr': {
                  "calculation_type": selectedCalType,
                  "slabs_attributes": rowSlab.map((dkr: any) => ({
                    "min_value": dkr.min,
                    "max_value": dkr.max,
                    "weightage": dkr.weightage,
                  })),
                  'applicable_dkrs_attributes': rowDrkprorata.map((dkr: any) => ({
                    "title": dkr.DkrTitle,
                    "dkr_type": dkr.DKRtype, 
                  })),
                },
              };
            }

          } else {
            if (selectedCalType === 'pro_rata') {
              apiPostData = {
                'kpi_id': KPIData.id,
                'template_dkr': {
                  "calculation_type": selectedCalType,
                  "minimum_qualify_value": qualifiedPercent, 
                  'applicable_dkrs_attributes': rowDrkprodata.map((dkr: any) => ({
                    "title": dkr.DkrTitle,
                    "dkr_type": dkr.DKRtype,
                  })),
                },
              };
            } else {
              apiPostData = {
                'kpi_id': KPIData.id,
                'template_dkr': {
                  "calculation_type": selectedCalType,
                  "slabs_attributes": rowSlab.map((dkr: any) => ({
                    "min_value": dkr.min,
                    "max_value": dkr.max,
                    "weightage": dkr.weightage,
                  })),
                  'applicable_dkrs_attributes': rowDrkprodata.map((dkr: any) => ({
                    "title": dkr.DkrTitle,
                    "dkr_type": dkr.DKRtype, 
                  })),
                },
              };
            }
          }

        } else {
          apiPostData = {
            'kpi_id': KPIData.id,
            'template_dkr': {
              'applicable_dkrs_attributes': rows.map((dkr: any) => {
                if (dkr.responseType === 'manual') {
                  return {
                    "title": dkr.title,
                    "response_type": dkr.responseType,
                  };
                }
                else if (dkr.responseType === 'dropdown') {
                  return {
                    "title": dkr.title,
                    "response_type": dkr.responseType,
                    "dkr_responses_attributes": dkr.options.map((data: string) => ({
                      "title": data,
                    })),
                  };
                }
              })
            }
          }
        }
        break;
  
      case 'productivity':
        if (selectedCalType === 'slab') {
          apiPostData = {
            'kpi_id': KPIData.id,
            'template_dkr': {
              "calculation_type": selectedCalType,
              "slabs_attributes": rowSlab.map((dkr: any) => ({
                "min_value": dkr.min,
                "max_value": dkr.max,
                "weightage": dkr.weightage,
              })),
              'applicable_dkrs_attributes': rowProductivity.map((dkr: any) => ({
                'title': dkr.title,
                'value': Number(dkr.value),
              })),
            },
          };
        } else {
          apiPostData = {
            'kpi_id': KPIData.id,
            'template_dkr': {
              "calculation_type": selectedCalType,
              "minimum_qualify_value": qualifiedPercent,
              'applicable_dkrs_attributes': rowProductivity.map((dkr: any) => ({
                'title': dkr.title,
                'value': Number(dkr.value),
              })),
            },
          };
        }
        break;

      case 'checklist':
        apiPostData = {
          'kpi_id': KPIData.id,
          'template_dkr': {
            "description": checklistDescriptions,
            'applicable_dkrs_attributes': rowProductivity.map((dkr:any, i) => ({
              "value": Number(dkr.value),
              "title": `Checklist Option ${i + 1}`,
              "description": dkr.title,
            })),
          },
        };
        break;
      case 'occurrence':
        apiPostData = {
          'kpi_id': KPIData.id,
          'template_dkr': {
            "applicable_dkrs_attributes": [
              {"title": occrTitle, "response_type": "dropdown" ,
                "dkr_responses_attributes": rowOccDkr.map((dkr:any)=> (
                  { "title": dkr.resOption, "response_nature": dkr.resNature }
                ))
              }
            ],
            "applicable_occurrences_attributes": rowAppOcc.map((dkr: any, index: number) => (
              { "title": `${index + 1} Negative Response`, "deductible_value": Number(dkr.deductVal) }
            ))
          },
        };
        break;
  
      default:
        console.log("Unhandled kpi_type:", KpiDetail.kpi_type);
        return;
    }
  
    this.createNewDkr(apiPostData);
  };
  

handleRemoveOption = (index:any, optionIndex:any) => {
  this.setState((prevState) => {
    const newAddedRows = { ...prevState.addedRows };
    newAddedRows[index].splice(optionIndex, 1);
    return { addedRows: newAddedRows };
  });
};

  handleRemoveRow = (index:any) => {
    const { rows } = this.state;
    const updatedRows = rows.filter((row:any, i:any) => i !== index);
    this.setState({
      rows: updatedRows,
    });
  };

  handleRemoveRowOccDkr = (index: number) => {
    const { rowOccDkr } = this.state;
    const updatedRowOccDkr = rowOccDkr.filter((row:any, i) => i !== index);
    this.setState({
      rowOccDkr: updatedRowOccDkr,
    });
  };
  
  handleSelectChange = (index: any, event: any) => {
    const { selectedValues } = this.state;
    const newSelectedValues = { ...selectedValues, [index]: event.target?.value };
    this.setState({ selectedValues: newSelectedValues });
  };  

  showTemplateswithKpis = async (id:any) => {
    this.setState({circularLoading: true})
    let userToken =localStorage.getItem('authToken')
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getShowTempaltewithKpiId= requestMessage.messageId;
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `bx_block_gamification/kpis/${id}`
    );
   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area Start
  getKpisdetails = (response: any) =>{
      this.setState({circularLoading: false, currentStatus: response.attributes.status, KPIData: response})
  }

  getAnalyticaldetails = async () => {
    let userToken = window.localStorage.getItem("authToken");
    const fetchactvateAccountsHeader = {
      token: userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAnalyticsDetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_gamification/organization_templates/analytics_dropdown`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchactvateAccountsHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createNewDkr = async (apiPostData: any) => {
    let userToken = window.localStorage.getItem("authToken");

    const fetchTemplateAddedHeader = {
      "Content-Type": configJSON.dashboarContentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postNewDkrId = requestMessage.messageId;
    const endpoint = `bx_block_gamification/template_dkrs`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchTemplateAddedHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(apiPostData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    console.log("Request Message: ", requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
