import { IBlock } from "../../../framework/src/IBlock";
import React from "react";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import toast from "react-hot-toast";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: string
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  type: string;
  description: string;
  automatic: boolean;
  runRate: boolean;
  analytics: boolean;
  goaltype: string;
  isModal: boolean;
  statisticsTab: string;
  tabNumber: number;
  scoreTabNumber: number;
  employeesData: any;
  loading: boolean;
  open: boolean;
  name: any;
  descriptionModal: any;
  id: any;
  dayType: any;
  startDate: any;
  endDate: any;
  infoBoardData: any;
  title: any;
  descriptionModalSecond: any;
  openNew: boolean;
  filteredData: any;
  infoTitle: any;
  infoDescription: any
  analyticsChecked: boolean;
  automaticChecked: boolean;
  isHovered: boolean;
  selectedOption: string;
  goalType: string;
  analyticsData: any;
  kpiNextNo: number;
  kpiNameUpdate: string;
  descriptions: string;
  points: string;
  goalValue: number;
  goalCalMethod: string;
  kpinameError: string;
  kpiDescrError: string;
  kpiWeightError: string;
  maxDescriptionLength: Number;
  descriptionsLenght: Number;
  isOpenDialog: boolean;
  dialogMessage: string;
  goalValError: string;
  analyticParam: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddKpiController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getEventDetailsId: any
  getinfoboardsDetailsId: any
  getAnalyticsDetailsId: string = "";
  getTemplateDataId: string = "";
  postNewKpiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      type: "core",
      description: "",
      automatic: false,
      runRate: false,
      analytics: false,
      goaltype: "number",
      isModal: false,
      statisticsTab: '',
      tabNumber: 1,
      scoreTabNumber: 0,
      employeesData: [],
      infoBoardData: [],
      loading: true,
      open: false,
      name: "",
      descriptionModal: "",
      id: null,
      dayType: "",
      startDate: "",
      endDate: "",
      title: "",
      descriptionModalSecond: "",
      openNew: false,
      filteredData: [],
      infoTitle: '',
      infoDescription: '',
      analyticsChecked: false,
      automaticChecked: false,
      isHovered: false,
      selectedOption: 'core',
      goalType: "percentage",
      analyticsData: [],
      kpiNextNo: 1,
      kpiNameUpdate: '',
      descriptions: '',
      points: '',
      goalValue: 100,
      goalCalMethod: 'sum_calculation',
      kpinameError: '',
      kpiDescrError: '',
      kpiWeightError: '',
      maxDescriptionLength: 1000,
      descriptionsLenght: 0,
      isOpenDialog: false,
      dialogMessage: '',
      goalValError: '',
      analyticParam: '3'
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {

    super.componentDidMount();
    this.getEventDetails();
    this.infoboardsDetails();


    // Customizable Area Start
    const activeTab = await getStorageData("tabb")
    if (activeTab === null) {
      this.setState({ statisticsTab: "" })
    }
    else {
      this.setState({ statisticsTab: activeTab })
    }
    this.getTemplateAddedResponse()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getEventDetailsId) {
        this.getEventDetailsIdFunction(responseJson)
      }
      if (apiRequestCallId === this.getinfoboardsDetailsId) {
        this.infoBoardDataFunction(responseJson)
      }
      if (apiRequestCallId === this.getAnalyticsDetailsId) {
        this.setState({ analyticsData: responseJson.data })
      }
      if (apiRequestCallId === this.getTemplateDataId) {
        this.getTemplateAddedDetail(responseJson)
      }
      if (apiRequestCallId === this.postNewKpiId) {
        this.handleCreatekpiRes(responseJson)
      }
    }
    // Customizable Area End
  }

  // web events
  handleChange = (event: React.ChangeEvent<{
    name?: string | undefined;
    value: string;
  }>) => {
    this.setState({
      type: event.target.value
    });
  };
  handleDescriptionChange = (event: React.ChangeEvent<{
    name?: string | undefined;
    value: string;
  }>) => {
    this.setState({ description: event.target.value })
  };
  handleAutomatic = () => {
    this.setState({ automatic: !this.state.automatic })
  }
  handleRunrate = () => {
    this.setState({ runRate: !this.state.runRate })
  }
  handleAnalytics = () => {
    this.setState({ analytics: !this.state.analytics })
  }
  handleGoal = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ goaltype: event.target.value })
  }

  // Customizable Area Start
  getEventDetailsIdFunction = (responseJson: any) => {
    if (responseJson) {
      this.setState({ employeesData: responseJson?.data, loading: false });
    }
  }
  infoBoardDataFunction = (responseJson: any) => {
    if (responseJson) {
      this.setState({ infoBoardData: responseJson?.infoboards })
    }
  }
  handlePageTabNavigate = async (pageName: any) => {
    setStorageData("tabb", pageName)
    this.setState({ statisticsTab: pageName })
  }
  handlePageTabBackNavigate = () => {
    removeStorageData("tabb")
    this.setState({ statisticsTab: "" })
  }
  handleRadioChange = (event: any) => {
    this.setState({
      selectedOption: event.target.value,
      descriptions: '',
      points: '',
      kpinameError: "",
      kpiDescrError: "",
      kpiWeightError: "",
      goalValError: ""
    });
  };
  
  handleMouseOver = () => {
    this.setState({ isHovered: true });
  };
  handlecloseDialog = () => {
    this.setState({ isOpenDialog: false });
  };
  handleMouseOut = () => {
    this.setState({ isHovered: false });
  };
  handleChanxgeAutomatic = (event: any) => {
    this.setState({ automaticChecked: event.target?.checked })
  }
  handleCalMethods = (event: any) => {
    this.setState({ goalCalMethod: event.target.value });
  }
  handleChangeChecked2 = (event: any) => {
    this.setState({ analyticsChecked: event.target?.checked })
    this.getAnalyticaldetails()
  }
  handleGoalTypeChange = (event: any) => {
    this.setState({ goalType: event.target.value, goalValError:"" });
  };
  handleAnalyaticParam = (event: any) => {
    this.setState({ analyticParam: event.target.value});
  }
  getTemplateAddedDetail = async (responseJson: any) => {
    const kpiNumbers = (responseJson.grouped_templates?.Core?.map((item: any) => parseInt(item.name.split('-')[1]))) || 0;
    const nextKpiNumber = kpiNumbers.length > 0 ? Math.max(...kpiNumbers) + 1 : 1;
    this.setState({ kpiNextNo: nextKpiNumber })
  }
  handleFocus = () => {
    this.setState({kpiWeightError: ''});
  };
  handleNumberInput = (event:any) => {
    if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
    }
};


  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {selectedOption, kpiNextNo} = this.state;
    const { name, value } = event.target;
    let kpiNameUpdates = this.state.kpiNameUpdate;
    if (['core', 'attendance', 'timing', 'report'].includes(selectedOption)) {
      switch (selectedOption) {
        case 'core':
          kpiNameUpdates = `KPI-${kpiNextNo}`;
          break;
        case 'attendance':
          kpiNameUpdates = 'Attendance';
          break;
        case 'timing':
          kpiNameUpdates = 'Timing';
          break;
        case 'report':
          kpiNameUpdates = 'Report';
          break;
        default:
          kpiNameUpdates = '';
      }
    } else if (name === 'kpiNameUpdate') {
      kpiNameUpdates = value;
    }
    this.setState({kpiNameUpdate: kpiNameUpdates})
    
    if (name === 'descriptions') {
      this.setState({descriptionsLenght: value.length});
    }
    this.setState({
      [name]: value
    } as Pick<any, keyof any>);
  };
  handleChangeGoalVal = (event: any) => {
    this.setState({ goalValue: event.target.value });
  }
  handleNavigationBack = () => {
    this.props.navigation.navigate("EditTemplate2")
  }
  handleSubmit = () => {
    const { kpiNameUpdate, descriptions, points, selectedOption, analyticsChecked, goalType, goalValue, automaticChecked} = this.state;   
    if (kpiNameUpdate.length == 0 && selectedOption !== 'core' && selectedOption !== 'attendance' && selectedOption !== 'timing' && selectedOption !== 'report' && selectedOption !== 'productivity' &&selectedOption !== 'checklist' && selectedOption !== 'occurrence') {
      this.setState({ kpinameError: "Title is required" });
    } else if (!descriptions) {
      this.setState({ kpiDescrError: "Description is required" });
    } else if (!points ) {
      this.setState({ kpiWeightError: "Points are required" });
    } else if (Number(points) <= 0) {
      this.setState({ kpiWeightError: "Points can't be negative" });
    } else if ((automaticChecked && !goalValue) || (selectedOption === 'productivity' && !goalValue)) {
      this.setState({ goalValError: "Goal value is required" });
    }else if (automaticChecked && goalType === "percentage" && goalValue > 100) {
      this.setState({ goalValError: "Percentage should not more than 100" });
    } else{      
      this.createNewKpi()
    }
  };

  handleCreatekpiRes = async (responseJson: any) => {
    if (responseJson.error) {
      this.setState({dialogMessage: responseJson.error, isOpenDialog: true})
    } else {
      this.props.navigation.navigate("EditTemplate2")
    }
  }
  handleOpenSecond = (modalTitle: any, eventId: any, description: any) => {
    if (!eventId) {
      this.setState({ open: true, infoDescription: description, infoTitle: modalTitle })
    }
    const filteredData = eventId
      ? this.state.employeesData.filter(
        (item: any) => item.attributes.id === eventId
      )
      : [];
    const formatDate = (date: any) => {
      const parsedDate = new Date(date);
      const day = String(parsedDate.getDate()).padStart(2, '0');
      const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
      const year = parsedDate.getFullYear();
      return `${day}-${month}-${year}`;
    };
    const filteredItem = filteredData.length > 0 ? filteredData[0].attributes : null;
    const formattedStartDate = filteredItem ? formatDate(filteredItem.start_date) : null;
    const formattedEndDate = filteredItem ? formatDate(filteredItem.end_date) : null;
    const newModalTitle = filteredItem.event_name;
    const newDescription = filteredItem.description
    const dayType = filteredItem.day_type === 'full_day' ? 'Full Day' : 'Half Day'
    this.setState({
      title: newModalTitle,
      descriptionModalSecond: newDescription,
      openNew: true,
      filteredData,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      dayType,
    });
  };

  handleClose = () => {
    this.setState({ open: false })
  }
  handleCloseNew = () => {
    this.setState({ openNew: false, open: false })
  }
  Goback = async (path: any) => {
    this.setState({ statisticsTab: "" })
    removeStorageData("tabb")
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);

  }
  handleCancel = async (path: any) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  }
  
  getEventDetails = async () => {
    let userToken = window.localStorage.getItem("authToken");
    const fetchactvateAccountsHeader = {
      token: userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEventDetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_gamification/events/company_events`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchactvateAccountsHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  infoboardsDetails = async () => {
    let companyId = await getStorageData("companyId");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getinfoboardsDetailsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/infoboards?company_id=${companyId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAnalyticaldetails = async () => {
    let userToken = window.localStorage.getItem("authToken");
    const fetchactvateAccountsHeader = {
      token: userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAnalyticsDetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_gamification/organization_templates/analytics_dropdown`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchactvateAccountsHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTemplateAddedResponse = async () => {
    let userToken = window.localStorage.getItem("authToken");
    const templateId = await getStorageData("templateId")
    const fetchTemplateAddedHeader = {
      "Content-Type": configJSON.dashboarContentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTemplateDataId = requestMessage.messageId;
    const endpoint = `bx_block_gamification/kpi_listing?template_type=organization_templates&template_id=${templateId}`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchTemplateAddedHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createNewKpi = async () => {
    let userToken = window.localStorage.getItem("authToken");
    const templateId = await getStorageData("templateId")
    const {
      selectedOption,
      kpiNameUpdate,
      descriptions,
      points,
      automaticChecked,
      analyticsChecked,
      goalType,
      goalValue,
      goalCalMethod,
      analyticParam
    } = this.state;
    const formData = new FormData();

    formData.append('organization_template[kpi_type]', selectedOption);
    formData.append('organization_template[organization_template_id]', templateId);
    formData.append('organization_template[name]', kpiNameUpdate);
    formData.append('organization_template[weightage]', points);
    formData.append('organization_template[description]', descriptions);
    formData.append('organization_template[automatic_calculation]', String(automaticChecked));
    formData.append('organization_template[goal_value]', String(goalValue));
    formData.append('organization_template[goal_type]', automaticChecked? goalType : '');
    formData.append('organization_template[part_of_analytics]', automaticChecked? String(analyticsChecked): '');
    formData.append('organization_template[calculation_method]', automaticChecked? goalCalMethod: '');
    formData.append('organization_template[analytics_parameter_id]', analyticsChecked ? analyticParam : '');

    const fetchTemplateAddedHeader = {
      "Content-Type": configJSON.dashboarContentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postNewKpiId = requestMessage.messageId;
    const endpoint = `bx_block_gamification/kpis`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchTemplateAddedHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
