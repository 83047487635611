import React from "react";
// Customizable Area Start
import {
  Button,
  Typography,
  TextField,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Divider,
  Box,styled
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// Customizable Area End
import AddDkrController, { Props } from "./AddDkrController";
export const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",
    }
  },
  typography: {
    subtitle1: {
      margin: "20px 0px"
    },
    h6: {
      fontWeight: 500
    },
  }
});
export default class ChecklistDkr extends AddDkrController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box style={webStyle.Checkdkrmaincontainer}>
          <Grid 
          justifyContent="space-between" 
          container 
          alignItems="center">
            <Grid 
            item
            style={webStyle.CheckdkrleftSide} 
            className="first" 
            >
              <Grid 
              container 
              alignItems="center"
              spacing={2} 
              >
                <Grid item style={webStyle.CheckdkrrowarrowDisplay}>
                  <ArrowBackIosIcon style={{ fontSize: "24px", color: "black" }}
                   data-test-id="nextButton1" onClick={this.navigateToEditTemplate2} />
                  <span
                    style={{...webStyle.CheckdkrHeadStyleTop1,fontWeight: 400}}>
                    Edit Template
                  </span>
                </Grid>
                <Grid 
                item 
                style={webStyle.CheckdkrrowarrowDisplay}>
                  <ArrowBackIosIcon style={{...webStyle.CheckdkrHeadStyleTop1,color:"#B2B2B2",fontSize:"16px"}} />
                  <span style={{...webStyle.CheckdkrlabelStyleoptionhead as React.CSSProperties,
                    fontWeight:700,fontSize:"18px"}}>
                    Add DKR Details
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="second"
             style={webStyle.CheckdkrnullContainer} item />
          </Grid>
          <Grid
            alignItems="center"
            container
            style={webStyle.CheckdkrinformContainer as React.CSSProperties}>
            <Grid
              className="first"
              item
              style={webStyle.CheckdkrinformSecondContainerHead}
              xs={12}>
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" 
                  style={webStyle.CheckdkrheaderMain10}>
                    <span style={webStyle.CheckdkrHeadtopStyle22 as React.CSSProperties}>KPI Details</span>
                  </FormLabel>

                </FormControl>
              </div>
              <div className="form-field text-center" id="signupBlock4">
                <div className="control" id="signupfieldcontrol2">
                  <Button style={webStyle.CheckdkrEditbutton as React.CSSProperties}
                   data-test-id="EditButton" onClick={this.navigateToEditKpi}>
                    Edit KPI Details
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.CheckdkrinformationSubject}
              xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend" 
                style={webStyle.CheckdkrheaderMain10}>
                  <span style={{ ...webStyle.CheckdkrlabelStyleoptionhead as React.CSSProperties, 
                    fontSize: "22px" ,fontWeight: 500}}>Type</span>
                </FormLabel>
                <span style={{...webStyle.CheckdkrblurvalueStyle as React.CSSProperties,fontWeight:400}}>Checklist</span>
              </FormControl>
            </Grid>
            <Grid
  className="second"
  item
  style={webStyle.CheckdkrinformationSubject}
  xs={12}
>
  <FormControl component="fieldset">
    <FormLabel component="legend" 
    style={{ ...webStyle.CheckdkrheaderMain10, marginTop: "30px" }}>
      <span style={{ ...webStyle.CheckdkrlabelStyleoptionhead as React.CSSProperties,
         fontWeight: 500, fontSize: "22px" }}>
        Basic Details
      </span>
    </FormLabel>
    
    <Grid container
     style={{ display: "flex" }} spacing={6} xs={12}>
      <Grid item xs={3}>
        <div className="form-block" id="signupBlock3">
          <form>
            <div className="form-field" id="signupform" 
            style={webStyle.CheckdkrtopdescThirty}>
              <span style={webStyle.CheckdkrTopstyleHeader as React.CSSProperties}>Title</span>
              <div className="control" id="signupfieldcontrol"
               style={webStyle.CheckdkrmarginLeft0}>
                <span style={webStyle.CheckdkrblurvalueStyle as React.CSSProperties}>Checklist</span>
              </div>
            </div>
          </form>
        </div>
      </Grid>

      <Grid item xs={6}>
        <div className="form-block" id="signupBlock3">
          <form>
            <div className="form-field" id="signupform" 
            style={webStyle.CheckdkrtopdescThirty}>
              <span style={webStyle.CheckdkrTopstyleHeader as React.CSSProperties}>Description</span>
              <div className="control" id="signupfieldcontrol" 
              style={webStyle.CheckdkrmarginLeft0}>
                <span style={{ ...webStyle.CheckdkrblurvalueStyle as React.CSSProperties, fontWeight: 400 }}>
                  Daily Productivity Goal (Mins): Avg 360 <br />
                  Per Day <br />
                  Monthly Avg Productivity Points <br />
                  compared Goal
                </span>
              </div>
            </div>
          </form>
        </div>
      </Grid>

      <Grid item xs={3}>
        <div className="form-block" id="signupBlock3">
          <form>
            <div className="form-field" id="signupform" 
            style={{ ...webStyle.CheckdkrtopdescThirty, width: "max-content" }}>
              <span style={webStyle.CheckdkrTopstyleHeader as React.CSSProperties}>Points (Weightage)</span>
              <div className="control" id="signupfieldcontrol" 
              style={webStyle.CheckdkrmarginLeft0}>
                <span style={{ ...webStyle.CheckdkrblurvalueStyle as React.CSSProperties, fontWeight: 400 }}>25 points</span>
              </div>
            </div>
          </form>
        </div>
      </Grid>
    </Grid>
  </FormControl>
</Grid>

          </Grid >
          <Divider light style={{ marginTop: "20px" , 
            marginBottom:"20px"}} />
          <Grid
            container
            alignItems="center"
            style={webStyle.CheckdkrinformContainer as React.CSSProperties}
          >
            <Grid
              className="first"
              item
              style={webStyle.CheckdkrinformSecondContainerHead}
              xs={12}
            >
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" 
                  style={webStyle.CheckdkrheaderMain10}>
                    <span style={webStyle.CheckdkrHeadtopStyle22 as React.CSSProperties}>Daily KPI Report</span>
                  </FormLabel>

                </FormControl>
              </div>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.CheckdkrinformationSubject}
              xs={12}
            >
             <Box>
                <Typography style={webStyle.CheckdkrTypography1}>Reminder Description</Typography>
                <TextField
                  rows={6}
                  multiline
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                  }}
                  style={webStyle.TextField2}
                />
                <Typography variant="caption" 
                style={webStyle.CheckdkrTypography2}>0/1000</Typography>
              </Box>
              
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.CheckdkrinformationSubContainerTable}
              xs={12}  >
              <>
                <FormControl component="fieldset">
                  <FormLabel component="legend" 
                  style={webStyle.Checkdkrheadermandatory}>
                    <span style={{...webStyle.CheckdkrlabelStyle100 as React.CSSProperties,
                      fontWeight:500}}>Applicable DKRs</span>
                  </FormLabel>
                </FormControl>
                <TableContainer style={webStyle.CheckdkrtablemainContainer}>
                  <Table aria-label="customized table">
                  <TableHead>
                      <TableRow style={webStyle.CheckdkrmaintableHead}>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",paddingLeft:"35px",width:"8%"}}><span style={{fontWeight:600}}>Sr.</span><span style={{fontWeight:600}}>No.</span></TableCell>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",fontWeight:600,width:"15%"}}>Checkdkrlist Options</TableCell>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",fontWeight:600,width:"32%"}}>
                        Checkdkrlist Options Description
                        </TableCell>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",
                          color:"black",fontWeight:600,width:"10%"}}>
                        Value
                        </TableCell>
                        <TableCell style={{paddingLeft:"30px",width:"22%"}}>
                           <CheckdkrlistButton onClick={this.handleAddRow}  
                          >
                               Add Checkdkrlist Options
                           </CheckdkrlistButton>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
             {this.state.rows.map((row:any, index:any) => (
              <TableRow key={row.id}>
                 <TableCell component="th" scope="row" style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",paddingLeft:"65px"}}>{index + 1}.</TableCell>
                 <TableCell component="th" scope="row" style={{fontFamily:"Open Sans",fontSize:"14px",color:"black"}}>Checkdkrlist Options {index + 1}.</TableCell>

                <TableCell>
                   <Box 
                   className="form-block" 
                   id="signupBlock3">
                              <form>
                                <Box
                                  id="signupform"
                                  className="form-field"
                                >
                                  <Box
                                    className="control"
                                    style={webStyle.CheckdkrmarginLeft0}
                                    id="signupfieldcontrol"
                                  >
                                    <input
                                      data-test-id="txtInputEmail"
                                      type="text"
                                      style={{...webStyle.inputmainFieldTable,
                                        backgroundColor:"White",
                                        width:"100%",
                                        fontFamily:"Open Sans",
                                        color:"Black",
                                        textAlign:"start",
                                        fontWeight:400,fontSize:"14px"}}
                                        placeholder= ""
                                        className="control-input"
                                        id="pronounce-control"
                                      name="email"
                                    />
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                </TableCell>
                <TableCell>
                   <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box id="signupform"  className="form-field"
                                >
                                  <Box
                                    className="control"
                                    id="signupfieldcontrol"
                                    style={webStyle.CheckdkrmarginLeft0}
                                  >
                                    <input
                                      placeholder= ""
                                      type="text"
                                      data-test-id="txtInputEmail"
                                      style={{...webStyle.inputmainFieldTable,
                                        backgroundColor:"White",
                                        width:"110%",
                                        color:"Black",
                                        textAlign:"start",
                                        fontFamily:"Open Sans",
                                        fontSize:"14px",
                                        fontWeight:400,
                                      }}
                                      id="pronounce-control"
                                      name="email"
                                      className="control-input"
                                    />
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                </TableCell>
                <TableCell>
                  <a  data-test-id="clickforRemoveCheck"
                  onClick={() => this.handleRemoveRow(index)}  
                  style={{ color: "#ff5c5c", textDecoration: "underline" ,paddingLeft:"30px"}}>Remove Checkdkrlist Options</a>
                </TableCell>
              </TableRow>
            ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              
              </>
            </Grid>
          </Grid >

          <Grid style={webStyle.CheckdkrbuttonmainContainer}>
            <Box style={{ marginTop: '50px', 
              marginBottom: '40px' }}>
              <Button style={webStyle.CheckdkrSubmitBtn as React.CSSProperties}>
                Submit
              </Button>
              <Button variant="outlined" style={webStyle.CheckdkrCancelBtn as React.CSSProperties} >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Box >
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
    CheckdkrTypography2:{
      display: 'flex', 
      justifyContent: 'start', 
      color: 'black', 
      fontSize: "larger",
      fontFamily: 'Open Sans', 
        marginLeft: "34%",
      },
    TextField2:{
        width: '330px',
        borderRadius: '10px',
        border: '2px solid #F3F4F5',
        marginTop: '10px',
        padding: '8px',
        backgroundColor: 'white',
      },
    CheckdkrTypography1:{
      color: 'black', 
      fontSize: "18px", 
      fontFamily: 'Open Sans', 
      marginTop: '10px' ,
      fontWeight: 700, 
     },
    CheckdkrmaintableHead: {
        backgroundColor: "#F5F5F5",
      },
    CheckdkrlabelStyle100: {
      color: "black",
      fontSize: "22px",
      fontWeight: 500,
      fontFamily:"Open Sans",
      },
    Checkdkrheadermandatory: {
        alignItems: "baseline",
        MarginTop:"30px",
        display: "flex",
        gap: "4px",
      },
      CheckdkrtablemainContainer: { 
        marginTop: "15px" ,
        borderRadius: "10px", 
        border: "1px solid #979797",
        },
    CheckdkrinformationSubContainerTable: {
        width: "98%" ,
        marginTop: "24px",
        },
    inputmainFieldTable: {
      width: "70%",
      background: 'transparent',
        border: '0.5px solid #979797'
      },
    Tcell:{
        fontFamily:"Open Sans",
        fontSize:"14px",
        color:"black"
      },
     CheckdkrEditbutton:{
    fontFamily: 'Open Sans', 
    color: "black", 
    padding: "15px 20px 15px 20px", 
    borderRadius: "10px", 
    backgroundColor: "FDD100", 
    width: "200px", 
    fontWeight: 600, 
    textTransform: "none",
    fontSize: "16px", 
  },
  Captured: {
    fontSize: "14px",
    fontFamily: "Open Sans",
    color: "black",
    width: "20%"
  },
 
  CheckdkrCancelBtn: {
    fontWeight: 600,
    fontSize: "16px",
    textTransform: "none",
    width: "170px",
    fontFamily: 'Open Sans',
    backgroundColor: "white",
    color: "black",
  },
  CheckdkrSubmitBtn: {
    backgroundColor: "FDD100",
    color: "black",
    fontFamily: 'Open Sans',
    fontSize: "16px",
    width: "170px",
    fontWeight: 600,
    marginRight: "20px",
    textTransform: "none",
    
  },
  TCell: {
    fontFamily: "Open Sans",
    color: "black",
    fontSize: "14px",
    fontWeight: 600
  },
  TableC: {
    fontSize: "14px",
    color: "black",
    fontFamily: "Open Sans",
    fontWeight: 600
  },

  Checkdkrmaincontainer: {
    height: "64px",
    marginRight: "-20px",
    background: "#F3F4F5",
  },
  CheckdkrinformContainer: {
    padding: "0 32px",
    flexDirection: "column",
    alignItems: "baseline",
  },
  CheckdkrinformationSubject: {
    marginTop: "24px",
    width: "60%",
  },
  CheckdkrleftSide: {
    padding: "19px 0px 21px 38px"
  },
  CheckdkrnullContainer: {
    padding: "0px 44px 0px 0px"
  },

  CheckdkrinformSecondContainerHead: {
    marginTop: "24px",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "initial",
    width: "98%"
  },
  CheckdkrtopdescThirty: {
    marginTop: "30px",
  },
  container: {
    width: "100%",
    height: "100%",
  },

  CheckdkrlabelStyleoptionhead: {
    color: "black",
    fontWeight: 500,
    fontSize: "22px",
    fontFamily: "Open Sans"
  },
  CheckdkrTopstyleHeader: {
    fontSize: "18px",
    color: "#c8ccc9",
    fontWeight: 700,
    fontFamily: "Open Sans"
  },
  CheckdkrblurvalueStyle: {
    color: "black",
    marginTop: "10px",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: 400
  },
  CheckdkrrowarrowDisplay: {
    alignItems: "center",
    display: "flex",
  },
  CheckdkrHeadStyleTop1: {
    color: "black",
    fontFamily: "Open Sans",
    fontSize: "18px",
  },
  CheckdkrHeadtopStyle22: {
    color: "black",
    fontWeight: 700,
    fontSize: "24px",
    fontFamily: "Open Sans"
  },
  inputField: {
    width: "60%",
    border: '0.5px solid #979797',
    background: 'transparent',
  },
  inputFieldTabless: {
    width: "70%",
    border: '0.5px solid #979797',
    background: 'transparent',
  },
  CheckdkrmarginLeft0: {
    marginLeft: 0,
    marginTop: 10
  },
  CheckdkrbuttonmainContainer: { display: "flex", gap: "20px", margin: "0 40px" },

  CheckdkrheaderMain10: {
    display: "flex",
    alignItems: "baseline",
    gap: "4px",
    MarginTop: "30px"
  },
};
const CheckdkrlistButton=styled(Button)({
  fontFamily: 'Open Sans',
   backgroundColor: "FDD100", 
  color: "black",
   width: "260px",
    fontSize: "16px", 
    fontWeight: 600,
     textTransform: "none",
     paddingTop: "6px",
     paddingBottom:"6px",
     borderRadius:"10px" 
})
// Customizable Area End
