import React from "react";

import {
    Box,
    Typography,
    Grid,
    // Customizable Area Start
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Tooltip,
    Checkbox,
    TextField,
    FormGroup,
    Button,
    IconButton,
    Dialog
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { MessageBox } from "./assets";
import { styled, withStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";


const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

// Customizable Area End

import AddKpiController, {
    Props
} from "./AddKpiController";
import PopupMessageWeb from "../../../components/src/PopupMessage.web";

export default class AddKpi extends AddKpiController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { kpiNextNo, isOpenDialog, dialogMessage, descriptions, maxDescriptionLength, goalValue, descriptionsLenght, points, analyticsChecked, automaticChecked , isHovered ,selectedOption, goalType, kpiNameUpdate} = this.state;
        const kpiNameUpdates = (() => {
            switch (selectedOption) {
              case 'core':
                return `KPI-${kpiNextNo}`;
              case 'attendance':
                return 'Attendance';
              case 'timing':
                return 'Timing';
              case 'report':
                return 'Report';
              case 'productivity':
                return 'Productivity';
              case 'checklist':
                return 'Checklist';   
              case 'occurrence':
                return 'Occurrence';  
              default:
                return '';
            }
          })();
        return (
            // Customizable Area Start
            <Box >
                <Grid container justifyContent="space-between" alignItems="center" style={{ backgroundColor: "#F3F4F5" }}>
                    <Grid className="first" style={webStyle.leftContainer} item>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item style={webStyle.rowDisplay}>
                                <ArrowBackIosIcon 
                                style={{ fontSize: "26px", color: "black",cursor:'pointer' }} 
                                data-testid="multiBack"
                                onClick={this.handleNavigationBack}/>
                                <span
                                    style={webStyle.labelStyle1}
                                >
                                    Edit Template
                                </span>
                            </Grid>
                            <Grid item style={webStyle.rowDisplay}>
                                <ArrowBackIosIcon style={webStyle.labelStyle2} />
                                <span style={webStyle.labelStyle as React.CSSProperties}>
                                    Add KPI
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className="second" style={webStyle.emptyContainer} item />
                </Grid>
                <Box style={webStyle.MainContainer}>
                    <Box>
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label" style={webStyle.FormLabel1}>Type <span style={{ color: 'red' }}>*</span></FormLabel>
                            <RadioGroup
                                data-testid="singleuserCheck"
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                row
                                defaultValue="core"
                                name="row-radio-buttons-group"
                                value={selectedOption}
                                onChange={this.handleRadioChange}

                            >
                                <FormControlLabel
                                    value="core"
                                    control={<Radio style={webStyle.Radio1} />}
                                    style={{ fontFamily: 'Open Sans', fontSize: '14px', color: '#777', }}
                                    label="Core"
                                />
                                <FormControlLabel
                                    value="attendance"
                                    control={<Radio style={webStyle.Radio1} />}
                                    style={{ fontSize: '14px', color: '#777', fontFamily: 'Open Sans', }}
                                    label="Attendance"
                                />
                                <FormControlLabel
                                    value="timing"
                                    label="Timing"
                                    control={<Radio style={webStyle.Radio1} />}
                                    style={webStyle.FormControlLabel1}
                                />
                                <FormControlLabel
                                    control={<Radio style={webStyle.Radio1} />}
                                    value="report"
                                    label="Report"
                                    style={webStyle.FormControlLabel1}
                                />
                                <FormControlLabel
                                    style={webStyle.FormControlLabel1}
                                    control={<Radio style={webStyle.Radio1} />}
                                    value="productivity"
                                    label="Productivity"
                                />
                                <FormControlLabel
                                    value="checklist"
                                    label="Checklist"
                                    control={<Radio style={webStyle.Radio1} />}
                                    style={webStyle.FormControlLabel1}
                                />
                                <FormControlLabel
                                    value="occurrence"
                                    control={<Radio style={webStyle.Radio1} />}
                                    style={webStyle.FormControlLabel1}
                                    label="Occurrence"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Box style={webStyle.box1}>
                        <Typography style={webStyle.Typography1}>Basic Details<span style={{ color: 'red' }}>*</span></Typography>
                        <Box sx={webStyle.box2}>
                            <Box style={webStyle.box3}>
                                <Typography style={webStyle.Typography1}>Title</Typography>
                                {['core', 'attendance', 'timing', 'report','productivity','checklist','occurrence'].includes
                               (selectedOption)? (
                                <TextField
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    style={{
                                        ...webStyle.TextField1, 
                                        padding: '12px',
                                        backgroundColor: '#F3F4F5',
                                        borderRadius: '4px',
                                    }}
                                    value={kpiNameUpdates}
                                    disabled
                                    name="kpiNameUpdate"
                                    id="pronounce-control"
                                    fullWidth
                                />):(
                                <TextField
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    style={{
                                        ...webStyle.TextField1, 
                                        padding: '12px',
                                        borderRadius: '4px',
                                    }}
                                    value= {kpiNameUpdate}
                                    name="kpiNameUpdate"
                                    onChange={this.handleInputChange}
                                    id="pronounce-control"
                                    fullWidth
                                />)}
                                {!kpiNameUpdate && (
                                    <div style={{color: "red"}}>
                                        {this.state.kpinameError}
                                    </div>
                                )}
                            </Box>
                            <Box style={webStyle.box3}>
                                <Typography style={webStyle.Typography1}>Description</Typography>
                                <TextField
                                    rows={6}
                                    multiline
                                    fullWidth
                                    inputProps={{
                                        maxLength: 1000,
                                    }}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    style={webStyle.TextField2}
                                    value={descriptions}
                                    name="descriptions"
                                    onChange={this.handleInputChange}
                                />
                                <Typography variant="caption" style={webStyle.Typography2}>{descriptions.length}/{maxDescriptionLength}</Typography>
                                {!descriptions && (
                                    <div style={{color: "red"}}>
                                        {this.state.kpiDescrError}
                                    </div>
                                )}
                            </Box>
                            <Box style={{ marginRight: '20px' }}>
                                <Typography style={webStyle.Typography1}>Points (Weightage) </Typography>
                                <TextField
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    style={webStyle.TextField3} 
                                    name="points"
                                    value={points}
                                    onFocus={this.handleFocus}
                                    onChange={this.handleInputChange}/>
                                    {(!points || Number(points)<=0) && (
                                    <div style={{color: "red"}}>
                                        {this.state.kpiWeightError}
                                    </div>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                    </Box>
                    {['productivity', 'core'].includes(selectedOption) && (
                        <Box sx={webStyle.box4}>
                            {/* Your existing upper code block */}
                            <Box sx={webStyle.box4}>
                                <Box style={{ marginTop: "30px" }}>
                                    <Box sx={webStyle.box5}>
                                        <Typography style={webStyle.Typography3}>
                                            Calculation Type <span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <Box
                                            style={webStyle.box6}
                                            data-test-id="MouseHover"
                                            onMouseOver={this.handleMouseOver}
                                            onMouseOut={this.handleMouseOut}
                                        >
                                            <Typography style={webStyle.Typography4}>i</Typography>
                                        </Box>
                                    </Box>

                                    {/* Conditional hover image */}
                                    <StyledModalContainer>
                                        <Box>
                                            {isHovered && (
                                                <Box style={{ position: "relative" }}>
                                                    <img src={MessageBox} style={webStyle.box7} />
                                                </Box>
                                            )}
                                        </Box>
                                    </StyledModalContainer>
                                </Box>

                                {/* Rest of the upper code block */}
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedOption === 'productivity'? true : automaticChecked}
                                                disabled={selectedOption === 'productivity'}
                                                style={{ color: '#FDD100', paddingRight: "0px" }}
                                                data-testid="singleuserCheck"
                                                onChange={this.handleChanxgeAutomatic}
                                            />
                                        }
                                        label="Automatic"
                                    />
                                </FormGroup>
                            </Box>

                            {/* Additional conditional logic for automaticChecked */}
                            {automaticChecked && (
                                <Box sx={webStyle.box4}>
                                    <Box sx={webStyle.box5}>
                                        {automaticChecked && selectedOption !== 'productivity' && (
                                            <Box>
                                                <Typography style={webStyle.Typography6}>
                                                    Monthly Goal Details <span style={{ color: 'red' }}>*</span>
                                                </Typography>
                                                <Box sx={webStyle.box5}>
                                                    <Box>
                                                        <Typography style={webStyle.Typography7}>Goal Type</Typography>
                                                        <StyledNumberBox>
                                                            <StyledSelect data-test-id="goalType"
                                                            value={this.state.goalType}
                                                            onChange={this.handleGoalTypeChange}
                                                            >
                                                                <option value="number">Number</option>
                                                                <option value="percentage">Percentage</option>
                                                            </StyledSelect>
                                                        </StyledNumberBox>
                                                    </Box>
                                                    {goalType === 'number' && (
                                                        <>
                                                        
                                                      
                                                    <Box style={{ marginLeft: '15px' }}>
                                                        <Typography style={webStyle.Typography7}>Calculation Formulas</Typography>
                                                        <SumBox >
                                                                <StyledSelect
                                                                    style={{ padding: "15px", border: "none", background: "none", width: "220px" }}
                                                                    value={this.state.goalCalMethod}
                                                                    onChange={this.handleCalMethods}
                                                                >
                                                                    <option value="sum_calculation">Sum</option>
                                                                    <option value="average_calculation">Average</option>
                                                                </StyledSelect>
                                                        </SumBox>
                                                    </Box>
                                                     <GoalBox >
                                                     <Box>
                                                         <Typography style={webStyle.Typography7}>Goal Value (In Numbers)</Typography>
                                                         <Box className="custom-select" style={{ marginTop: '12px' }}>
                                                             <TextField
                                                                 InputProps={{
                                                                     disableUnderline: true,
                                                                 }}
                                                                 
                                                                 onKeyPress={this.handleNumberInput}
                                                                 style={webStyle.TextField4}
                                                                 name="goalValue"
                                                                 onChange={this.handleInputChange}
                                                             />
                                                             {(!goalValue || goalValue > 100)&& (
                                                                 <div style={{ color: "red" }}>
                                                                     {this.state.goalValError}
                                                                 </div>
                                                             )}
                                                         </Box>
                                                     </Box>
                                                 </GoalBox>
                                                 </>
                                                    )}

                                                     {goalType === 'percentage' &&(
                                                         <GoalBox >
                                                         <Box>
                                                             <Typography style={webStyle.Typography7}>Goal Value (In Percentage)</Typography>
                                                             <Box className="custom-select" style={{ marginTop: '10px' }}>
                                                                 <TextField
                                                                     InputProps={{
                                                                         disableUnderline: true,
                                                                     }}
                                                                     value={`${goalValue}%`}
                                                                     onKeyPress={this.handleNumberInput}
                                                                     style={webStyle.TextField4}
                                                                     name="goalValue"
                                                                     onChange={this.handleInputChange}
                                                                     disabled
                                                                 />
                                                                 {(!goalValue || goalValue > 100)&& (
                                                                     <div style={{ color: "red" }}>
                                                                         {this.state.goalValError}
                                                                     </div>
                                                                 )}
                                                             </Box>
                                                         </Box>
                                                     </GoalBox>
                                                     ) }
                                                   
                                                </Box>
                                            </Box>
                                        )}
                                        
                                    </Box>
                                    <Box style={{ marginTop: '30px' }}>
                                        <Typography style={webStyle.Typography8}>
                                            Analytics & Run Rate<span style={{ color: 'red' }}>*</span><span><IconButton /></span>
                                        </Typography>
                                        <Box sx={webStyle.box8}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    style={{ marginTop: "6px" }}
                                                    control={
                                                        <Checkbox
                                                            data-testid="singleuserCheck"
                                                            style={{ color: '#FDD100', fontSize: 'large', paddingRight: "0px" }}
                                                        />
                                                    }
                                                    label="Run Rate"
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormControlLabel
                                                    style={{ marginTop: "6px" }}
                                                    control={
                                                        <Checkbox
                                                            onChange={this.handleChangeChecked2}
                                                            checked={analyticsChecked}
                                                            data-testid="singleuserCheck"
                                                            style={{ color: '#FDD100', fontSize: 'medium', paddingRight: "0px" }}
                                                        />
                                                    }
                                                    label="Part of Analytics"
                                                />
                                            </FormGroup>
                                            {(analyticsChecked) && (
                                                <NewStyledBox >
                                                    <StyledSelect
                                                        style={{ padding: "15px", border: "none", background: "none", width: "280px" }}
                                                        onChange={this.handleAnalyaticParam}
                                                    >
                                                        {this.state.analyticsData.map((param: any) => (
                                                            <option key={param.id} value={param.id}>
                                                                {param.name}
                                                            </option>
                                                        ))}
                                                    </StyledSelect>
                                                </NewStyledBox>)}
                                        </Box>
                                    </Box>
                                </Box>

                            )}
                            {selectedOption === 'productivity' && (
                                            <Box>
                                                <Typography style={webStyle.Typography6}>
                                                    Daily Goal Details <span style={{ color: 'red' }}>*</span>
                                                </Typography>
                                                <Box style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Box>
                                                        <Typography style={webStyle.Typography7}>Goal Value (In Numbers)</Typography>
                                                        <Box className="custom-select" style={{ marginTop: '12px' }}>
                                                            <TextField
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                }}
                                                                onKeyPress={this.handleNumberInput}
                                                                style={webStyle.TextField4}
                                                                name="goalValue"
                                                                onChange={this.handleInputChange}
                                                            />
                                                            {!goalValue && (
                                                                <div style={{ color: "red" }}>
                                                                    {this.state.goalValError}
                                                                </div>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Box>

                                            </Box>
                                        )}
                        </Box>
                    )}
                </Box>
                <Box style={{ marginTop: '50px', marginLeft: '40px' }}>
                    <Button  onClick={this.handleSubmit} style={{ fontFamily: 'Open Sans', backgroundColor: "FDD100", color: "black", marginRight: "20px", width: "170px", fontSize: "medium", fontWeight: 550, textTransform: "none" }}>
                        Submit
                    </Button>
                    <Button variant="outlined" data-test-id="handleCancelId" onClick={()=>this.handleCancel('EditTemplate2')} style={{ fontFamily: 'Open Sans', backgroundColor: "white", color: "black", width: "170px", fontSize: "medium", fontWeight: 550, textTransform: "none" }} >
                        Cancel
                    </Button>
                </Box>
                <PopupMessageWeb
                    open={isOpenDialog}
                    onClose={this.handlecloseDialog}
                    title="Error"
                    description={dialogMessage} />
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    MainContainer: {
      paddingLeft: '45px',
      marginTop: "30px",
      height:"max-content"
    },
    FormLabel1:{
      fontSize: "18px",
       fontFamily: 'Open Sans', 
       fontWeight: 700, 
       color: 'black' 
    },
    Radio1:{
      color: 'FDD100',
    },
    FormControlLabel1:{
      color: '#777', 
      fontFamily: 'Open Sans',
     fontSize: '14px' 
    },
    box1:{
       marginTop: '10px' 
    },
    Typography1:{
       fontWeight: 700, 
       color: 'black', 
       fontSize: "18px", 
       fontFamily: 'Open Sans', 
       marginTop: '10px' 
    },
    box2:{
      display: 'flex', 
      flexDirection: 'row'
    },
    box3:{
      marginRight: '20px'
    },
    TextField1:{
      borderRadius: '10px',
      width: '130px',
      marginTop: '10px',
      padding: '8px',
      backgroundColor: 'white',
      border: '2px solid #F3F4F5',
    },
    TextField2:{
      width: '330px',
      marginTop: '10px',
      borderRadius: '10px',
      padding: '8px',
      backgroundColor: 'white',
      border: '2px solid #F3F4F5',
    
    },
    Typography2:{
      fontFamily: 'Open Sans', 
      display: 'flex', 
      justifyContent: 'end', 
      color: 'black', 
      fontSize: "larger",
    },
    TextField3:{
      borderRadius: '10px',
      width: '130px',
      padding: '8px',
      marginTop: '10px',
      backgroundColor: 'white',
      border: '2px solid #F3F4F5',
    },
    box4:{
      display: 'flex', 
      flexDirection: 'column'
    },
    box44:{
      display: 'flex', 
      flexDirection: 'row'
    },
    box5:{
      display: 'flex', 
      flexDirection: 'row',
    },
    Typography3:{
      fontWeight: 700, 
      color: 'black', 
      fontSize: "18px", 
      fontFamily: 'Open Sans' 
    },
    box6:{
      height: "30px",
      width: "30px",
      border: "1px solid yellow",
      borderRadius: "50%",
      marginLeft: '10px',
      cursor: 'pointer' 
    },
    Typography4:{
      fontWeight: 100, 
      color: 'black', 
      fontSize: "larger", 
      fontFamily: 'Open Sans', 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center'
    },
    box7:{
      height: '110px', 
      width: '220px'
    },
    Typography5:{
      color: 'black', 
      fontFamily: 'Open Sans'
    },
    Typography6:{
      fontWeight: 700, 
      color: 'black', 
      fontSize: "18px", 
      fontFamily: 'Open Sans', 
      marginTop: '30px'
    },
    Typography7:{
      fontWeight: 700, 
      color: 'black', 
      fontSize: "18px", 
      fontFamily: 'Open Sans',
       marginTop: '20px' 
    },
    TextField4:{
      border: '1px solid #ddd',
      borderRadius: '10px',
      width: '220px',
      padding: '10px',
    },
    TextField5:{
      border: '1px solid #ddd',
      borderRadius: '10px',
      width: '220px',
      padding: '10px',
    },
    Typography8:{
      fontWeight: 700, 
      color: 'black', 
      fontSize: "18px", 
      fontFamily: 'Open Sans'
    },
    box8:{
      display: 'flex', 
      flexDirection: 'row', 
      marginTop: '10px'
    },
    template: {
      display: "flex",
      flexWrap: "wrap",
      padding: "10px 10px",
      backgroundColor: '#F3F4F5',
      position: "relative",
      height: "72px",
      alignItems: "center"
     },
     filterHeader: { 
      padding: "0px 0px 0px 22px", 
      display: "flex", 
      alignItems: "flex-start", 
      gap: "20px"
     },
     rowDisplay: { 
      display: "flex", 
      alignItems: "center" 
    },
    labelStyle1: {
      color: "black",
      fontSize: "18px",
      fontFamily: 'Open Sans',
    },
    labelStyle2: {
      color: "	#C0C0C0",
      fontSize: "18px",
    },
    labelStyle: {
      color: 'black', 
      fontSize: "18px", 
      fontWeight: 700 ,
      fontFamily: 'Open Sans',
    },
    emptyContainer: { 
      padding: "0px 44px 0px 0px" 
    },
    leftContainer: {
      padding: "19px 0px 21px 38px"
    },
    
    };
    
    
    const StyledModalContainer = styled(Box)({
      position:"absolute" , left: "499px",top: "530px",
      
      '@media (max-width: 1370px)': {
      left: "450px"   
    },
    '@media (max-width: 1445px)': {
      left: "450px"   
    },
    })
    const StyledNumberBox=styled(Box)({
      marginTop: '10px',
      width: "233px" ,
      backgroundColor: "F6F6F9" ,
       borderRadius: "12px" 
    })
    const SumBox=styled(Box)({
     marginTop: '10px',
     width: "233px" ,
     backgroundColor: "F6F6F9" ,
      borderRadius: "12px" 
    })
    const GoalBox=styled(Box)({
      marginLeft: '15px', 
      display: 'flex',
       flexDirection: 'row'
    })
    const NewStyledBox=styled(Box)({
     width: "290px" ,
     backgroundColor: "F6F6F9" ,
      borderRadius: "12px"
    })
    const StyledSelect=styled('select')({
      padding:"15px",
      border:"none",
      background:"none",
      width:"220px"
    })
// Customizable Area End
